import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import {
  CEO_CHAT_ID,
  COMMENT_BY_ID,
  EMPLOYEES_BY_ID,
  QUESTION_VISIBILITY,
} from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import AnswerView from './components/AnswerView';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { useParams } from 'react-router-dom';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAccessData } from '../../state/UserAccessData';
import PublicPrivateModal from './components/PublicPrivateModal';
import CustomModal from '../../common/ui/custom_modal/CustomModal';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';

const CeoChatContentScreen = ({ history }) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const { questionId } = useParams();
  const [question, setQuestion] = useState({});
  const [isCmd, setIsCmd] = useState(false);
  const [liked, setLiked] = useState();
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const dispatch = useDispatch();
  const pageId = useRef(1);
  const loadMoreFlag = useRef(true);
  const [ceoChatContent, setCeoChatContent] = useState([]);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showPublicPrivateModal, setShowPublicPrivateModal] = useState(false);
  const [showReplyButton, setShowReplyButton] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const ceoChatAccessData =
    accessData && accessData?.data?.find((item) => item.feature_key === '_cmdChat');

  useEffect(() => {
    const actions = ceoChatAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowReplyButton(actions?.includes('_reply'));
  }, [ceoChatAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CEO_CHAT_CONTENT, NAVIGATION_ROUTES.CEO_CHAT_CONTENT);
  }, []);

  const callCeoChatApi = useCallback(
    async (questionIdVar) => {
      setLoadMoreBtn(true);
      dispatch(saveShowEmptyContentView(true));
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (loadMoreFlag.current) {
        const get_params = {
          page_id: pageId.current,
        };
        try {
          const apiResponse = await apiRequest(
            CEO_CHAT_ID + questionIdVar + '/answers',
            REQUEST_TYPE.GET,
            get_params
          );
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.answers) {
                const data = apiResponse.response.data.answers;
                if (data.length > 0) {
                  if (pageId.current === 1) {
                    setCeoChatContent(data.reverse());
                  } else {
                    setCeoChatContent((prev) => [...prev, ...data.reverse()]);
                  }
                  pageId.current = pageId.current + 1;
                  loadMoreFlag.current = true;
                } else {
                  setLoadMoreBtn(false);
                  loadMoreFlag.current = false;
                }
              } else {
                setLoadMoreBtn(false);
                loadMoreFlag.current = false;
              }
            } else {
              setLoadMoreBtn(false);
              loadMoreFlag.current = false;
            }
          }
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(
            err,
            get_params,
            CEO_CHAT_ID + questionIdVar + '/answers',
            NAVIGATION_ROUTES.CEO_CHAT_CONTENT
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      } else {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      }
    },
    [dispatch]
  );

  const callQuestionApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CEO_CHAT_ID + 'question/' + questionId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.access === true) {
            if (apiResponse.response.data.question) {
              setIsCmd(apiResponse.response.data.is_cmd);
              const data = apiResponse.response.data.question;
              if (data) {
                setQuestion(data);
                setLiked(data.likes.is_liked);
                setLikeCount(data.likes.count);
                setCommentCount(data.comment_count);
              }
              loadMoreFlag.current = true;
              pageId.current = 1;
              callCeoChatApi(questionId);
            }
          } else {
            setAlertMessage(apiResponse.response.message);
            setShowAlert(true);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        CEO_CHAT_ID + 'question/' + questionId,
        NAVIGATION_ROUTES.CEO_CHAT_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [callCeoChatApi, dispatch, questionId]);

  const renderScreen = useCallback(async () => {
    if (questionId) {
      callQuestionApi(questionId);
    }
  }, [callQuestionApi, questionId]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const renderItem = (item) => {
    return (
      <div className="ceo-chat-answer-item-view">
        <AnswerView
          employeeId={item.employee.employee_id}
          baseUrl={item.employee.profile_picture.base_url}
          imagePath={item.employee.profile_picture.image_path}
          employeeName={
            item.employee.first_name +
            ' ' +
            item.employee.middle_name +
            ' ' +
            item.employee.last_name +
            ' '
          }
          askedOn={item.answer_on}
          question={item.answer}
          isQuestion={false}
          isCmd={isCmd}
          questionId={question.question_id}
          taggedEmployees={item.tagged_employees}
          answerId={item.answer_id}
        />
        {item.tagged_employees.length > 0 && (
          <div className="ceo-chat-ceo-tagged-view">
            <div className="ceo-chat-ceo-tag-text-label mb-2">Tagged:</div>
            <div>
              {item.tagged_employees.map((val, index) => {
                return (
                  <div
                    key={index}
                    onClick={() =>
                      history.push(
                        `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${val.employee_id}`
                      )
                    }
                    className="d-flex flex-row mb-2 w-100 align-items-center">
                    <UserProfilePhoto
                      imageBaseUrl={val.profile_picture.base_url}
                      imagePath={val.profile_picture.image_path}
                      imageClass="ceo-chat-tagged-emp-pfp mr-2"
                      employeeId={val.employee_id}
                    />
                    <div className="ceo-chat-ceo-tag-text common-cursor-pointer ml-2">
                      {` ${val.first_name} ${val.last_name}`}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const loadMoreHandler = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callCeoChatApi(question.question_id);
    }
  };

  const postLikeFunction = useCallback(async () => {
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.CEO_CHAT);
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.CEO_QUESTION_LIKE,
        question.question_id,
        ANALYTICS_ITEM_NAMES.LIKE
      );
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + question.question_id + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + question.question_id + '/like',
        NAVIGATION_ROUTES.CEO_CHAT_CONTENT
      );
      console.error(err);
    }
  }, [question]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_REPLY_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const updateQuestionStatus = async (isPublic = false) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const formData = new URLSearchParams();
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.CEO_QUESTION_STATUS_CHANGE,
        question.question_id,
        isPublic ? ANALYTICS_ITEM_NAMES.PUBLIC : ANALYTICS_ITEM_NAMES.PRIVATE
      );
      formData.append('question_id', question.question_id);
      formData.append('question_visibility', isPublic ? 1 : 0);

      const apiResponse = await apiRequest(QUESTION_VISIBILITY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          callQuestionApi();
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    } catch (err) {
      handleError(err, formData, QUESTION_VISIBILITY, NAVIGATION_ROUTES.CEO_CHAT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  return (
    <div className="container">
      {Object.keys(question).length >= 0 && (
        <PublicPrivateModal
          question={question}
          setIsVisible={setShowPublicPrivateModal}
          submit={updateQuestionStatus}
          visible={showPublicPrivateModal}
        />
      )}
      <CustomModal
        visible={showAlert}
        onClose={() => {
          setShowAlert(false);
          history.replace(NAVIGATION_ROUTES.HOME);
        }}
        description={alertMessage}
        title={'Alert!'}
        primaryButtonTitle={'OK'}
        onClickPrimaryButton={() => {
          setShowAlert(false);
          history.replace(NAVIGATION_ROUTES.HOME);
        }}
      />
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {question && (
          <div className="broadcast-header-row justify-content-end">
            {showLikeButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    liked
                      ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                      : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                  )}
                  className="header-icon"
                  onClick={postLikeFunction}
                />
                <div
                  className="ceo-chat-icon-text common-cursor-pointer"
                  onClick={() => {
                    if (likeCount > 0) {
                      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                        referId: question.question_id,
                        parentType: FEATURE_TYPE.CEO_CHAT,
                      });
                    }
                  }}>
                  {likeCount}
                </div>
              </div>
            )}
            {showCommentButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                  )}
                  className="header-icon"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: question.question_id,
                      likeType: FEATURE_TYPE.CEO_CHAT,
                    });
                  }}
                />
                <div
                  className="ceo-chat-icon-text common-cursor-pointer"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: question.question_id,
                      likeType: FEATURE_TYPE.CEO_CHAT,
                    });
                  }}>
                  {commentCount}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {question && question.employee && (
        <div className="main-content-container material-card mb-3">
          <AnswerView
            employeeId={question.employee.employee_id}
            baseUrl={
              question.employee.profile_picture ? question.employee.profile_picture.base_url : ''
            }
            imagePath={
              question.employee.profile_picture ? question.employee.profile_picture.image_path : ''
            }
            employeeName={
              question.employee.first_name +
              ' ' +
              question.employee.middle_name +
              ' ' +
              question.employee.last_name +
              ' '
            }
            askedOn={question.asked_on}
            question={question.question}
            isQuestion={true}
            canAnswer={question.can_answer}
            isPublic={question.is_public}
            location={question.employee.location}
            position={question.employee.position_title}
            isCmd={isCmd}
            onSelectChangeStatus={() => setShowPublicPrivateModal(true)}
            questionId={question.question_id}
          />
        </div>
      )}
      <div className="material-card mb-3">
        <div
          className={`ceo-chat-reply-view ${
            !ceoChatContent.length && 'ceo-chat-border-empty-ans'
          }`}>
          <div className="estore-bold-text">REPLIES</div>

          {question && question.can_answer && showReplyButton && (
            <CustomButton
              buttonStyle="ceo-chat-reply-button common-custom-button-secondary"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.CEO_TAG_EMPLOYEE, {
                  questionId: question.question_id,
                  isCmd,
                });
              }}>
              Reply
            </CustomButton>
          )}
        </div>
        {showEmptyContentView &&
        ceoChatContent &&
        !ceoChatContent.length &&
        !progressLoadingState.isProgressLoading ? (
          showEmptyListView()
        ) : (
          <div>
            {ceoChatContent &&
              question &&
              ceoChatContent.map((item) => {
                return <div key={item.answer_id}>{renderItem(item)}</div>;
              })}
          </div>
        )}
      </div>
      {loadMoreBtn && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn
            onClick={loadMoreHandler}
            isLoading={progressLoadingState.isProgressLoading}
          />
        </div>
      )}
    </div>
  );
};

export default CeoChatContentScreen;
