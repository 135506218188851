import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMiMeetingConfig, selectMiMeetingMinInterval } from '../../../state/MasterData';
import { Carousel } from 'react-bootstrap';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getTomorrowDateInYYYYMMDD, showToast } from '../../../utils/common';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { msApiRequest } from '../../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { MEETING_ROOM_BOOKINGS, MEETING_ROOM_FACILITIES } from '../../../services/ApiUrls';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import dayjs from 'dayjs';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import UpcomingItem from '../components/UpcomingItem';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const MiMeetingHomeTab = () => {
  const meetingHomeConfig = useSelector(selectMiMeetingConfig);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const employeeData = useSelector(selectEmployeeData);
  const dispatch = useDispatch();
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [facilities, setFacilities] = useState([]);
  const [selectedDate, setSelectedDate] = useState(getTomorrowDateInYYYYMMDD());
  const [checkInTime, setCheckInTime] = useState('');
  const [checkOutTime, setCheckOutTime] = useState('');
  const minIntervalInMins = useSelector(selectMiMeetingMinInterval);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await msApiRequest(MEETING_ROOM_FACILITIES, REQUEST_TYPE.GET, {
        org_id: 1,
        source: 'meeting',
      });
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status_code === 200) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data.length > 0) {
              setFacilities(data);
            }
          }
        }
      }
      dispatch(resetLoaderState());
    } catch (err) {
      dispatch(resetLoaderState());
    }
  }, [dispatch]);

  const callUpcomingBookingApi = useCallback(async () => {
    try {
      const response = await msApiRequest(MEETING_ROOM_BOOKINGS, REQUEST_TYPE.GET, {
        org_id: 1,
        type: 'dashboard',
        tab_type: 'upcoming',
        page_id: 1,
      });
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setUpcomingBookings(data);
          }
        }
      }
      dispatch(resetLoaderState());
    } catch (err) {
      dispatch(resetLoaderState());
    }
  }, [dispatch]);

  useEffect(() => {
    callFacilitiesApi();
    callUpcomingBookingApi();
  }, [callFacilitiesApi, callUpcomingBookingApi]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    const today = dayjs().startOf('day');

    if (dayjs(newDate).isBefore(today, 'day')) {
      showToast("Past dates can't be selected");
    } else {
      if (newDate !== selectedDate) {
        setCheckInTime('');
        setCheckOutTime('');
      }
      setSelectedDate(newDate);
    }
  };

  const handleCheckInTimeChange = (e) => {
    const selectedCheckInTime = e.target.value;
    const selectedEntryDateTime = dayjs(`${selectedDate} ${selectedCheckInTime}`);
    if (selectedEntryDateTime.isBefore(dayjs())) {
      showToast('The selected entry time is in the past. Please choose a future time.');
      return;
    }

    if (selectedCheckInTime !== checkInTime) {
      setCheckOutTime('');
    }

    setCheckInTime(selectedCheckInTime);
  };

  const handleCheckoutTimeChange = (e) => {
    const selectedCheckoutTime = e.target.value;

    if (!checkInTime) {
      showToast('Please select a Check-in time first.');
      return;
    }

    const checkInDateTime = dayjs(checkInTime, 'HH:mm');
    const checkOutDateTime = dayjs(selectedCheckoutTime, 'HH:mm');

    if (checkOutDateTime.isBefore(checkInDateTime)) {
      showToast('Check-out time should be after Check-in time.');
      return;
    }

    if (checkOutDateTime.diff(checkInDateTime, 'minute') < minIntervalInMins) {
      showToast(
        `Check-out time should be at least ${minIntervalInMins} minutes later than Check-in time.`
      );
      return;
    }

    setCheckOutTime(selectedCheckoutTime);
  };

  const isButtonDisabled = () => {
    return !selectedDate || !checkInTime || !checkOutTime;
  };

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.MI_MEETING_HOME_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.MIMEETING);
  }, []);

  const entryDateTime = dayjs(`${selectedDate} ${checkInTime}`).format('YYYY-MM-DD HH:mm:ss');
  const exitDateTime = dayjs(`${selectedDate} ${checkOutTime}`).format('YYYY-MM-DD HH:mm:ss');

  return (
    <div className="seat-booking-colored-container p-2">
      <div className="d-flex flex-row mb-2">
        {meetingHomeConfig && meetingHomeConfig.banners.length > 0 && (
          <div className="seat-booking-white-background meeting-container-width meeting-component-height mr-2">
            <Carousel
              activeIndex={currentActiveSlideIndex}
              className=""
              nextIcon={
                meetingHomeConfig.banners.length > 1 && (
                  <span aria-hidden="true" className="carousel-control-next-icon" />
                )
              }
              prevIcon={
                meetingHomeConfig.banners.length > 1 && (
                  <span aria-hidden="true" className="carousel-control-prev-icon" />
                )
              }
              onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
              {meetingHomeConfig.banners.map((imageItem, index) => {
                return (
                  <Carousel.Item key={`${index}`}>
                    <div className="d-flex justify-content-center">
                      <img
                        className="meeting-carousel-container seat-booking-carousel-border"
                        src={imageItem.base_url + imageItem.filename}
                      />
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        )}
        {upcomingBookings && upcomingBookings.length > 0 ? (
          <div className="seat-booking-white-background p-2 meeting-container-width meeting-component-height mr-2">
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
            </div>
            {upcomingBookings.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <UpcomingItem item={item} index={index} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="seat-booking-white-background p-2 meeting-container-width meeting-component-height mr-2">
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">UPCOMING MEETINGS</div>
            </div>
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100 align-self-center
      seat-booking-quick-emp-container-height">
              <div>Your upcoming bookings will appear here</div>
              <div className="d-flex flex-row align-items-center">
                <div className="mr-1 mt-n1">Book a meeting room now</div>
                <div className="vph-arrow">➞</div>
              </div>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.MEET_UPCOMING_BOOKING_ICON,
                  ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                )}
                className="park-qb-icon pt-2"
              />
            </div>
          </div>
        )}
        <div className="seat-booking-white-background p-2 meeting-container-width meeting-component-height">
          <div className="estore-home-tab-products-header">
            <div className="estore-product-type-title">BOOK MEETING ROOM</div>
          </div>
          <div className="flex-fill mr-2 mb-2">
            <CustomTextInput
              name="destination_pref_date"
              isTitle={false}
              placeholder="Date"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <div className="base-14px-font ml-1">Start time</div>
              <div className="flex-fill">
                <CustomTextInput
                  isTitle={false}
                  placeholder="Time"
                  name="pref_time"
                  type="time"
                  value={checkInTime}
                  onChange={handleCheckInTimeChange}
                />
              </div>
            </div>
            <div>
              <div className="base-14px-font ml-1">End time</div>
              <div className="flex-fill mr-2">
                <CustomTextInput
                  isTitle={false}
                  placeholder="Time"
                  name="pref_time"
                  type="time"
                  value={checkOutTime}
                  onChange={handleCheckoutTimeChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center mb-2 mt-2">
            <div className="w-100">
              <CustomButton
                disabled={isButtonDisabled()}
                buttonStyle="estore-add-to-cart-button mt-2"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.MI_MEETING_ROOMS_RESULTS_SCREEN, {
                    query: '',
                    entryDatetime: entryDateTime,
                    exitDatetime: exitDateTime,
                    checkInTime: checkInTime,
                    checkOutTime: checkOutTime,
                    selectedDate: selectedDate,
                  });
                  logEvent(
                    ANALYTICS_EVENT_TYPES.MEETING_ROOM_CHECK_AVAILABILITY_BUTTON_CLICKED,
                    '',
                    ANALYTICS_ITEM_NAMES.MIMEETING
                  );
                }}>
                CHECK AVAILABILITY
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="seat-booking-white-background p-2">
        <div className="estore-home-tab-products-header">
          <div className="estore-product-type-title">FACILITIES</div>
        </div>
        <div className="d-flex flex-row">
          {facilities?.slice(0, 3).map((item, index) => {
            return (
              <div
                className="seat-booking-colored-container mr-2 mi-meeting-border-radius"
                key={`${index}`}>
                <img src={item.promo_image} className="mi-meeting-db-facility-image" />
                <div className="p-2">
                  <div className="mi-meeting-facility-title">{item?.title}</div>
                  <div className="d-flex flex-row justify-content-between">
                    <div>
                      {item?.roomsCount && item?.roomsCount.CONFERENCE && (
                        <div className="mi-meeting-facility-subtitle">
                          {item.roomsCount.CONFERENCE} Conference room
                          {item?.roomsCount.CONFERENCE > 1 && 's'}
                        </div>
                      )}
                      {item?.roomsCount && item?.roomsCount.DISCUSSION && (
                        <div className="mi-meeting-facility-subtitle">
                          {item.roomsCount.DISCUSSION} Discussion room
                          {item?.roomsCount.DISCUSSION > 1 && 's'}
                        </div>
                      )}
                    </div>
                    <div
                      className="mi-meet-check-fac-container p-3 d-flex flex-row justify-content-between align-items-center common-cursor-pointer"
                      onClick={() => {
                        history.push(NAVIGATION_ROUTES.MI_MEETING_ROOMS_RESULTS_SCREEN, {
                          query: '',
                          facility_id: item.facility_id,
                          facilityTitle: item.title,
                        });
                        logEvent(
                          ANALYTICS_EVENT_TYPES.MEETING_ROOM_CHECK_FACILITY_BUTTON_CLICKED,
                          item.facility_id,
                          ANALYTICS_ITEM_NAMES.MIMEETING
                        );
                      }}>
                      <div className="mi-meet-check-fac-text">CHECK AVAILABILITY</div>
                      <span className="mi-meet-arrow-txt ml-4">{'❯'}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MiMeetingHomeTab;
