import React, { useState } from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  isToday,
  removeHtmlContent,
} from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const Questiondiv = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showReadMore, setShowReadMore] = useState(removeHtmlContent(props.question).length > 256);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  return (
    <div>
      <div className="ceo-chat-ceo-question-row ceo-chat-row-container">
        <div className="ceo-chat-ceo-question-row ceo-chat-image-container w-100">
          <div>
            {props.employee.profile_picture && (
              <UserProfilePhoto
                imageBaseUrl={props.employee.profile_picture.base_url}
                imagePath={props.employee.profile_picture.image_path}
                employeeId={props.employee.employee_id}
                imageClass={props.imageClassName}
              />
            )}
          </div>

          <div className="ceo-chat-employee-details">
            <div className="d-flex flex-row justify-content-between align-items-start w-100">
              <div>
                <p className="ceo-chat-question-emp-name-text mb-0">
                  {props.employee.first_name + ' ' + props.employee.last_name}
                </p>
                {props.position && (
                  <p className="ceo-chat-question-emp-pronoun-text mb-0">{props.position}</p>
                )}
                {props.location && (
                  <p className="ceo-chat-question-emp-pronoun-text mb-0">{props.location}</p>
                )}
              </div>
              <div className="ceo-chat-ques-emp-date-text">
                {isToday(props.askedOn)
                  ? formatTimeAMPM(props.askedOn)
                  : formatDateDDMonthYYYY(props.askedOn)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="ceo-chat-question-text">
          <HtmlView
            onClick={handleHtmlClicks}
            html={
              props.question.length > 256 && showReadMore
                ? props.question.substring(0, 256) + '...'
                : props.question
            }
          />
          <div className="w-100 d-flex flex-row justify-content-between">
            {removeHtmlContent(props.question).length > 256 ? (
              <div
                onClick={(e) => {
                  setShowReadMore(!showReadMore);
                  e.stopPropagation();
                }}
                className="common-cursor-pointer mt-1 ceo-chat-read-style">
                {showReadMore ? 'Read More' : 'Read Less'}
              </div>
            ) : (
              <div />
            )}
            {props.isCmd &&
              (props.isPublic ? (
                <div
                  onClick={(e) => {
                    props.onSelectChangeStatus();
                    e.stopPropagation();
                  }}
                  className="ceo-chat-public-private-view">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.PUBLIC_QUESTION,
                      ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                    )}
                    style={{ height: 8, width: 14.5, marginRight: 5, alignSelf: 'center' }}
                  />
                  <div className="ceo-chat-public">Public ▼</div>
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    props.onSelectChangeStatus();
                    e.stopPropagation();
                  }}
                  className="ceo-chat-public-private-view">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.PRIVATE_QUESTION,
                      ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                    )}
                    style={{ height: 8, width: 14.5, marginRight: 5 }}
                  />
                  <div className="ceo-chat-private">Private ▼</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questiondiv;
