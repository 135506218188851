import { createSlice } from '@reduxjs/toolkit';

const saveNotificationCountReducer = (state, action) => {
  state.notificationCount = action.payload;
};
const saveNotificationListItemReducer = (state, action) => {
  state.notificationList = action.payload;
};
const updateNotificationListItemReducer = (state, action) => {
  let temp = [...state.notificationList];
  temp.unshift(action.payload);
  state.notificationList = temp;
};

const updateNotificationCountReducer = (state, action) => {
  state.notificationCount = state.notificationCount ? state.notificationCount + 1 : 1;
};

const resetNotificationCountReducer = (state, action) => {
  state.notificationCount = 0;
};

// REDUCER
const notificationDataSlice = createSlice({
  name: 'notificationData',
  initialState: {
    notificationCount: undefined,
    notificationList: [],
  },
  reducers: {
    saveNotificationCount: saveNotificationCountReducer,
    updateNotificationCount: updateNotificationCountReducer,
    resetNotificationCount: resetNotificationCountReducer,
    saveNotificationListItem: saveNotificationListItemReducer,
    updateNotificationListItem: updateNotificationListItemReducer,
  },
});

// ACTIONS
const {
  saveNotificationCount,
  updateNotificationCount,
  resetNotificationCount,
  saveNotificationListItem,
  updateNotificationListItem,
} = notificationDataSlice.actions;

// SELECTOR
const selectNotificationCount = ({ notificationData }) => notificationData.notificationCount;

const notificationDataSliceReducer = notificationDataSlice.reducer;

const selectNotificationList = ({ notificationData }) => notificationData.notificationList;

export {
  notificationDataSliceReducer,
  saveNotificationCount,
  selectNotificationCount,
  updateNotificationCount,
  resetNotificationCount,
  selectNotificationList,
  saveNotificationListItem,
  updateNotificationListItem,
};
