import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import debounce from 'lodash.debounce';
import { apiRequest } from '../../../services/Service';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { showToast } from '../../../utils/common';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import dayjs from 'dayjs';
import { saveMeetingArray, selectMeetingArray } from '../../../state/MeetingData';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../../state/UserAccessData';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MeetingPreviewScreen = ({ location }) => {
  const dispatch = useDispatch();
  const savedMeetingArray = useSelector(selectMeetingArray);
  const meetingArray = location?.state?.item;
  const accessibilityData = useSelector(selectAccessibilityData);
  const [meetingName, setMeetingName] = useState(savedMeetingArray?.meetingName || '');
  const [isMnFocused, setIsMnFocused] = useState(false);
  const [extParticipants, setExtParticipants] = useState('');
  const [isEpFocused, setIsEpFocused] = useState(false);
  const [agendaTxt, setAgendaTxt] = useState(savedMeetingArray?.meetingAgenda || '');
  const [isAgendaFocused, setIsAgendaFocused] = useState(false);
  const [participantText, setParticipantText] = useState('');
  const [isPtFocused, setIsPtFocused] = useState('');
  const history = useHistory();
  const [showBottomButton, setShowBottomButton] = useState(false);
  const [employeeList, setEmployeeList] = useState();
  const employeeData = useSelector(selectEmployeeData);
  const [selectedEmployee, setSelectedEmployee] = useState(
    savedMeetingArray?.selectedEmployee || []
  );
  const [loading, setLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [externalParticipantList, setExternalParticipantList] = useState(
    savedMeetingArray?.externalParticipantList || []
  );
  const { checkInTime, checkOutTime, entryDateTime, exitDateTime, selectedDate } = location.state;

  const accessData = useSelector(selectAccessData);
  const meetAccessData = accessData?.data?.find(
    (item) => item.feature_key === '_meetingRoomBooking'
  );
  const [showBookButton, setShowBookButton] = useState(true);

  useEffect(() => {
    const actions = meetAccessData?.actions;
    setShowBookButton(actions?.includes('_book'));
  }, [meetAccessData?.actions]);

  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('DD MMMM YYYY');
  };
  const formattedDate = formatDate(selectedDate);

  const onChange = (e) => {
    setMeetingName(e.target.value);
  };

  const onEpChange = (e) => {
    setExtParticipants(e.target.value);
  };

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MI_MEETING_PREVIEW_SCREEN,
      NAVIGATION_ROUTES.MEETING_PREVIEW
    );
  }, []);

  const debouncedApiCall = useCallback(
    debounce((value) => {
      callEmployeeApi(value);
    }, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      const topButton = document.getElementById('topContinueButton');
      if (!topButton) return;

      const buttonPosition = topButton.getBoundingClientRect().top;
      setShowBottomButton(buttonPosition < 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const ContinueButton = () => (
    <CustomButton buttonStyle="estore-add-to-cart-button mt-2" onClick={handleContinueClick}>
      CONTINUE
    </CustomButton>
  );

  const handleContinueClick = () => {
    if (meetingName.trim() === '') {
      showToast('Please enter a meeting name.');
      return;
    }
    if (selectedEmployee === null || selectedEmployee?.length === 0) {
      showToast('Please add atleast 1 meeting participant.');
      return;
    }
    dispatch(
      saveMeetingArray({
        meetingArray: meetingArray,
        selectedEmployee: selectedEmployee,
        externalParticipantList: externalParticipantList,
        meetingName: meetingName,
        meetingAgenda: agendaTxt,
        checkInTime: checkInTime,
        checkOutTime: checkOutTime,
        entryDateTime: entryDateTime,
        exitDateTime: exitDateTime,
      })
    );
    history.push(NAVIGATION_ROUTES.MEETING_DETAIL, {
      meetingArray: meetingArray,
      selectedEmployee: selectedEmployee,
      externalParticipantList: externalParticipantList,
      meetingName: meetingName,
      meetingAgenda: agendaTxt,
      checkInTime: checkInTime,
      checkOutTime: checkOutTime,
      entryDateTime: entryDateTime,
      exitDateTime: exitDateTime,
      selectedDate: selectedDate,
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setParticipantText(value);
    debouncedApiCall(value);
  };

  const callEmployeeApi = useCallback(
    async (searchQuery = '') => {
      let paramsObj = { page_id: 1, query: searchQuery };
      const params = { ...paramsObj };
      try {
        setLoading(true);
        setEmployeeList([]);
        const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList(empList);
          }
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    },
    [employeeData.employee_id]
  );

  const handleDropdownClick = (emp) => {
    setSelectedEmployee((prevSelected) => {
      if (!prevSelected.some((e) => e.employee_id === emp.employee_id)) {
        return [...prevSelected, emp];
      }
      return prevSelected;
    });
    setParticipantText('');
    setIsDropdownVisible(false);
  };

  const removeEmployee = (empId) => {
    setSelectedEmployee((prevSelected) => prevSelected.filter((emp) => emp.employee_id !== empId));
  };

  // Render selected employee tags
  const renderSelectedEmployees = () => {
    return selectedEmployee?.map((emp, index) => (
      <div
        key={`${index}`}
        className="d-flex align-items-center seat-booking-white-background rounded-pill px-2 py-1 mr-2 mb-1">
        <UserProfilePhoto
          imageBaseUrl={emp.profile_picture.base_url}
          imagePath={emp.profile_picture.image_path}
          employeeId={emp.employee_id}
          imageClass={'meet-prev-prof-icon'}
        />
        <div className="pr-4">
          <p className="base-10px-font m-0 ml-2">
            {`${emp.employee_name}`.length < 30
              ? `${emp.employee_name}`
              : `${emp.employee_name}`.substring(0, 26) + '...'}
          </p>
        </div>
        <div
          className="ml-1 common-cursor-pointer"
          onClick={() => removeEmployee(emp.employee_id)}
          style={{ padding: '2px' }}>
          ✕
        </div>
      </div>
    ));
  };

  const addExternalParticipant = () => {
    if (extParticipants.trim() === '') {
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(extParticipants.trim())) {
      showToast('Please enter a valid email address.');
      return;
    }

    setExternalParticipantList((prev) => {
      if (!prev.includes(extParticipants.trim())) {
        return [...prev, extParticipants.trim()];
      }
      return prev;
    });
    setExtParticipants('');
  };

  const removeExternalParticipant = (email) => {
    setExternalParticipantList((prev) => prev.filter((item) => item !== email));
  };

  return (
    <div className="container">
      <SectionHeader title={'MiMEETING'} />
      <div className="header-view justify-content-between mb-2">
        <BackButton isFullRow={false} />
        {showBookButton && (
          <div className="broadcast-header-row justify-content-end">
            <div className="broadcast-icon-row mr-3" id="topContinueButton">
              <ContinueButton />
            </div>
          </div>
        )}
      </div>
      <div className="seat-booking-colored-container p-2 mb-2">
        <div className="d-flex flex-row">
          <div
            className="seat-booking-white-background p-3 d-flex flex-row mi-meeting-rs-border-radius mr-2"
            style={{ width: '65%' }}>
            <div className="meet-prev-left-con d-flex flex-column">
              <div>
                <div className="d-flex meet-prev-title mb-2" style={{ wordBreak: 'break-word' }}>
                  {meetingArray?.title}
                </div>
                <div className="base-12px-font mt-2">{meetingArray?.facility_details?.title}</div>
              </div>
              <div className="meet-prev-cap-text mt-2 d-flex">
                Max capacity: {meetingArray?.capacity} people
              </div>
            </div>
            <div className="meet-prev-vertical-divider mx-5" />
            <div className="meet-prov-con mr-3">
              {meetingArray?.equipments?.map((provision, index) => {
                return (
                  <div key={`${index}`} className="meet-prev-provision-container">
                    <img
                      src={provision.icon_path}
                      className="meet-prev-prov-icon common-cursor-pointer"
                    />
                    <div className="meet-prev-tooltip">{provision.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="seat-booking-white-background mi-meeting-rs-border-radius p-3 d-flex flex-row meet-prev-con-wid justify-content-end align-items-center">
              <UserProfilePhoto
                imageBaseUrl={employeeData.profile_picture.base_url}
                imagePath={employeeData.profile_picture.image_path}
                employeeId={employeeData.employee_id}
                imageClass={'meet-prev-prof-icon'}
              />
              <div>
                <p className="meet-prev-org-name-text base-10px-font m-0 ml-2">
                  {`${employeeData.first_name + ' ' + employeeData.last_name}`.length < 30
                    ? `${employeeData.first_name + ' ' + employeeData.last_name}`
                    : `${employeeData.first_name + ' ' + employeeData.last_name}`.substring(0, 26) +
                      '...'}
                </p>
              </div>
              <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
              <div className="base-10px-semibold">Organizer</div>
            </div>
            <div className="seat-booking-white-background mi-meeting-rs-border-radius p-3 d-flex flex-row mt-2 justify-content-center">
              <div className="d-flex flex-row align-items-center">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.NEWS
                  )}
                  className="meet-prev-dt-icon mr-2"
                />
                <div className="meet-prev-date-txt base-10px-semibold">{formattedDate || ''}</div>
              </div>
              <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
              <div className="d-flex flex-row align-items-center">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CLOCK,
                    ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                  )}
                  className="meet-prev-dt-icon mr-2"
                />
                <div className="meet-prev-date-txt base-10px-semibold">
                  Start - {checkInTime || ''}
                </div>
              </div>
              <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
              <div className="d-flex flex-row align-items-center">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CLOCK,
                    ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                  )}
                  className="meet-prev-dt-icon mr-2"
                />
                <div className="meet-prev-date-txt base-10px-semibold">
                  End - {checkOutTime || ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seat-booking-white-background p-3 mi-meeting-rs-border-radius mt-2">
          <HtmlView html={meetingArray?.description} htmlStyle="meet-prev-subtitle" />
        </div>
        <div className="seat-booking-white-background p-2 mi-meeting-rs-border-radius mt-2">
          <div
            className={
              isMnFocused
                ? 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center meet-prev-bm'
                : 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center'
            }>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NAME,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
            />
            <input
              type="text"
              placeholder={isMnFocused ? 'Enter meeting name*' : 'Meeting name*'}
              className={
                isMnFocused
                  ? 'meet-prev-borderless-input w-100'
                  : 'meet-prev-borderless-input w-100 common-cursor-pointer'
              }
              onChange={onChange}
              value={meetingName}
              onFocus={() => setIsMnFocused(true)}
              onBlur={() => setIsMnFocused(false)}
            />
          </div>
          <div
            style={{ position: 'relative' }}
            className={
              isPtFocused
                ? 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center meet-prev-bm'
                : 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center'
            }>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ADD_PARTICIPANT,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
            />
            <div className="w-100">
              {selectedEmployee?.length > 0 && (
                <div className="d-flex flex-wrap mb-1">{renderSelectedEmployees()}</div>
              )}
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  placeholder={isPtFocused ? 'Search for participant' : 'Add participants*'}
                  className={
                    isPtFocused
                      ? 'meet-prev-borderless-input w-100'
                      : 'meet-prev-borderless-input w-100 common-cursor-pointer'
                  }
                  onChange={handleInputChange}
                  value={participantText}
                  onFocus={() => setIsPtFocused(true)}
                  onBlur={(e) => {
                    setTimeout(() => {
                      setIsPtFocused(false);
                      setIsDropdownVisible(false);
                    }, 200);
                  }}
                />
              </div>
            </div>
            {!!participantText && isPtFocused && (
              <div className="meet-prev-dropdown-panel">
                {loading && (
                  <div className="d-flex align-items-center flex-row w-100 justify-content-center my-5">
                    <ActivityLoader visible={loading} />
                  </div>
                )}
                {!loading &&
                  employeeList?.map((emp, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className="d-flex flex-row mt-2 meet-prev-dropdown-item p-2 align-items-center"
                        onMouseDown={(e) => {
                          e.preventDefault();
                          handleDropdownClick(emp);
                        }}>
                        <UserProfilePhoto
                          imageBaseUrl={emp.profile_picture.base_url}
                          imagePath={emp.profile_picture.image_path}
                          employeeId={emp.employee_id}
                          imageClass={'meet-prev-prof-icon'}
                        />
                        <div className="pr-2">
                          <p className="base-10px-font m-0 ml-2">
                            {`${emp.employee_name}`.length < 30
                              ? `${emp.employee_name}`
                              : `${emp.employee_name}`.substring(0, 26) + '...'}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {!loading && employeeList?.length === 0 && (
                  <div className="base-14px-font p-3">
                    No results found. Check spelling or try another name.
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className={
              isEpFocused
                ? 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 mb-1 align-items-center meet-prev-bm d-flex flex-row'
                : 'meet-prev-colored-bg mi-meeting-rs-border-radius p-3 mb-1 align-items-center d-flex flex-row'
            }>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ADD_PARTICIPANT,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2 mt-2"
            />
            <div className="w-100">
              <div className="d-flex flex-wrap mb-2">
                {externalParticipantList.map((email, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center seat-booking-white-background rounded-pill px-3 py-1 mr-2 mb-1">
                    <span className="text-truncate base-10px-font" style={{ maxWidth: '200px' }}>
                      {email}
                    </span>
                    <div
                      className="ml-2 common-cursor-pointer"
                      onClick={() => removeExternalParticipant(email)}
                      style={{ padding: '2px' }}>
                      ✕
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  placeholder={
                    isEpFocused
                      ? 'Enter external participants emails'
                      : 'Invite external participants'
                  }
                  className={
                    isEpFocused
                      ? 'meet-prev-borderless-input w-100'
                      : 'meet-prev-borderless-input w-100 common-cursor-pointer'
                  }
                  onChange={onEpChange}
                  value={extParticipants}
                  onFocus={() => setIsEpFocused(true)}
                  onBlur={() => setIsEpFocused(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addExternalParticipant();
                    }
                  }}
                />
                <div
                  className="common-cursor-pointer ml-2 d-flex align-items-center justify-content-center"
                  onClick={addExternalParticipant}
                  style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: extParticipants.trim() ? '#007bff' : '#ccc',
                  }}>
                  <span style={{ color: 'white', fontSize: '20px', lineHeight: '20px' }}>+</span>
                </div>
              </div>
            </div>
          </div>
          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.LOCATION,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
            />
            <div className="meet-prev-form-label">{meetingArray?.facility_details?.title}</div>
          </div>
          <div
            className={`meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 meet-prev-dec-con ${
              isAgendaFocused ? 'meet-prev-bm' : ''
            } align-items-start`}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.INFORMATION,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
              alt="Meeting Icon"
            />
            <div className="position-relative w-100">
              <ReactQuill
                theme="snow"
                placeholder="Enter meeting agenda"
                value={!agendaTxt ? '<br>' : `<p>${agendaTxt}</p>`}
                onChange={(val) => {
                  setAgendaTxt(val.replace(/<\/?p[^>]*>/g, '').replace('<br>', ''));
                }}
                onFocus={() => setIsAgendaFocused(true)}
                onBlur={() => setIsAgendaFocused(false)}
                className="w-100 meet-prev-ti-con mt-n1"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ align: [] }],
                    ['link'],
                  ],
                  clipboard: {
                    matchVisual: false,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showBottomButton && showBookButton && (
        <div className="meet-prev-floating-button-container">
          <div className="meet-prev-floating-button-inner">
            <ContinueButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingPreviewScreen;
