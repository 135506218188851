import React, { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  BUTTON_TITLES,
  MODAL_TITLES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import dayjs from 'dayjs';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { showToast } from '../../../utils/common';
import { msApiRequest } from '../../../services/Service';
import CustomModal from '../../../common/ui/custom_modal/CustomModal';
import {
  MEETING_ROOM_BOOKING_CANCEL,
  MEETING_ROOM_BOOKING_DETAIL,
} from '../../../services/ApiUrls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { saveMeetingArray } from '../../../state/MeetingData';
import { Carousel } from 'react-bootstrap';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../../state/UserAccessData';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

const MeetingDetailScreen = ({ location }) => {
  const {
    checkInTime,
    checkOutTime,
    meetingArray,
    externalParticipantList,
    meetingName,
    meetingAgenda,
    selectedEmployee,
    entryDateTime,
    exitDateTime,
    booking_id,
    fromRoute,
    selectedDate,
  } = location.state;

  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD').local();
    const formattedDate = date.format('DD MMMM YYYY');
    const now = dayjs().local();

    if (date.isSame(now, 'day')) {
      return `Today, ${formattedDate}`;
    } else if (date.isSame(now.add(1, 'day'), 'day')) {
      return `Tomorrow, ${formattedDate}`;
    } else {
      return `${date.format('dddd')}, ${formattedDate}`;
    }
  };
  const formattedDate = formatDate(selectedDate);

  const dispatch = useDispatch();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const employeeIds = selectedEmployee.map((employee) => employee.employee_id);
  const [bookings, setBookings] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);
  const [bookingMessage, setBookingMessage] = useState('');
  const [cancelMessage, setCancelMessage] = useState('');
  const history = useHistory();
  const isFutureDate = dayjs(entryDateTime).isSameOrAfter(dayjs(), 'day');
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [showBottomButton, setShowBottomButton] = useState(false);

  const accessData = useSelector(selectAccessData);
  const meetAccessData = accessData?.data?.find(
    (item) => item.feature_key === '_meetingRoomBooking'
  );
  const [showCancelButton, setShowCancelButton] = useState(true);

  useEffect(() => {
    const actions = meetAccessData?.actions;
    setShowCancelButton(actions?.includes('_cancel'));
  }, [meetAccessData?.actions]);

  const callBookingDetailApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const response = await msApiRequest(
        MEETING_ROOM_BOOKING_DETAIL + booking_id,
        REQUEST_TYPE.GET,
        {
          org_id: 1,
        }
      );
      if (response.response.statusCode === 200) {
        setBookings(response.response.data);
      }
      dispatch(resetLoaderState());
    } catch (error) {
      dispatch(resetLoaderState());
      console.error(error);
      return null;
    }
  }, [booking_id, dispatch]);

  const BookMeetingRoom = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const formData = new URLSearchParams();
      employeeData.employee_id && formData.append('user_id', employeeData.employee_id);
      entryDateTime && formData.append('entry_datetime', entryDateTime);
      exitDateTime && formData.append('exit_datetime', exitDateTime);
      meetingArray && formData.append('room_id', meetingArray?._id);
      localTimezone && formData.append('timezone', localTimezone);
      formData.append('org_id', '1');
      meetingName && formData.append('title', meetingName);
      meetingAgenda && formData.append('agenda', meetingAgenda);
      formData.append('repeat', false);
      formData.append('reminder', false);
      formData.append('attendee_count', 0);
      employeeIds.forEach((id) => formData.append('attendee_user_ids[]', id));
      externalParticipantList &&
        externalParticipantList.forEach((email) => formData.append('external_email_ids[]', email));

      const response = await msApiRequest('meeting-room/bookings', REQUEST_TYPE.POST, formData);
      if (response.response.statusCode === 201) {
        setBookingMessage(response.response.message);
        setShowSuccessModal(true);
      }
      dispatch(resetLoaderState());
    } catch (error) {
      dispatch(resetLoaderState());
      showToast(error);
      console.error(error);
      return null;
    }
  }, [
    dispatch,
    employeeData.employee_id,
    employeeIds,
    entryDateTime,
    exitDateTime,
    externalParticipantList,
    localTimezone,
    meetingAgenda,
    meetingArray,
    meetingName,
  ]);

  const callCancelBookingApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const response = await msApiRequest(
        MEETING_ROOM_BOOKING_CANCEL + booking_id,
        REQUEST_TYPE.DELETE
      );
      if (response.response.statusCode === 200) {
        setCancelMessage(response.response.message);
        setShowCancelSuccessModal(true);
      }
      dispatch(resetLoaderState());
    } catch (error) {
      dispatch(resetLoaderState());
      console.error(error);
      return null;
    }
  }, [dispatch, booking_id]);

  useEffect(() => {
    if (fromRoute === 'BookingScreen') {
      callBookingDetailApi();
    }
  }, [callBookingDetailApi, fromRoute]);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_MEETING_DETAIL_SCREEN, NAVIGATION_ROUTES.MEETING_DETAIL);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const topButton = document.getElementById('topActionButton');
      if (!topButton) return;

      const buttonPosition = topButton.getBoundingClientRect().top;
      setShowBottomButton(buttonPosition < 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const ActionButton = () => {
    if (!isFutureDate || (fromRoute === 'BookingScreen' && bookings?.status === 'Rejected')) {
      return null;
    }

    if (fromRoute === 'BookingScreen' && bookings?.status !== 'Cancelled' && showCancelButton) {
      return (
        <CustomButton
          buttonStyle="estore-add-to-cart-button mt-2"
          onClick={() => {
            setShowCancelConfirmationModal(true);
            logEvent(
              ANALYTICS_EVENT_TYPES.MI_MEETING_BOOKING_CANCEL_BUTTON_CLICKED,
              '',
              ANALYTICS_ITEM_NAMES.MIMEETING
            );
          }}>
          CANCEL
        </CustomButton>
      );
    }

    if (fromRoute !== 'BookingScreen') {
      return (
        <CustomButton
          buttonStyle="estore-add-to-cart-button mt-2"
          onClick={() => {
            setShowConfirmationModal(true);
            logEvent(
              ANALYTICS_EVENT_TYPES.MI_MEETING_BOOKING_CANCEL_BUTTON_CLICKED,
              '',
              ANALYTICS_ITEM_NAMES.MIMEETING
            );
          }}>
          BLOCK ROOM
        </CustomButton>
      );
    }

    return null;
  };

  return (
    <div className="container">
      <SectionHeader title={'MiMEETING'} />
      <div className="header-view justify-content-between mb-2">
        <BackButton isFullRow={false} />
        {isFutureDate && bookings?.status !== 'Rejected' && (
          <div className="broadcast-header-row justify-content-end">
            <div className="broadcast-icon-row mr-3" id="topActionButton">
              <ActionButton />
            </div>
          </div>
        )}
      </div>
      <CustomModal
        visible={showConfirmationModal}
        onClose={() => {
          null;
        }}
        description={'Are you sure?<br />You would like to block this meeting room.'}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => setShowConfirmationModal(false)}
        onClickPrimaryButton={() => {
          BookMeetingRoom();
          setShowConfirmationModal(false);
        }}
      />
      <CustomModal
        visible={showSuccessModal}
        onClose={() => {
          dispatch(saveMeetingArray({}));
          setShowSuccessModal(false);
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
        }}
        description={`${bookingMessage}`}
        title={MODAL_TITLES.SUBMISSION_SUCCESS}
        primaryButtonTitle={BUTTON_TITLES.OK}
        onClickPrimaryButton={() => {
          dispatch(saveMeetingArray({}));
          setShowSuccessModal(false);
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
        }}
      />
      <CustomModal
        visible={showCancelConfirmationModal}
        onClose={() => {
          setShowCancelConfirmationModal(false);
        }}
        description={'Are you sure?<br />You want to cancel this booking.'}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => setShowCancelConfirmationModal(false)}
        onClickPrimaryButton={() => {
          setShowCancelConfirmationModal(false);
          callCancelBookingApi();
        }}
      />
      <CustomModal
        visible={showCancelSuccessModal}
        onClose={() => {
          setShowCancelSuccessModal(false);
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
        }}
        description={cancelMessage}
        title={MODAL_TITLES.SUBMISSION_SUCCESS}
        primaryButtonTitle={BUTTON_TITLES.OK}
        onClickPrimaryButton={() => {
          setShowCancelSuccessModal(false);
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
        }}
      />
      <div className="seat-booking-colored-container p-2 mb-2">
        <div className="d-flex flex-row justify-content-between">
          <div
            className={
              bookings.roomData?.layouts.length > 0 || meetingArray.layouts.length > 0
                ? 'seat-booking-white-background p-3 d-flex flex-row mi-meeting-rs-border-radius mi-meet-det-container mr-2'
                : 'seat-booking-white-background p-3 d-flex flex-row mi-meeting-rs-border-radius w-100'
            }>
            <div className="meet-prev-left-con d-flex flex-column">
              <div>
                <div className="d-flex meet-prev-title mb-2" style={{ wordBreak: 'break-word' }}>
                  {meetingArray?.title}
                </div>
                <div className="base-12px-font mt-2">
                  {fromRoute !== 'BookingScreen'
                    ? meetingArray?.facility_details?.title
                    : bookings?.facilityDetails?.title}
                </div>
              </div>
              <div className="meet-prev-cap-text d-flex mt-2">
                Max capacity: {meetingArray?.capacity} people
              </div>
            </div>
            {(meetingArray?.equipments?.length > 0 ||
              bookings?.roomData?.equipments?.length > 0) && (
              <div className="meet-prev-vertical-divider mx-5" />
            )}
            {fromRoute !== 'BookingScreen' ? (
              <div className="meet-prov-con mr-3">
                {meetingArray?.equipments?.map((provision, index) => {
                  return (
                    <div key={`${index}`} className="meet-prev-provision-container">
                      <img
                        src={provision.icon_path}
                        className="meet-prev-prov-icon common-cursor-pointer"
                      />
                      <div className="meet-prev-tooltip">{provision.title}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="meet-prov-con mr-3">
                {bookings?.roomData?.equipments?.map((provision, index) => {
                  return (
                    <div key={`${index}`} className="meet-prev-provision-container">
                      <img
                        src={provision.icon_path}
                        className="meet-prev-prov-icon common-cursor-pointer"
                      />
                      <div className="meet-prev-tooltip">{provision.title}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {bookings && bookings.roomData?.layouts.length > 0 && (
            <div className="mi-meet-gallery-container">
              <Carousel
                activeIndex={currentActiveSlideIndex}
                className="pb-1 seat-booking-carousel"
                nextIcon={
                  bookings.roomData?.layouts.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-next-icon" />
                  )
                }
                prevIcon={
                  bookings.roomData?.layouts.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-prev-icon" />
                  )
                }
                onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                {bookings.roomData?.layouts.map((imageItem, index) => {
                  return (
                    <Carousel.Item key={`${index}`}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="mi-meet-gallery-container seat-booking-carousel-border"
                          src={imageItem.image}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          {fromRoute !== 'BookingScreen' && meetingArray && meetingArray?.layouts.length > 0 && (
            <div className="mi-meet-gallery-container">
              <Carousel
                activeIndex={currentActiveSlideIndex}
                className="pb-1 seat-booking-carousel"
                nextIcon={
                  meetingArray?.layouts.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-next-icon" />
                  )
                }
                prevIcon={
                  meetingArray?.layouts.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-prev-icon" />
                  )
                }
                onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                {meetingArray?.layouts.map((imageItem, index) => {
                  return (
                    <Carousel.Item key={`${index}`}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="mi-meet-gallery-container seat-booking-carousel-border"
                          src={imageItem.image}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
        <div className="seat-booking-white-background p-3 mi-meeting-rs-border-radius mt-2">
          <HtmlView html={meetingArray?.description} htmlStyle="meet-prev-subtitle" />
        </div>

        <div className="seat-booking-white-background p-2 mi-meeting-rs-border-radius mt-2">
          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NAME,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
            />
            <HtmlView html={meetingName} htmlStyle="base-14px-font-bold meet-prev-date-txt" />
          </div>
          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center">
            <UserProfilePhoto
              imageBaseUrl={employeeData.profile_picture.base_url}
              imagePath={employeeData.profile_picture.image_path}
              employeeId={employeeData.employee_id}
              imageClass={'meet-prev-prof-icon'}
            />
            <div>
              <p className="meet-prev-org-name-text base-12px-font m-0 ml-2">
                {`${employeeData.first_name + ' ' + employeeData.last_name}`.length < 30
                  ? `${employeeData.first_name + ' ' + employeeData.last_name}`
                  : `${employeeData.first_name + ' ' + employeeData.last_name}`.substring(0, 26) +
                    '...'}
              </p>
            </div>
            <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
            <div className="base-12px-font-semi-bold">Organizer</div>
          </div>
          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-column mb-1">
            <div className="d-flex align-items-center mb-3">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ADD_PARTICIPANT,
                  ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                )}
                className="meet-prev-form-icon mr-2"
              />
              <span className="base-14px-font-semi-bold">Participants</span>
            </div>
            <div className="meet-detail-emp-grid mr-3">
              {selectedEmployee?.map((emp, index) => {
                return (
                  <div className="meet-detail-employee-item" key={`${index}`}>
                    <UserProfilePhoto
                      imageBaseUrl={emp.profile_picture.base_url}
                      imagePath={emp.profile_picture.image_path}
                      employeeId={emp.employee_id}
                      imageClass={'meet-prev-prof-icon'}
                    />
                    <div className="pr-2">
                      {fromRoute !== 'BookingScreen' ? (
                        <p className="base-10px-font m-0 ml-2">
                          {`${emp.employee_name}`.length < 30
                            ? `${emp.employee_name}`
                            : `${emp.employee_name}`.substring(0, 26) + '...'}
                        </p>
                      ) : (
                        <p className="base-10px-font m-0 ml-2">
                          {`${emp.first_name + ' ' + emp.last_name}`.length < 30
                            ? `${emp.first_name + ' ' + emp.last_name}`
                            : `${emp.first_name + ' ' + emp.last_name}`.substring(0, 26) + '...'}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center">
            <div className="d-flex flex-row align-items-center">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="meet-prev-dt-icon mr-2"
              />
              <div className="base-14px-font-semi-bold meet-prev-date-txt">
                {fromRoute === 'BookingScreen' ? selectedDate : formattedDate || ''}
              </div>
            </div>
            <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
            <div className="d-flex flex-row align-items-center">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CLOCK,
                  ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                )}
                className="meet-prev-dt-icon mr-2"
              />
              <div className="base-14px-font-semi-bold meet-prev-date-txt">
                Start time - {checkInTime || ''}
              </div>
            </div>
            <div style={{ height: 40 }} className="meet-prev-vertical-divider mx-3" />
            <div className="d-flex flex-row align-items-center">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CLOCK,
                  ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                )}
                className="meet-prev-dt-icon mr-2"
              />
              <div className="base-14px-font-semi-bold meet-prev-date-txt">
                End time - {checkOutTime || ''}
              </div>
            </div>
          </div>
          {externalParticipantList?.length > 0 && (
            <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-column mb-1">
              <div className="d-flex align-items-center mb-3">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ADD_PARTICIPANT,
                    ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                  )}
                  className="meet-prev-form-icon mr-2"
                />
                <span className="base-14px-font-semi-bold">External participants</span>
              </div>
              <div className="external-participant-grid">
                {externalParticipantList?.map((ext, index) => (
                  <div className="external-participant-item" key={`${index}`}>
                    {ext}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-center">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.LOCATION,
                ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
              )}
              className="meet-prev-form-icon mr-2"
            />
            <div className="base-14px-font-semi-bold meet-prev-date-txt">
              {fromRoute !== 'BookingScreen'
                ? meetingArray?.facility_details?.title
                : bookings?.facilityDetails?.title}
            </div>
          </div>

          {!!meetingAgenda && (
            <div className="meet-prev-colored-bg mi-meeting-rs-border-radius p-3 d-flex flex-row mb-1 align-items-start">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.INFORMATION,
                  ACCESSIBILITY_IDENTIFIERS.MEETING_ROOM
                )}
                className="meet-prev-form-icon mr-2"
              />
              <HtmlView html={meetingAgenda} htmlStyle="base-12px-font meet-det-agenda-txt" />
            </div>
          )}
        </div>
      </div>
      {showBottomButton && isFutureDate && bookings?.status !== 'Rejected' && (
        <div className="meet-prev-floating-button-container">
          <div className="meet-prev-floating-button-inner">
            <ActionButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingDetailScreen;
