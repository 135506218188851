import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  selectBloodGroupFilters,
  selectFacilityFilters,
  selectGeographies,
  selectLayers,
  selectLevels,
  selectLocations,
  selectRegionFilters,
  selectYearFilters,
} from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { sortArrayOfObjects } from '../../../utils/common';
import {
  FILTER_IDENTIFIERS,
  FILTER_TITLES,
  FILTER_ANALYTICS_PREFIXES,
  API_PARAMS,
  MESSAGES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CUSTOMERS,
  NAVIGATION_ROUTES,
  HOLIDAY,
} from '../../constants/AppConstants';
import CustomButton from '../custom_button/CustomButton';
import FilterHeader from './components/FilterHeader';
import MultiSelectButton from './components/MultiSelectButton';
import SingleSelectButton from './components/SingleSelectButton';
import { selectEmployeeData } from '../../../state/EmployeeData';

const HolidayFilter = (props) => {
  const {
    topFilter = null,
    geographies = false,
    locations = false,
    levels = false,
    layers = false,
    serviceTenures = false,
    bloodGroups = false,
    facilities = false,
    regions = false,
    dealYear = false,
    fromRoute,
    isNewJoinees,
    holidayYear = false,
  } = props;

  const location = useLocation();
  const employeeData = useSelector(selectEmployeeData);

  const accessibilityData = useSelector(selectAccessibilityData);
  const geographiesData = sortArrayOfObjects(useSelector(selectGeographies), 'title');
  const locationData = sortArrayOfObjects(useSelector(selectLocations), 'title');
  const facilityData = sortArrayOfObjects(useSelector(selectFacilityFilters), 'title');
  const regionData = sortArrayOfObjects(useSelector(selectRegionFilters), 'title');
  const CustomerDealYear = props.dealYearList;
  const levelsData = useSelector(selectLevels);
  const layersData = useSelector(selectLayers);
  const yearData = useSelector(selectYearFilters);
  const bloodGroupData = sortArrayOfObjects(useSelector(selectBloodGroupFilters), 'title');

  const [appliedFilterTitles, setAppliedFilterTitles] = useState(MESSAGES.NO_FILTERS);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedFilterIds, setSelectedFilterIds] = useState({});
  const [selectedFilterTitles, setSelectedFilterTitles] = useState([]);
  const [geographiesSelected, setGeographiesSelected] = useState([
    employeeData?.geography?.geography_id,
  ]);
  const [holidayYearsSelected, setHolidayYearsSelected] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState([employeeData?.location?.location_id]);
  const [facilitiesSelected, setFacilitiesSelected] = useState([]);
  const [regionsSelected, setRegionsSelected] = useState([]);
  const [dealYearsSelected, setDealYearsSelected] = useState([]);
  const [levelsSelected, setLevelsSelected] = useState([]);
  const [layersSelected, setLayersSelected] = useState([]);
  const [bloodGroupsSelected, setBloodGroupsSelected] = useState([]);
  const [yearsSelected, setYearsSelected] = useState([]);

  const highLightedButtonStyle = 'filter-selected-button';
  const highLightedTextStyle = 'filter-selected-text';
  const unhighLightedButtonStyle = 'filter-unselected-button';
  const unhighLightedTextStyle = 'filter-unselected-text';
  const holidayGeographies = sortArrayOfObjects(props?.holidayGeographies, 'title');
  const holidayLocations = sortArrayOfObjects(props?.holidayLocations, 'title');
  const holidayYearList = sortArrayOfObjects(props?.holidayYearList, 'title');

  let filteredSelectedFilterTitles = [];

  if (selectedFilterTitles && selectedFilterTitles.length > 0) {
    filteredSelectedFilterTitles = selectedFilterTitles.filter((title) => {
      return !yearData.some((yearItem) => yearItem.title === title);
    });
  }

  const clearFilters = () => {
    setIsFilterVisible(false);
    setAppliedFilterTitles([]);
    setSelectedFilterTitles([]);
    setSelectedFilterIds({});
    setGeographiesSelected([employeeData?.geography?.geography_id]);
    setHolidayYearsSelected([]);
    setLocationsSelected([employeeData?.location?.location_id]);
    setFacilitiesSelected([]);
    setRegionsSelected([]);
    setDealYearsSelected([]);
    setLevelsSelected([]);
    setLevelsSelected([]);
    setLayersSelected([]);
    setBloodGroupsSelected([]);
    setYearsSelected([]);
    props.setIsClear(false);
    props.onClear({});
  };

  useEffect(() => {
    selectedFilterIds[API_PARAMS.EMPLOYEES.GEOGRAPHY_ID] = geographiesSelected;
    selectedFilterIds[API_PARAMS.EMPLOYEES.LOCATION_ID] = locationsSelected;
    selectedFilterIds[API_PARAMS.EMPLOYEES.LEVEL_ID] = levelsSelected;
    selectedFilterIds[API_PARAMS.EMPLOYEES.LAYER_ID] = layersSelected;
    selectedFilterIds[API_PARAMS.EMPLOYEES.RANGE] = yearsSelected;
    selectedFilterIds[API_PARAMS.EMPLOYEES.BLOOD_GROUP] = bloodGroupsSelected;
    selectedFilterIds[CUSTOMERS.FACILITY_ID] = facilitiesSelected;
    selectedFilterIds[CUSTOMERS.REGION_ID] = regionsSelected;
    selectedFilterIds[CUSTOMERS.DEAL_YEAR] = dealYearsSelected;
    selectedFilterIds[HOLIDAY.HOLIDAY_ID] = holidayYearsSelected;
  }, [
    geographiesSelected,
    locationsSelected,
    levelsSelected,
    layersSelected,
    yearsSelected,
    bloodGroupsSelected,
    selectedFilterIds,
    facilitiesSelected,
    dealYearsSelected,
    regionsSelected,
    holidayYearsSelected,
  ]);

  const displayTitleHandler = () => {
    if (selectedFilterTitles) {
      if (selectedFilterTitles.length === 1) {
        setAppliedFilterTitles(`${selectedFilterTitles[0]}`);
      } else if (selectedFilterTitles.length > 1) {
        setAppliedFilterTitles(
          `${selectedFilterTitles[0]} + ${selectedFilterTitles.length - 1} other applied`
        );
      } else {
        setAppliedFilterTitles(MESSAGES.NO_FILTERS);
      }
    } else {
      setAppliedFilterTitles(MESSAGES.NO_FILTERS);
    }
    return appliedFilterTitles;
  };

  useEffect(() => {
    if (selectedFilterTitles) {
      if (isNewJoinees && selectedFilterIds?.range?.length > 0) {
        if (filteredSelectedFilterTitles.length === 1) {
          setAppliedFilterTitles(`${filteredSelectedFilterTitles[0]}`);
        } else if (filteredSelectedFilterTitles.length > 1) {
          setAppliedFilterTitles(
            `${filteredSelectedFilterTitles[0]} + ${
              filteredSelectedFilterTitles.length - 1
            } other applied`
          );
        } else {
          setAppliedFilterTitles(MESSAGES.NO_FILTERS);
        }
      } else if (selectedFilterTitles.length === 1) {
        setAppliedFilterTitles(`${selectedFilterTitles[0]}`);
      } else if (selectedFilterTitles.length > 1) {
        setAppliedFilterTitles(
          `${selectedFilterTitles[0]} + ${selectedFilterTitles.length - 1} other applied`
        );
      } else {
        setAppliedFilterTitles(MESSAGES.NO_FILTERS);
      }
    } else {
      setAppliedFilterTitles(MESSAGES.NO_FILTERS);
    }
  }, [
    filteredSelectedFilterTitles,
    isNewJoinees,
    selectedFilterIds?.range?.length,
    selectedFilterTitles,
  ]);

  useEffect(() => {
    if (props.isChatbotActionable) {
      if (
        location.state &&
        location.state.chatbotParams &&
        location.state.chatbotParams.filters &&
        location.state.chatbotParams.filters.filter_titles
      )
        if (location.state.chatbotParams.filters.filter_titles.length >= 1) {
          setAppliedFilterTitles(
            `${location.state.chatbotParams.filters.filter_titles.join(', ')} filters applied`
          );
        } else {
          setAppliedFilterTitles(MESSAGES.NO_FILTERS);
        }
    }
  }, [location.state, props.isChatbotActionable]);

  const onPressApplyFilterHandler = () => {
    props.setIsClear(false);
    displayTitleHandler();
    setIsFilterVisible(false);
    console.log('selectedFilterIds', selectedFilterIds);
    props.onClick(selectedFilterIds);
  };

  const onClickFilterButtonHandler = () => {
    setIsFilterVisible((val) => !val);
  };

  const isButtonDisabled =
    props?.fromRoute === NAVIGATION_ROUTES.HOLIDAY_SCREEN
      ? !locationsSelected.length
      : !geographiesSelected.length &&
        !locationsSelected.length &&
        !facilitiesSelected.length &&
        !regionsSelected.length &&
        !dealYearsSelected.length &&
        !levelsSelected.length &&
        !layersSelected.length &&
        !yearsSelected.length &&
        !holidayYearsSelected &&
        !bloodGroupsSelected.length;

  const isClearDisabled =
    !geographiesSelected.length &&
    !locationsSelected.length &&
    !facilitiesSelected.length &&
    !regionsSelected.length &&
    !dealYearsSelected.length &&
    !levelsSelected.length &&
    !layersSelected.length &&
    !yearsSelected.length &&
    !holidayYearsSelected &&
    !bloodGroupsSelected.length;

  return (
    <div className="main-container">
      <div className="row">
        {fromRoute !== NAVIGATION_ROUTES.HOLIDAY_SCREEN && (
          <div className={props.isChatbotActionable ? 'col-9 col-sm-10' : 'col-8 col-sm-6'}>
            <p className="applied-filter-text">
              {appliedFilterTitles.length ? appliedFilterTitles : MESSAGES.NO_FILTERS}
            </p>
          </div>
        )}
        {props.isChatbotActionable ? (
          <div
            className={
              fromRoute === NAVIGATION_ROUTES.HOLIDAY_SCREEN
                ? 'col-3 col-sm-2 d-flex flex-row-reverse holiday-filter-style'
                : 'col-3 col-sm-2 d-flex flex-row-reverse'
            }>
            <CustomButton
              onClick={() => {
                props.setIsChatbotActionable(false);
                props.resetStates();
                clearFilters();
              }}>
              Reset
            </CustomButton>
          </div>
        ) : (
          <div className="col-4 col-sm-6 d-flex flex-row-reverse ml-auto mr-2">
            <img
              onClick={onClickFilterButtonHandler}
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_FILTER,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="filter-icon"
            />
          </div>
        )}
      </div>
      {isFilterVisible && (
        <div>
          <div>
            {geographies && (
              <div className="mt-3 ml-3 mb-1">
                <FilterHeader
                  filterTitle={
                    fromRoute === NAVIGATION_ROUTES.HOLIDAY_SCREEN
                      ? FILTER_TITLES.COUNTRY
                      : FILTER_TITLES.GEOGRAPHY
                  }
                  topFilter={topFilter === FILTER_IDENTIFIERS.GEOGRAPHIES}
                  onClear={clearFilters}
                  isClearDisabled={isClearDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {holidayGeographies.map((geography, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.GEOGRAPHY}
                        selectedItems={geographiesSelected}
                        onSelectHandler={setGeographiesSelected}
                        childSelectedItems={locationsSelected}
                        childSelectedItemsHandler={setLocationsSelected}
                        childLookup={holidayLocations}
                        childLookupTag={API_PARAMS.EMPLOYEES.LOCATION_ID}
                        childLookupParentTag={API_PARAMS.EMPLOYEES.GEOGRAPHY_ID}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={geography.title}
                        valueId={geography.geography_id}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {locations && !!geographiesSelected.length && (
              <div className="ml-3">
                <FilterHeader
                  filterTitle={FILTER_TITLES.LOCATION}
                  topFilter={topFilter === FILTER_IDENTIFIERS.LOCATIONS}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {holidayLocations.map((location, index) => {
                    if (geographiesSelected.includes(location.geography_id)) {
                      return (
                        <SingleSelectButton
                          key={index}
                          filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.LOCATION}
                          selectedItems={locationsSelected}
                          onSelectHandler={setLocationsSelected}
                          childSelectedItems={facilitiesSelected}
                          childSelectedItemsHandler={setFacilitiesSelected}
                          childLookup={facilityData}
                          childLookupTag={CUSTOMERS.FACILITY_ID}
                          childLookupParentTag={API_PARAMS.EMPLOYEES.LOCATION_ID}
                          selectedTitles={selectedFilterTitles}
                          filterTitlesHandler={setSelectedFilterTitles}
                          valueTitle={location.title}
                          valueId={location.location_id}
                          highLightedButtonStyle={highLightedButtonStyle}
                          highLightedTextStyle={highLightedTextStyle}
                          unhighLightedButtonStyle={unhighLightedButtonStyle}
                          unhighLightedTextStyle={unhighLightedTextStyle}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            )}
            {regions && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.REGIONS}
                  topFilter={topFilter === FILTER_IDENTIFIERS.REGIONS}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {regionData?.map((regions, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.REGIONS}
                        selectedItems={regionsSelected}
                        onSelectHandler={setRegionsSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={regions.title}
                        valueId={regions.region_id}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {facilities && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.FACILITIES}
                  topFilter={topFilter === FILTER_IDENTIFIERS.FACILITIES}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {facilityData?.map((customerFacilities, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.FACILITIES}
                        selectedItems={facilitiesSelected}
                        onSelectHandler={setFacilitiesSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={customerFacilities.title}
                        valueId={customerFacilities.facility_id}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {dealYear && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.DEAL_YEAR}
                  topFilter={topFilter === FILTER_IDENTIFIERS.DEAL_YEAR}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {CustomerDealYear?.revese()?.map((dealYears, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.DEAL_YEAR}
                        selectedItems={dealYearsSelected}
                        onSelectHandler={setDealYearsSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={dealYears}
                        valueId={dealYears}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {levels && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.LEVEL}
                  topFilter={topFilter === FILTER_IDENTIFIERS.LEVELS}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {levelsData.map((level, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.LEVEL}
                        selectedItems={levelsSelected}
                        onSelectHandler={setLevelsSelected}
                        childSelectedItems={layersSelected}
                        childSelectedItemsHandler={setLayersSelected}
                        childLookup={layersData}
                        childLookupTag={API_PARAMS.EMPLOYEES.LAYER_ID}
                        childLookupParentTag={API_PARAMS.EMPLOYEES.LEVEL_ID}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={level.title}
                        valueId={level.level_id}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {layers && !!levelsSelected.length && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.LAYER}
                  topFilter={topFilter === FILTER_IDENTIFIERS.LAYERS}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {layersData.map((layer, index) => {
                    if (levelsSelected.includes(layer.level_id)) {
                      return (
                        <MultiSelectButton
                          key={index}
                          filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.LAYER}
                          selectedItems={layersSelected}
                          onSelectHandler={setLayersSelected}
                          selectedTitles={selectedFilterTitles}
                          filterTitlesHandler={setSelectedFilterTitles}
                          valueTitle={layer.title}
                          valueId={layer.layer_id}
                          highLightedButtonStyle={highLightedButtonStyle}
                          highLightedTextStyle={highLightedTextStyle}
                          unhighLightedButtonStyle={unhighLightedButtonStyle}
                          unhighLightedTextStyle={unhighLightedTextStyle}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            )}
            {serviceTenures && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.SERVICE_TENURE}
                  topFilter={topFilter === FILTER_IDENTIFIERS.SERVICE_TENURES}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {yearData.map((year, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.SERVICE_TENURE}
                        selectedItems={yearsSelected}
                        onSelectHandler={setYearsSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={year.title}
                        valueId={year.key}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {bloodGroups && (
              <div>
                <FilterHeader
                  filterTitle={FILTER_TITLES.BLOOD_GROUP}
                  topFilter={topFilter === FILTER_IDENTIFIERS.BLOOD_GROUPS}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {bloodGroupData.map((bloodGroup, index) => {
                    return (
                      <MultiSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.BLOOD_GROUP}
                        selectedItems={bloodGroupsSelected}
                        onSelectHandler={setBloodGroupsSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={bloodGroup.title}
                        valueId={bloodGroup.key}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            {holidayYear && (
              <div className="ml-3">
                <FilterHeader
                  filterTitle={FILTER_TITLES.HOLIDAY_YEAR}
                  topFilter={topFilter === FILTER_IDENTIFIERS.HOLIDAY_YEAR}
                  onClear={clearFilters}
                  isClearDisabled={isButtonDisabled}
                />
                <div className="d-flex flex-row flex-wrap pt-3">
                  {holidayYearList?.reverse().map((year, index) => {
                    return (
                      <SingleSelectButton
                        key={index}
                        filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.HOLIDAY_YEAR}
                        selectedItems={holidayYearsSelected}
                        onSelectHandler={setHolidayYearsSelected}
                        selectedTitles={selectedFilterTitles}
                        filterTitlesHandler={setSelectedFilterTitles}
                        valueTitle={year.title}
                        valueId={year.title}
                        highLightedButtonStyle={highLightedButtonStyle}
                        highLightedTextStyle={highLightedTextStyle}
                        unhighLightedButtonStyle={unhighLightedButtonStyle}
                        unhighLightedTextStyle={unhighLightedTextStyle}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div
            className={
              fromRoute === NAVIGATION_ROUTES.HOLIDAY_SCREEN
                ? 'pr-3 pb-4 d-flex ml-2'
                : 'pr-3 pb-4 d-flex'
            }>
            <CustomButton
              disabled={isButtonDisabled}
              onClick={onPressApplyFilterHandler}
              buttonStyle="mr-2">
              Apply
            </CustomButton>
            <CustomButton
              disabled={
                props?.fromRoute === NAVIGATION_ROUTES.HOLIDAY_SCREEN
                  ? isClearDisabled
                  : isButtonDisabled
              }
              onClick={clearFilters}>
              Clear
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default HolidayFilter;
