import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { saveProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { apiRequest, handleError } from '../../../services/Service';
import { BUSINESS_CARD, VCF_CARD_TARGET } from '../../../services/ApiUrls';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { Modal, Overlay, Popover } from 'react-bootstrap';
import { showToast } from '../../../utils/common';
import html2canvas from 'html2canvas';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectEmployeeData } from '../../../state/EmployeeData';

const BusinessCard = () => {
  const dispatch = useDispatch();
  const [businessCard, setBusinessCard] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showQRCode, setShowQRCode] = useState(false);
  const [QRCode, setQRCode] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [bcText, setBcText] = useState({});
  const [showBCTextModal, setShowBCTextModal] = useState(false);
  const employeeData = useSelector(selectEmployeeData);
  const target = useRef();

  const bottomSheetItems = [
    {
      type: 'string',
      title: 'Text',
      image: 'BC_TEXT',
    },
    {
      type: 'image',
      title: 'Image',
      image: 'BC_PICTURE',
    },
    {
      type: 'vcf',
      title: 'VCF',
      image: 'BC_VCF',
    },
    {
      type: 'qr',
      title: 'QR',
      image: 'BC_QR_CODE',
    },
  ];

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_APP_SETTINGS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  const getBusinessCardImage = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const params = {
        vcfcard_format: 'image',
        business_card: true,
        vcard: false,
      };
      const apiResponse = await apiRequest(BUSINESS_CARD, REQUEST_TYPE.GET, params);

      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          if (Object.keys(apiResponse.response).length) {
            const data = apiResponse.response.employeeData;
            setBusinessCard(data.business_card.base_url + data.business_card.image_path);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, BUSINESS_CARD, NAVIGATION_ROUTES.PROFILE_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    getBusinessCardImage();
  }, [getBusinessCardImage]);

  const shareBusinessCard = useCallback(
    async (content, type) => {
      logEvent(ANALYTICS_EVENT_TYPES.SHARE_BUSINESS_CARD, type, ANALYTICS_ITEM_NAMES.MY_PROFILE);
      try {
        switch (type) {
          case 'string': {
            setBcText(content);
            setShowBCTextModal(true);
            break;
          }
          case 'image':
            html2canvas(document.getElementById('business-card'), {
              useCORS: true,
              onclone: function (clonedDoc) {
                clonedDoc.getElementById('business-card').classList.add('d-block');
              },
            })
              .then((canvas) => {
                document.getElementById('business-card').classList.remove('d-block');

                let xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function () {
                  let a = document.createElement('a');
                  a.href = window.URL.createObjectURL(xhr.response);
                  a.download = `${employeeData.first_name}_${
                    employeeData.middle_name ? `${employeeData.middle_name}_` : ''
                  }${employeeData.last_name}.jpeg`;
                  a.style.display = 'none';
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                };
                xhr.open('GET', canvas.toDataURL('image/jpeg'));
                xhr.send();
                dispatch(saveProgressLoadingState({ isProgressLoading: false }));
              })
              .catch((e) => {
                dispatch(saveProgressLoadingState({ isProgressLoading: false }));
              });
            break;
          case 'qr':
            setQRCode(content.vcard.base_url + content.vcard.image_path);
            setShowQRCode(true);
            break;
        }
      } catch (e) {
        // console.log(e);
      }
    },
    [dispatch]
  );

  const fetchBusinessCard = useCallback(
    async (type) => {
      setShowOverlay(false);
      if (type === 'vcf') {
        window.open(VCF_CARD_TARGET, '_blank');
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        try {
          const params = {
            vcfcard_format: type === 'qr' ? 'image' : type,
            business_card: type === 'image',
            vcard: true,
          };
          const apiResponse = await apiRequest(BUSINESS_CARD, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              if (Object.keys(apiResponse.response).length) {
                const data = apiResponse.response.employeeData;
                shareBusinessCard(data, type);
              }
            }
          }
        } catch (err) {
          handleError(err, {}, BUSINESS_CARD, NAVIGATION_ROUTES.PROFILE_DETAIL);
          // console.error(err);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, shareBusinessCard]
  );

  const handleCopyText = () => {
    const copyText = `${bcText.employee_name}\n${bcText.position_title}, ${bcText.department_name}\n\nEmail: ${bcText.email}\nM: ${bcText.mobile_number}\n\n${bcText.address}\n\nwww.microland.com`;
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        showToast('Text copied to clipboard! You can share with others.');
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  };

  return (
    <div className="w-50">
      <Modal
        onHide={() => setShowQRCode(false)}
        centered
        show={showQRCode}
        aria-labelledby="contained-modal-title-vcenter">
        <div className="business-card-qr-container">
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.SF_CLOSE_ICON,
              ACCESSIBILITY_IDENTIFIERS.MI_HR
            )}
            className="close-icon-business-card-qr"
            onClick={() => setShowQRCode(false)}
          />
          <img src={QRCode} className="embed-responsive embed-responsive-16by9" />
        </div>
      </Modal>
      <Modal
        onHide={() => setShowBCTextModal(false)}
        centered
        show={showBCTextModal}
        aria-labelledby="contained-modal-title-vcenter">
        <div className="bc-text-modal-container">
          <div className="bc-text-close-icon-view">
            <div className="share-business-card-title">SHARE BUSINESS CARD</div>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.SF_CLOSE_ICON,
                ACCESSIBILITY_IDENTIFIERS.MI_HR
              )}
              className="bc-text-modal-close"
              onClick={() => setShowBCTextModal(false)}
            />
          </div>
          <div className="bc-text-modal-text-container">
            <div className="mb-2">
              <div className="neo-induction-text">{bcText.employee_name}</div>
              <div className="neo-induction-text">
                {bcText.position_title}, {bcText.department_name}
              </div>
            </div>
            <div className="mb-2">
              <div className="neo-induction-text">
                Email: <a href={`mailto:${bcText.email}`}>{bcText.email}</a>
              </div>
              <div className="neo-induction-text">M: {bcText.mobile_number}</div>
            </div>
            <div className="neo-induction-text">{bcText.address}</div>
          </div>
          <div className="bc-text-modal-button-container">
            <div>
              <div className="neo-induction-text">
                <a href="https://www.microland.com">www.microland.com</a>
              </div>
            </div>
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={handleCopyText}>
              COPY
            </CustomButton>
          </div>
        </div>
      </Modal>
      <div className="d-flex flex-row mt-5 mb-3">
        <img
          id="business-card"
          src={businessCard}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="header-view d-flex justify-content-between align-items-center">
          <div className="mi-hr-activity-btn-container common-cursor-pointer">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_SHARE,
                ACCESSIBILITY_IDENTIFIERS.ECARDS
              )}
              className="business-card-share-icon ml-2 mt-1"
              ref={target}
              onClick={() => setShowOverlay(!showOverlay)}
            />
            <Overlay
              onHide={() => setShowOverlay(false)}
              target={target.current}
              show={showOverlay}
              placement="bottom-end">
              <Popover>
                <Popover.Content className="wallpaper-info-popover-container">
                  <div className="p-2">
                    {bottomSheetItems.map((item, index) => (
                      <div
                        className="business-card-menu-item"
                        onClick={() => fetchBusinessCard(item.type)}
                        key={index}>
                        <img
                          className="ceo-chat-image-size mr-2"
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS[item.image],
                            ACCESSIBILITY_IDENTIFIERS.PROFILE
                          )}
                        />
                        <div className="ijp-search-item-title">{item.title}</div>
                      </div>
                    ))}
                  </div>
                </Popover.Content>
              </Popover>
            </Overlay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BusinessCard);
