import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';

const QuickBookingItem = ({ item, onQuickBook }) => {
  const history = useHistory();
  const entryDatetime = item?.entry_datetime ? dayjs(item?.entry_datetime) : null;
  const exitDatetime = item?.exit_datetime ? dayjs(item?.exit_datetime) : null;
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('dddd, DD MMMM YYYY');
  };
  const formattedDate = entryDatetime ? formatDate(entryDatetime) : 'N/A';
  const checkInTime = entryDatetime ? entryDatetime.format('hh:mm A') : 'N/A';
  const checkOutTime = exitDatetime ? exitDatetime.format('hh:mm A') : 'N/A';
  return (
    <div className="seat-quick-booking-card-container mr-2 p-3">
      {item?.facilityTitle && (
        <div className="base-12px-font mb-2 quick-book-title-container">{item?.facilityTitle}</div>
      )}
      <div className="d-flex flex-row justify-content-between">
        {item?.levelName && item?.spaceData.name ? (
          <div className="d-flex align-self-center booking-home-subtitle">
            {item?.levelName}, {item?.spaceData.name}
          </div>
        ) : item?.spaceData.name ? (
          <div className="d-flex align-self-center booking-home-subtitle">
            {item?.spaceData.name}
          </div>
        ) : null}

        <div
          onClick={() =>
            onQuickBook({
              fromRoute: 'QuickBooking',
              checkInTime: dayjs(checkInTime, 'h:mm A').format('HH:mm'),
              checkOutTime: dayjs(checkOutTime, 'h:mm A').format('HH:mm'),
              facilityId: item.spaceData.facility_id,
              levelId: item.spaceData.level_id,
              selectedDate: formattedDate,
              entryDatetime: entryDatetime,
              exitDatetime: exitDatetime,
              space_id: item.spaceData._id,
            })
          }
          className="d-flex flex-row justify-content-center align-items-center quick-booking-btn common-cursor-pointer py-1 px-2">
          <div className="quick-booking-btn-text common-cursor-pointer">Book again</div>
        </div>
      </div>
    </div>
  );
};

export default QuickBookingItem;
