import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import _ from 'lodash';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessData } from '../../../state/UserAccessData';

const MeetTheSpeakersTab = (props) => {
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const dispatch = useDispatch();
  const pageId = useRef(1);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessData = useSelector(selectAccessData);
  const [showIwdLike, setShowLike] = useState(true);
  const [showIwdComment, setShowcomment] = useState(true);
  const iwdAccessData = accessData?.data?.find((item) => item.feature_key === '_womensDay');

  useEffect(() => {
    const actions = iwdAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
  }, [iwdAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_MEET_THE_SPEAKERS_TAB,
      props?.item?.key,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const callArticlesApi = useCallback(async () => {
    const params = {
      page_id: pageId.current,
      channel_id: props?.item?.channel_id,
      sub_channel_id: props?.item?.sub_channel_id,
    };
    try {
      setLoading(true);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (!_.isEmpty(apiResponse)) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data) {
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
            const data = apiResponse?.response?.data?.feeds ?? [];
            if (data?.length) {
              setArticles((prevData) => [...prevData, ...data]);
              setLoadMoreBtn(true);
            } else {
              setLoadMoreBtn(false);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, ARTICLES, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setLoading(false);
    }
  }, [props?.item]);

  useEffect(() => {
    callArticlesApi();
  }, [callArticlesApi]);

  const onPressLoadMore = () => {
    pageId.current = pageId.current + 1;
    callArticlesApi();
  };

  const onPressArticles = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.WOMENS_DAY
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`, {
        screenIdetifier: ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY,
        showIwdComment: showIwdComment,
        showIwdLike: showIwdLike,
        channel_id: props?.item?.channel_id,
        sub_channel_id: props?.item?.sub_channel_id,
      });
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  return (
    <div>
      <div className="row">
        {articles &&
          articles.length > 0 &&
          articles.map((item) => {
            return (
              <div className="col-sm-4 common-cursor-pointer" key={item.article_id}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    onPressArticles(item);
                  }}>
                  <img
                    src={getUrlWithSpecificRendition(
                      item.image.base_url,
                      SCREEN_DENSITY_QUALIFIERS.HDPI,
                      item.image.image_path
                    )}
                    className="womens-day-image"
                  />
                  <div className="d-flex flex-row justify-content-between mt-1">
                    {!!item?.time_to_read && (
                      <div className="broadcast-event-date-new">
                        {item.time_to_read != 0
                          ? formatSecondsIntoMinutes(item.time_to_read)
                          : null}
                      </div>
                    )}
                    <div className="broadcast-event-date-new ">
                      {formatDateDDMonthYYYY(item.pub_datetime)}
                    </div>
                    {!!item.page_views && (
                      <div className="broadcast-event-date-new ">
                        {item.page_views > 1
                          ? `${item.page_views} views`
                          : `${item.page_views} view`}
                      </div>
                    )}
                  </div>
                  <div className="di-title mt-1">{item.title}</div>
                </div>
              </div>
            );
          })}
      </div>
      {articles.length === 0 && !progressLoadingState.isProgressLoading && emptyListView()}
      {loadMoreBtn && <LoadMoreBtn onClick={onPressLoadMore} />}
    </div>
  );
};

export default MeetTheSpeakersTab;
