import React, { useEffect } from 'react';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { useDispatch, useSelector } from 'react-redux';
import { saveVehicleParkingTabData, selectVehicleParkingTabData } from '../../state/TabData';
import { Tab } from 'react-bootstrap';
import { selectVehicleParkingTabsTitles } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import MyBookingsTab from './tabs/MyBookingsTab';
import VehicleParkingHomeTab from './VehicleParkingHomeTab';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { ANALYTICS_SCREEN_NAMES, NAVIGATION_ROUTES } from '../../common/constants/AppConstants';

const VehicleParkingMainScreen = () => {
  const dispatch = useDispatch();
  const seatTabs = useSelector(selectVehicleParkingTabsTitles);
  const seatBookingTabdata = useSelector(selectVehicleParkingTabData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VEHICLE_PARKING,
      NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN
    );
  }, []);

  const renderTabs = (tab, index) => {
    switch (tab.key) {
      case 'book_a_parking':
        return <VehicleParkingHomeTab />;
      case 'my_bookings':
        return <MyBookingsTab />;
    }
  };

  return (
    <div className="container">
      <SectionHeader title={'MiPARKINGS'} />
      <NavTabs
        activeKey={seatBookingTabdata === '' ? 'book_a_parking' : seatBookingTabdata}
        onSelect={(k) => dispatch(saveVehicleParkingTabData(k))}>
        {seatTabs &&
          seatTabs.map((tab, index) => {
            return (
              <Tab
                key={`${index}`}
                eventKey={tab.key}
                title={tab.title}
                className="main-content-container">
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
      </NavTabs>
    </div>
  );
};

export default VehicleParkingMainScreen;
