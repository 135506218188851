import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasShownBanner: false,
};

const appBanner = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setHasShownBanner(state, action) {
      state.hasShownBanner = action.payload;
    },
  },
});

export const { setHasShownBanner } = appBanner.actions;
export const selectHasShownBanner = ({ banner }) => {
  return banner?.hasShownBanner;
};

export default appBanner.reducer;
