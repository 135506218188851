import React, { useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { CEO_CHAT_ASK_QUESTION } from '../../services/ApiUrls';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { isEmptyText, showToast } from '../../utils/common';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../common/components/hashtagsAndMentionsInput/hashtagUtils';
import { EditorState } from 'draft-js';
import CustomCommentBox from '../../common/ui/comment_box/CustomCommentBox';
import { selectCeoChatTerms } from '../../state/MasterData';

const AskQuestionScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const params = location.state;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [mentions, setMentions] = useState([]);
  const ceoChatConfig = useSelector(selectCeoChatTerms);

  const progressLoadingState = useSelector(selectProgressLoadingState);

  const getCommentText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...mentions,
    ]);
    setEditorState(editorState2);
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CEO_CHAT_ASK_QUESTION, NAVIGATION_ROUTES.CEO_ASK_QUESTION);
  }, []);

  const sendCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.CEO_ASK_QUESTION, '', '');
      callCommentApi(tempDes);
    }
  };

  const callCommentApi = async (commentText) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const formData = new URLSearchParams();
    formData.append('question', commentText);
    try {
      const apiResponse = await apiRequest(CEO_CHAT_ASK_QUESTION, REQUEST_TYPE.POST, formData);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        const data = apiResponse.response;
        showToast(data.message);
        if (apiResponse.response.status === true) {
          setEditorState(EditorState.createEmpty());
          history.goBack();
        }
      }
    } catch (err) {
      handleError(err, formData, CEO_CHAT_ASK_QUESTION, NAVIGATION_ROUTES.CEO_CHAT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <BackButton />
      <div className="ceo-chat-ask-question">
        <div className="ceo-chat-ask-question-text ceo-chat-ask-question-text-container">
          {ceoChatConfig.ask_question_label}
        </div>
        <CustomCommentBox
          getCommentText={sendCommentFunction}
          onChangehandler={getCommentText}
          showLoader={progressLoadingState.isProgressLoading}
          setEditorState={setEditorState}
          editorState={editorState}
          enableUserMentions={true}
          enableHashtags={true}
          onMention={onMention}
        />
      </div>
    </div>
  );
};

export default AskQuestionScreen;
