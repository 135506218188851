import { createAction, createSlice } from '@reduxjs/toolkit';
import { HOME_SECTION_IDENTIFIERS } from '../common/constants/AppConstants';
import { sortBySequence } from '../utils/common';

const signOutAction = createAction('signout');

const saveDashboardDataReducer = (state, action) => {
  state.data = action.payload;
};

// REDUCER
const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    data: {},
  },
  extraReducers: {
    [signOutAction]: (state) => {
      return {
        ...state,
        data: {},
      };
    },
  },
  reducers: {
    saveDashboardData: saveDashboardDataReducer,
  },
});

// ACTIONS
const { saveDashboardData } = dashboardDataSlice.actions;

// SELECTOR
const selectDashboardData = ({ dashboardData }) => {
  return Object.keys(dashboardData.data).length ? sortBySequence(dashboardData.data.dashboard) : [];
};
const selectDashBoard = ({ dashboardData }) => dashboardData.data;
const selectFooterData = ({ dashboardData }) => {
  return Object.keys(dashboardData.data).length
    ? dashboardData.data.dashboard.find(
        (section) => section.identifier === HOME_SECTION_IDENTIFIERS.FOOTER
      ).items
    : [];
};
const selectJoineesInfoData = ({ dashboardData }) => dashboardData.data.joinees_info;
const selectDistributionData = ({ dashboardData }) => dashboardData.data.distribution;
const selectGreentingData = ({ dashboardData }) => dashboardData?.data?.greetings;
const dashboardDataSliceReducer = dashboardDataSlice.reducer;

export {
  dashboardDataSliceReducer,
  saveDashboardData,
  selectDashboardData,
  selectDashBoard,
  selectFooterData,
  selectJoineesInfoData,
  selectDistributionData,
  selectGreentingData,
};
