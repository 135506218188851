import React, { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { useDispatch } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { msApiRequest } from '../../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { FETCH_SEAT_BY_LEVEL } from '../../../services/ApiUrls';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SeatLayout = ({ location }) => {
  const {
    selectedFloor,
    checkInTime,
    checkOutTime,
    facility,
    level,
    parkingNumber,
    selectedDate,
    space,
    space_id,
    entryDateTime,
    exitDateTime,
  } = location.state;

  const param = location.state.selectedFloor;
  const layOutImageUri = param.layOutImage;
  const height = param.height;
  const width = param.width;
  const [seats, setSeats] = useState([]);
  const dispatch = useDispatch();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedSeat, setSelectedSeat] = useState();
  const history = useHistory();

  const fetchSeatsApi = useCallback(async () => {
    setSeats([]);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const params = {
        timezone: localTimezone,
        floor_id: param.value,
        org_id: 1,
        entry_datetime: entryDateTime ?? null,
        exit_datetime: exitDateTime ?? null,
      };
      const apiResponse = await msApiRequest(FETCH_SEAT_BY_LEVEL, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.statusCode === 200) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data?.length > 0) {
              setSeats(data);
            }
          }
        }
      }
      dispatch(resetLoaderState());
    } catch (e) {
      dispatch(resetLoaderState());
      console.error(e);
    }
  }, [dispatch, entryDateTime, exitDateTime, localTimezone, param.value]);

  useEffect(() => {
    fetchSeatsApi();
  }, [fetchSeatsApi]);

  const handleSeatPress = (seat, index) => {
    if (!seat.is_available) return;
    setSelectedSeat(selectedSeat?.index === index ? null : { ...seat, index });
  };

  const ContinueButton = () => (
    <CustomButton
      disabled={!selectedSeat?.name}
      buttonStyle="estore-add-to-cart-button mt-2"
      onClick={() => {
        history.push(NAVIGATION_ROUTES.SEAT_BOOKING_PREVIEW_SCREEN, {
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          facility: facility,
          level: level,
          parkingNumber: selectedSeat,
          selectedDate: selectedDate,
          entryDatetime: entryDateTime,
          exitDatetime: exitDateTime,
          space: selectedSeat,
          space_id: selectedSeat?._id,
        });
        logEvent(
          ANALYTICS_EVENT_TYPES.SEAT_BOOKING_HOME_BOOK_BUTTON_CLICKED,
          '',
          ANALYTICS_ITEM_NAMES.MIDESK
        );
      }}>
      CONTINUE
    </CustomButton>
  );

  return (
    <div className="container">
      <SectionHeader title={'MiDESK'} />
      <div className="header-view justify-content-between mb-2">
        <BackButton isFullRow={false} />

        <div className="broadcast-header-row justify-content-end">
          <div className="broadcast-icon-row mr-3" id="topContinueButton">
            <ContinueButton />
          </div>
        </div>
      </div>
      <div className="seat-layout-container seat-booking-colored-container">
        <img
          style={{ height: height, width: width }}
          src={layOutImageUri}
          alt="Seat Layout"
          //className="layout-image"
        />
        {seats.map((seat, index) => {
          const isSelected = selectedSeat?.index === index;
          const isUnavailable = !seat.is_available;

          return (
            <div
              key={index}
              onClick={() => handleSeatPress(seat, index)}
              style={{
                position: 'absolute',
                left: `${seat.x_coordinates}px`,
                top: `${seat.y_coordinates}px`,
                width: `${seat.width}px`,
                height: `${seat.height}px`,
                backgroundColor: isUnavailable ? '#909090' : isSelected ? '#0CAA41' : 'transparent',
                border: isUnavailable ? 'none' : '2px solid #0CAA41',
                borderRadius: '5px',
                padding: '10px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: isUnavailable ? 'not-allowed' : 'pointer',
              }}>
              {`${index + 1}`}
            </div>
          );
        })}
      </div>
      <div className="map-view-leg-con d-flex flex-row">
        <div className="d-flex flex-row align-items-center ml-3 mr-5">
          <div className="map-view-selected" />
          <div>Selected</div>
        </div>
        <div className="d-flex flex-row align-items-center mr-5">
          <div className="map-view-available" />
          <div>Available</div>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div className="map-view-unavailable" />
          <div>Unavailable</div>
        </div>
        {selectedSeat?.name && (
          <div className="selected-desk-container mr-3">
            <div className="selected-desk-icon" />
            <span>Selected desk: {selectedSeat?.name}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SeatLayout;
