// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  appId: process.env.REACT_APP_API_ID,
};

const app = initializeApp(firebaseConfig);
export const messaging = isBrowserSupported() && getMessaging(app);
export function isBrowserSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window;
}
const subscribe = () => {
  const subscribeToPushNotifications = async () => {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_KEY,
      });

      console.log('User subscribed to push notifications:', subscription);
    }
  };
  try {
    subscribeToPushNotifications();
  } catch (e) {
    console.log('error in subcribing', e);
  }
};

export const generateToken = async () => {
  if (isBrowserSupported) {
    const permission = await Notification.requestPermission();
    // console.log('permission', permission);
    let token;
    if (permission === 'granted') {
      token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      subscribe();
      // console.log('token', token);
    }
    return token;
  }
};
