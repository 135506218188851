import React, { useEffect, useCallback, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest, handleError } from '../../services/Service';
import {
  COMMENT_BY_ID,
  INCREASE_POLL_VIEW_COUNT_EXTERNAL_LINK,
  POLLS_BY_ID,
} from '../../services/ApiUrls';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition, showToast } from '../../utils/common';
import { saveProgressLoadingState } from '../../state/UIState';
import { useParams } from 'react-router-dom';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import Chart from 'react-apexcharts';
import colors from '../../common/themes/Colors';
import HtmlView from '../../common/ui/html_view/HTMLView';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import { selectAccessData } from '../../state/UserAccessData';

const PollsNotificationScreen = () => {
  const { pollId } = useParams();
  const [pollsResults, setPollsResults] = useState();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const history = useHistory();
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POLLS_RESULTS, NAVIGATION_ROUTES.POLLS_RESULTS);
  }, []);

  const callPollsApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(POLLS_BY_ID + pollId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.poll) {
            const data = apiResponse.response.data.poll;
            setPollsResults(data);
            setLiked(data.liked);
            setCommentCount(data.comment_count);
            setLikeCount(data.like_count);
            if (data?.poll_type === 'external') {
              try {
                const es = await apiRequest(
                  INCREASE_POLL_VIEW_COUNT_EXTERNAL_LINK + data?.poll_id,
                  REQUEST_TYPE.GET,
                  {}
                );
              } catch (err) {
                handleError(
                  err,
                  {},
                  POLLS_BY_ID + pollId,
                  NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN
                );
                //ignore if the view count request is not successful
                console.warn(err);
              }
            }
            if (data?.results) {
              let categories = [];
              let barData = [];
              data.results.options.map((item, index) => {
                categories.push(index + 1);
                barData.push(Math.round(item.percentage * 10) / 10);
              });
              setOptions({
                xaxis: {
                  categories: categories,
                },
                fill: {
                  colors: [colors.primary],
                },
              });
              setSeries([
                {
                  name: 's-1',
                  data: barData,
                },
              ]);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, POLLS_BY_ID + pollId, NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, pollId]);

  useEffect(() => {
    callPollsApi();
  }, []);

  const postLikeFunction = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    logEvent(ANALYTICS_EVENT_TYPES.POLL_LIKE, pollsResults.poll_id, ANALYTICS_ITEM_NAMES.LIKE);
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.POLL);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + pollsResults.poll_id + '/like',
        REQUEST_TYPE.POST,
        formData
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + pollsResults.poll_id + '/like',
        NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, pollsResults?.poll_id]);

  const submitVote = async (pollId, optionId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const formData = new URLSearchParams();
    formData.append('option_id', optionId);
    try {
      const apiResponse = await apiRequest(
        POLLS_BY_ID + pollId + '/vote',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          callPollsApi();
        }
      }
    } catch (err) {
      handleError(
        err,
        formData,
        POLLS_BY_ID + pollId + '/vote',
        NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressParticipate = () => {
    window.open(pollsResults.external_url, '_blank');
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.POLL} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />

        <div className="broadcast-header-row justify-content-end">
          {showLikeButton && (
            <div className="broadcast-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
                onClick={postLikeFunction}
              />
              <div
                className="broadcast-icon-text"
                onClick={() => {
                  if (likeCount > 0) {
                    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: pollsResults?.poll_id,
                      parentType: FEATURE_TYPE.POLL,
                    });
                  }
                }}>
                {likeCount}
              </div>
            </div>
          )}

          {showCommentButton && (
            <div className="broadcast-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                )}
                className="header-icon"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.COMMENTS, {
                    referId: pollsResults.poll_id,
                    likeType: FEATURE_TYPE.POLL,
                  });
                }}
              />
              <div
                className="broadcast-icon-text"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.COMMENTS, {
                    referId: pollsResults.poll_id,
                    likeType: FEATURE_TYPE.POLL,
                  });
                }}>
                {commentCount}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-3 common-container-md mx-auto">
        {pollsResults && (
          <div>
            <img
              src={getUrlWithSpecificRendition(
                pollsResults.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                pollsResults.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="polls-title-container p-3">
              <div className="polls-cross-axis-view mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.NEWS
                  )}
                  className="polls-calender-style"
                />
                <div className="ml-1 polls-date-text">
                  {pollsResults.active
                    ? `Till ${formatDateDDMonthYYYY(pollsResults.end_datetime)}`
                    : `Poll ended on ${formatDateDDMonthYYYY(pollsResults.end_datetime)}`}
                </div>
              </div>
              <div className="polls-details-title-text mt-1">{pollsResults.question}</div>
              {pollsResults.active && pollsResults.voted && (
                <div className="mt-3 polls-details-participated-text text-center">{`You've already participated in this poll`}</div>
              )}
              {pollsResults.active && pollsResults?.poll_type === 'external' && showParticipate && (
                <CustomButton
                  buttonStyle="polls-button-style ml-0 mt-2"
                  onClick={onPressParticipate}>
                  Participate
                </CustomButton>
              )}
              {pollsResults.active && !pollsResults.voted && showParticipate && (
                <div className="polls-category-container">
                  {pollsResults.results.options &&
                    pollsResults.results.options.length > 0 &&
                    pollsResults.results.options.map((itemData, index) => {
                      return (
                        <div
                          key={`${index}`}
                          className="polls-categories mt-2"
                          onClick={() => submitVote(pollsResults.poll_id, itemData.option_id)}>
                          {`${index + 1} - ${itemData.title}`}
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
            {pollsResults && !pollsResults.active && pollsResults?.poll_type !== 'external' && (
              <div>
                <div className="polls-category-container">
                  {pollsResults.results.options &&
                    pollsResults.results.options.length > 0 &&
                    pollsResults.results.options.map((item, index) => {
                      return (
                        <div key={`${index}`} className="polls-inactive-categories mt-2">
                          {`${index + 1} - ${item.title}`}
                        </div>
                      );
                    })}
                </div>
                <Chart
                  className="mt-3"
                  options={options}
                  series={series}
                  type="bar"
                  width={500}
                  height={320}
                />
              </div>
            )}
            {pollsResults?.description && (
              <div className="p-2">
                <HtmlView html={pollsResults?.description} htmlStyle="polls-description-text" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PollsNotificationScreen;
