import { createSlice } from '@reduxjs/toolkit';

const saveBookingDataReducer = (state, action) => {
  state.selectedType = action.payload;
};

const saveMeetingArrayReducer = (state, action) => {
  state.meetingArray = action.payload;
};

// REDUCER
const bookingDataSlice = createSlice({
  name: 'meetingData',
  initialState: {
    selectedType: 'upcoming',
    meetingArray: {},
  },
  reducers: {
    saveBookingData: saveBookingDataReducer,
    saveMeetingArray: saveMeetingArrayReducer,
  },
});

// ACTIONS
const { saveBookingData, saveMeetingArray } = bookingDataSlice.actions;

// SELECTOR
const selectBookingData = ({ meetingData }) => meetingData.selectedType;
const selectMeetingArray = ({ meetingData }) => meetingData.meetingArray;

const meetingBookingDataSliceReducer = bookingDataSlice.reducer;

export {
  meetingBookingDataSliceReducer,
  saveBookingData,
  selectBookingData,
  saveMeetingArray,
  selectMeetingArray,
};
