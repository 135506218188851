import React, { useEffect, useState } from 'react';
import { ANALYTICS_SCREEN_NAMES, NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import AllocatePointPeerListItem from './AllocatePointPeerListItem';

const AllocatePointPeer = ({ selectedEmployee, onPressSelectPoints }) => {
  const [empList, setEmpList] = useState(selectedEmployee);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ALLOCATE_POINT_PEER_SCREEN,
      NAVIGATION_ROUTES.ALLOCATE_POINT_PEER_SCREEN
    );
  }, []);

  const onPressPlus = (index) => {
    let arr = selectedEmployee;
    let obj = arr[index];
    if (obj.points < 1000) {
      obj.points = obj.points + 100;
      arr[index] = obj;
      setEmpList([...arr]);
    }
  };

  const onPressMinus = (index) => {
    let arr = selectedEmployee;
    let obj = arr[index];
    if (obj.points > 100) {
      obj.points = obj.points - 100;
      arr[index] = obj;
      setEmpList([...arr]);
    }
  };

  const renderEmployee = (item, index) => {
    return (
      <AllocatePointPeerListItem
        key={`${index}`}
        name={item.name}
        onPressPlus={() => onPressPlus(index)}
        onPressMinus={() => onPressMinus(index)}
        points={item.points}
      />
    );
  };

  return (
    <div className="mt-3 mb-3">
      <div className="cheers-date-view-style justify-content-between mr-2">
        <div className="cheers-allocate-name">Name</div>
        <div className="text-center pr-4 cheers-allocate-name">Points</div>
      </div>
      <div className="mt-3 mb-3">
        {empList.length > 0 && empList.map((data, index) => renderEmployee(data, index))}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="text-center cheers-point-awarded">
          Maximum 1000 points can be awarded to each person.
        </div>
        <CustomButton
          buttonStyle="cheers-button-style"
          onClick={() => {
            onPressSelectPoints(empList);
          }}>
          Select points
        </CustomButton>
      </div>
    </div>
  );
};

export default AllocatePointPeer;
