import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FieldArray } from 'formik';
import { useLocation, useHistory } from 'react-router';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
  selectBusCoachList,
  selectFlightClassList,
  selectTrainCoachList,
  selectTravellerData,
  selectTravelModesList,
} from '../../../state/TravelRequestMasterData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  TRAVEL_REQUEST_DESTINATION_PREF,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { selectMultilineTextSize } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  getDateInYYYYMMDD,
  getFutureYearDateInYYYYMMDD,
  getTomorrowDateInYYYYMMDD,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import AddEditTravellerDialog from './AddEditTravellerDialog';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import Divider from '../../../common/ui/divider/Divider';
import CreateChooseTravellerDialog from './CreateChooseTravellerDialog';
import { saveTravellerList, selectTravellerList } from '../../../state/TabData';

const TravelSubTab = (props) => {
  const { formikProps, ticketsFieldArrayProps, ticketLength, ticketIndex, isPreApprovedRequest } =
    props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const travelModesList = useSelector(selectTravelModesList);
  const flightClassList = useSelector(selectFlightClassList);
  const trainCoachList = useSelector(selectTrainCoachList);
  const busCoachList = useSelector(selectBusCoachList);
  const travellerData = useSelector(selectTravellerData);
  const tomorrowDate = getTomorrowDateInYYYYMMDD();
  const maxAllowedDate = getFutureYearDateInYYYYMMDD(5);
  const [isAddEditTravellerModalVisible, setIsAddEditTravellerModalVisible] = useState(false);
  const [isCreateTravellerModalVisible, setIsCreateTravellerModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [travellerDetails, setTravellerDetails] = useState({});
  const travellersFieldArrayPropRef = useRef();
  const travellerState = useSelector(selectTravellerList);
  const [travellerList, setTravellerList] = useState(travellerState || undefined);

  useEffect(() => {
    if (travellerList?.length > 0 && travellerList !== undefined) {
      formikProps.setFieldValue(`tickets[${ticketIndex}].travellers`, travellerList);
    }
  }, [ticketIndex, travellerList]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.isEditTraveller &&
      location.state.travellerDetails &&
      Object.keys(location.state.travellerDetails) &&
      Object.keys(location.state.travellerDetails).length > 0
    ) {
      if (
        formikProps.values.tickets[ticketIndex].travellers &&
        formikProps.values.tickets[ticketIndex].travellers.length > 0 &&
        location.state.travellerDetails.traveller_id
      ) {
        formikProps.values.tickets[ticketIndex].travellers.map((traveller, travellerIndex) => {
          if (
            traveller &&
            traveller.traveller_id &&
            traveller.traveller_id.toString() ===
              location.state.travellerDetails.traveller_id.toString()
          ) {
            travellersFieldArrayPropRef.current.replace(
              travellerIndex,
              location.state.travellerDetails
            );
          }
        });
        if (
          travellerData &&
          travellerData.traveller_id &&
          travellerData.traveller_id.toString() ===
            location.state.travellerDetails.traveller_id.toString()
        ) {
          dispatch({ type: ACTION_TYPES.GET_TRAVEL_REQUEST_MASTER_DATA, payload: {} });
        }
        history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
          travellerDetails: undefined,
          isEditTraveller: undefined,
        });
      }
    }
  }, [dispatch, formikProps.values.tickets, history, location, ticketIndex, travellerData]);

  const onAddEditTraveller = (travellerDetails) => {
    travellersFieldArrayPropRef.current.push(travellerDetails);
  };

  const onCloseCreateChooseDialog = useCallback(() => {
    setIsCreateTravellerModalVisible(false);
    dispatch(saveTravellerList([]));
  }, [dispatch]);

  const onPressCreateTraveller = () => {
    setIsEdit(false);
    setIsCreateTravellerModalVisible(false);
    setIsAddEditTravellerModalVisible(true);
  };

  const commentPopover = (
    <Popover>
      <Popover.Content>
        <div className="common-cursor-pointer">
          <p className="mb-0 common-ws-pre-line">
            E.g., Preferred location/Nearest landmarks/Accessible areas/Other requirements
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  const pushNewTravelForm = () => {
    ticketsFieldArrayProps.push({
      from_city: '',
      to_city: '',
      destination_pref: 'D',
      destination_pref_date: '',
      destination_pref_time: '',
      travel_mode_id: '',
      flight_class: {},
      train_coach: {},
      bus_coach: {},
      comments: '',
      travellers:
        formikProps.values.on_behalf === 'E' &&
        formikProps.values.employee &&
        Object.keys(formikProps.values.employee) &&
        Object.keys(formikProps.values.employee).length > 0 &&
        formikProps.values.employee.employee_code !== employeeData.employee_code
          ? [formikProps.values.employee]
          : [travellerData],
    });
  };

  const onPressAddTravelHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_ADD_ANOTHER_TRAVEL_CLICK,
      formikProps.values.request_id.toString(),
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
    pushNewTravelForm();
  };

  const onPressEditHandler = (traveller) => {
    setIsEdit(true);
    setIsAddEditTravellerModalVisible(true);
    setTravellerDetails(traveller);
  };

  return (
    <div className="travel-request-selected-type-form px-3 pb-3">
      <div>
        {ticketIndex > 0 && <Divider style="mb-1" />}
        <div className="d-flex">
          <div className="flex-fill mr-2 mt-2">
            <CustomTextInput
              name={`tickets.${ticketIndex}.from_city`}
              title="From"
              value={formikProps.values.tickets[ticketIndex].from_city}
              maxLength={maxMultiLineCharLimit}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              placeholder="Enter City"
            />
          </div>
          <div className="flex-fill ml-2 mt-2">
            <CustomTextInput
              name={`tickets.${ticketIndex}.to_city`}
              title="To"
              value={formikProps.values.tickets[ticketIndex].to_city}
              maxLength={maxMultiLineCharLimit}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              placeholder="Enter City"
            />
          </div>
        </div>
        <Form.Label className="travel-request-plan-travel-label">Date & Time</Form.Label>
        <div className="d-flex justify-content-center">
          <Select
            className="travel-request-type mr-2"
            placeholder="Select an option"
            isSearchable={false}
            options={TRAVEL_REQUEST_DESTINATION_PREF}
            value={TRAVEL_REQUEST_DESTINATION_PREF.find(
              (option) => option.value === formikProps.values.tickets[ticketIndex].destination_pref
            )}
            onChange={(selectedOption) => {
              formikProps.setFieldValue(
                `tickets.${ticketIndex}.destination_pref`,
                selectedOption.value
              );
            }}
          />
          <div className="flex-fill mr-2 ml-2">
            <CustomTextInput
              name={`tickets.${ticketIndex}.destination_pref_date`}
              isTitle={false}
              placeholder="Date"
              type="date"
              min={
                isPreApprovedRequest && formikProps.values.start_date
                  ? getDateInYYYYMMDD(formikProps.values.start_date)
                  : tomorrowDate
              }
              max={
                isPreApprovedRequest && formikProps.values.end_date
                  ? getDateInYYYYMMDD(formikProps.values.end_date)
                  : maxAllowedDate
              }
              value={formikProps.values.tickets[ticketIndex].destination_pref_date}
              onChange={formikProps.handleChange}
            />
          </div>
          <div className="flex-fill ml-2">
            <CustomTextInput
              isTitle={false}
              placeholder="Time"
              name={`tickets.${ticketIndex}.destination_pref_time`}
              type="time"
              value={formikProps.values.tickets[ticketIndex].destination_pref_time}
              onChange={formikProps.handleChange}
            />
          </div>
        </div>
        <Form.Label className="travel-request-plan-travel-label">Travel Mode</Form.Label>
        <Select
          className="travel-request-dropdown-style"
          placeholder="Select an option"
          isSearchable={false}
          getOptionLabel={(option) => option.title}
          getOptionValue={(option) => option.pref_id}
          options={travelModesList}
          value={travelModesList.find(
            (option) => option.pref_id === formikProps.values.tickets[ticketIndex].travel_mode_id
          )}
          onChange={(selectedOption) => {
            formikProps.setFieldValue(
              `tickets[${ticketIndex}].travel_mode_id`,
              selectedOption.pref_id
            );

            if (selectedOption.pref_id === 1) {
              if (formikProps.values.travel_range === 'D') {
                formikProps.setFieldValue(
                  `tickets[${ticketIndex}].flight_class`,
                  flightClassList.find((item) => item.pref_id === 5)
                );
              } else if (formikProps.values.travel_range === 'I') {
                formikProps.setFieldValue(
                  `tickets[${ticketIndex}].flight_class`,
                  flightClassList.find((item) => item.pref_id === 6)
                );
              }
              formikProps.setFieldValue(`tickets.${ticketIndex}.train_coach`, {});
              formikProps.setFieldValue(`tickets.${ticketIndex}.bus_coach`, {});
            } else if (selectedOption.pref_id === 2) {
              formikProps.setFieldValue(`tickets.${ticketIndex}.train_coach`, {
                pref_id: trainCoachList[0].pref_id,
                title: trainCoachList[0].title,
              });
              formikProps.setFieldValue(`tickets.${ticketIndex}.flight_class`, {});
              formikProps.setFieldValue(`tickets.${ticketIndex}.bus_coach`, {});
            } else if (selectedOption.pref_id === 3) {
              formikProps.setFieldValue(`tickets.${ticketIndex}.bus_coach`, {
                pref_id: busCoachList[0].pref_id,
                title: busCoachList[0].title,
              });
              formikProps.setFieldValue(`tickets.${ticketIndex}.flight_class`, {});
              formikProps.setFieldValue(`tickets.${ticketIndex}.train_coach`, {});
            }
          }}
        />
        {formikProps.values.tickets[ticketIndex].travel_mode_id === 1 && (
          <div>
            <Form.Label className="mt-3 travel-request-plan-travel-label">Class</Form.Label>
            <Select
              className="travel-request-dropdown-style"
              placeholder="Select an option"
              isSearchable={false}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.pref_id}
              options={flightClassList}
              value={formikProps.values.tickets[ticketIndex].flight_class}
              onChange={(selectedOption) => {
                formikProps.setFieldValue(`tickets[${ticketIndex}].flight_class`, selectedOption);
              }}
            />
          </div>
        )}
        {formikProps.values.tickets[ticketIndex].travel_mode_id === 2 && (
          <div>
            <Form.Label className="mt-3 travel-request-plan-travel-label">Coach</Form.Label>
            <Select
              className="travel-request-dropdown-style"
              placeholder="Select an option"
              isSearchable={false}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.pref_id}
              options={trainCoachList}
              value={formikProps.values.tickets[ticketIndex].train_coach}
              onChange={(selectedOption) => {
                formikProps.setFieldValue(`tickets[${ticketIndex}].train_coach`, selectedOption);
              }}
            />
          </div>
        )}
        {formikProps.values.tickets[ticketIndex].travel_mode_id === 3 && (
          <div>
            <Form.Label className="mt-3 travel-request-plan-travel-label">Coach</Form.Label>
            <Select
              className="travel-request-dropdown-style"
              placeholder="Select an option"
              isSearchable={false}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.pref_id}
              options={busCoachList}
              value={formikProps.values.tickets[ticketIndex].bus_coach}
              onChange={(selectedOption) => {
                formikProps.setFieldValue(`tickets[${ticketIndex}].bus_coach`, selectedOption);
              }}
            />
          </div>
        )}

        <FieldArray name={`tickets[${ticketIndex}].travellers`}>
          {(travellersFieldArrayProps) => {
            travellersFieldArrayPropRef.current = travellersFieldArrayProps;
            return (
              <div>
                <Form.Label className="mt-3 travel-request-plan-travel-label">
                  Travellers
                </Form.Label>
                {formikProps.values.tickets[ticketIndex].travellers &&
                  formikProps.values.tickets[ticketIndex].travellers.length > 0 &&
                  formikProps.values.tickets[ticketIndex].travellers.map(
                    (traveller, travellerIndex) => (
                      <div
                        key={travellerIndex.toString()}
                        className="d-flex justify-content-between travel-request-travellers-view align-items-center mb-3 px-3">
                        <p className="mb-0">{traveller.first_name + ' ' + traveller.last_name}</p>
                        <div className="d-flex align-items-center">
                          <p
                            onClick={() => onPressEditHandler(traveller)}
                            className="mb-0 common-cursor-pointer travel-request-add-traveller">
                            EDIT
                          </p>
                          <div
                            className="common-cursor-pointer ml-3"
                            onClick={() => {
                              travellersFieldArrayProps.remove(travellerIndex);
                              dispatch(saveTravellerList([]));
                            }}>
                            <img
                              src={getFeatureComponentUrl(
                                accessibilityData,
                                ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_CLOSE,
                                ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                              )}
                              className="travel-request-close-icon"
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {isAddEditTravellerModalVisible && (
                  <AddEditTravellerDialog
                    isEdit={isEdit}
                    travellerDetails={travellerDetails}
                    isAddEditTravellerModalVisible={isAddEditTravellerModalVisible}
                    setIsAddEditTravellerModalVisible={setIsAddEditTravellerModalVisible}
                    onAddEditTraveller={onAddEditTraveller}
                    travellerList={formikProps.values.tickets[ticketIndex].travellers}
                    formikPropValues={formikProps.values}
                  />
                )}
                {isCreateTravellerModalVisible && (
                  <CreateChooseTravellerDialog
                    isCreateTravellerModalVisible={isCreateTravellerModalVisible}
                    onCloseCreateChooseDialog={onCloseCreateChooseDialog}
                    onPressCreateTraveller={onPressCreateTraveller}
                    travellersFieldArrayProps={travellersFieldArrayProps}
                    ticketIndex={ticketIndex}
                    setTravellers={setTravellerList}
                  />
                )}
                <div
                  className="d-flex align-items-center justify-content-center travel-request-add-traveller-view py-2 common-cursor-pointer"
                  onClick={() => {
                    logEvent(
                      ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_ADD_TRAVELLER_CLICK,
                      formikProps.values.request_id.toString(),
                      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                    );
                    setIsCreateTravellerModalVisible(true);
                  }}>
                  <div className="travel-request-add-traveller">+ ADD TRAVELLER</div>
                </div>
              </div>
            );
          }}
        </FieldArray>

        <div className="mt-3">
          <div>
            <label>Comments</label>
            <OverlayTrigger rootClose trigger="click" placement="right" overlay={commentPopover}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="common-user-profile-info-icon common-cursor-pointer ml-2"
              />
            </OverlayTrigger>
          </div>
          <CustomTextInput
            name={`tickets.${ticketIndex}.comments`}
            isTitle={false}
            title="Comments"
            value={formikProps.values.tickets[ticketIndex].comments}
            as="textarea"
            maxLength={maxMultiLineCharLimit}
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            style="my-profile-edit-editable-text-area"
          />
        </div>
        <div
          className="d-flex justify-content-end mb-3 common-cursor-pointer"
          onClick={() => {
            if (ticketLength === 1) {
              ticketsFieldArrayProps.remove(ticketIndex);
              pushNewTravelForm();
            } else {
              ticketsFieldArrayProps.remove(ticketIndex);
            }
          }}>
          <div className="d-flex align-items-center">
            <div className="travel-request-delete-travel-accommodation mr-2">DELETE TRAVEL</div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_TRASH_RED,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="travel-request-delete-icon"
            />
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-center travel-request-add-traveller-view py-2">
        <div
          className="travel-request-add-traveller common-cursor-pointer"
          onClick={onPressAddTravelHandler}>
          + ADD ANOTHER TRAVEL
        </div>
      </div>
    </div>
  );
};

export default React.memo(TravelSubTab);
