import React from 'react';
import StatusIndicator from '../../../common/components/StatusIndicator';
import { formatDateDDthMonth } from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import Divider from '../../../common/ui/divider/Divider';

const UpcomingItem = ({ item }) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const entryDatetime = item?.entry_datetime ? dayjs(item?.entry_datetime) : null;
  const exitDatetime = item?.exit_datetime ? dayjs(item?.exit_datetime) : null;
  const checkInTime = entryDatetime ? entryDatetime.format('hh:mm A') : 'N/A';
  const checkOutTime = exitDatetime ? exitDatetime.format('hh:mm A') : 'N/A';
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('dddd, DD MMMM YYYY');
  };
  const formattedDate = entryDatetime ? formatDate(entryDatetime) : 'N/A';
  return (
    <div
      onClick={() => {
        history.push(NAVIGATION_ROUTES.MEETING_DETAIL, {
          fromRoute: 'BookingScreen',
          checkInTime: checkInTime,
          checkOutTime: checkOutTime,
          selectedDate: formattedDate,
          entryDateTime: entryDatetime,
          exitDateTime: exitDatetime,
          booking_id: item._id,
          meetingName: item?.title,
          selectedEmployee: item?.attendees,
          meetingAgenda: item?.agenda,
          meetingArray: item?.roomData,
          externalParticipantList: item.external_email_ids,
        });
      }}
      className="common-cursor-pointer">
      <div className="d-flex flex-row justify-content-between">
        <div>
          <div className="base-14px-font">{item?.facility_title}</div>
          <div className="booking-home-subtitle">{item.roomData.title}</div>
          <div className="d-flex flex-row justify-content-between">
            <div className="upcoming-booking-timing-text">{`${formatDateDDthMonth(
              entryDatetime
            )} - ${checkInTime}`}</div>
          </div>
        </div>
        <div>
          <div>{item?.status && <StatusIndicator status={item?.status} />}</div>
          <div className="d-flex flex-row justify-content-end w-100 mt-2 pt-1">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="seat-booking-home-arrow-style"
            />
          </div>
        </div>
      </div>
      <Divider style={'my-2'} />
    </div>
  );
};

export default UpcomingItem;
