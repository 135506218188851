import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoIosSearch } from 'react-icons/io';
import { VscChromeClose } from 'react-icons/vsc';
import $ from 'jquery';
import './NewHeader.css';

import { selectCompanyLogo, selectConfigurations } from '../../../state/MasterData';
import { getUrlWithSpecificRendition, sortBySequence } from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../constants/AppConstants';
import { selectMenuData } from '../../../state/MenuData';
import {
  getFeatureComponent,
  getFeatureComponentUrlLdpi,
  getHeaderComponentUrl,
} from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  saveNotificationListItem,
  selectNotificationCount,
  selectNotificationList,
} from '../../../state/NotificationData';
import colors from '../../themes/Colors';
import useWindowDimensions from '../../../custom_hooks/useWindowDimensions';
import LogoutBtn from './components/LogoutBtn';
import Feature from './components/Feature';
import FavoriteModal from './components/FavoriteModal';
import { apiRequest, handleError } from '../../../services/Service';
import {
  GET_EMPLOYEE_BY_ID,
  GET_NOTIFICATION_COUNT,
  GLOBAL_SEARCH_FILTER,
  INCREASE_VIEW_COUNT_EXTERNAL_LINK,
} from '../../../services/ApiUrls';
import UserProfilePhoto from '../user_profile_photo/UserProfilePhoto';
import { selectEmployeeData } from '../../../state/EmployeeData';
import debounce from 'lodash.debounce';
import Divider from '../divider/Divider';
import HtmlView from '../html_view/HTMLView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState } from '../../../state/UIState';
import NotificationItem from '../../../screen/notiifcation/components/NotificationItem';
import { notificationListingNavigation } from '../../../navigation/NavigationHandler';
import NotificationListItem from './components/NotificationListingItem';

const Header = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const notificationData = useSelector(selectNotificationList);
  // const [notificationData, setNotificationData] = useState([]);

  const companyLogo = useSelector(selectCompanyLogo);
  const configurations = useSelector(selectConfigurations);
  const menuData = useSelector(selectMenuData);
  const notificationCount = useSelector(selectNotificationCount);
  const [isFavoriteModalVisible, setIsFavoriteModalVisible] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [profile, setProfile] = useState();
  const [searchText, setSearchText] = useState();
  const history = useHistory();
  let navbarCollapseRef = useRef();
  let navbarToggleRef = useRef();
  let globalSearchRef = useRef();
  const [feedTypes, setFeedTypes] = useState([]);
  const [allFeeds, setAllfeeds] = useState([]);
  const employeeData = useSelector(selectEmployeeData);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef(null);
  const [transformStyle, setTransformStyle] = useState();
  const dropdownRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownKey, setDropdownKey] = useState(0);

  const handleToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleViewAllClick = () => {
    history.push(NAVIGATION_ROUTES.NOTIFICATION_LISTING);
    setDropdownOpen(false);
    setDropdownKey((prevKey) => prevKey + 1);
  };

  const openSearchPopupHandler = () => {
    setShowSearchPopup(true);
  };

  const closeSearchPopupHandler = () => {
    resetSearch();
    setShowSearchPopup(false);
  };
  const callEmployeeDetailApi = useCallback(async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      if (employeeData?.employee_id) {
        const apiResponse = await apiRequest(
          GET_EMPLOYEE_BY_ID + employeeData?.employee_id,
          REQUEST_TYPE.GET,
          {}
        );

        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            if (Object.keys(apiResponse.response.data.employee).length) {
              setProfile(apiResponse.response.data.employee);
            }
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_EMPLOYEE_BY_ID + employeeData.employee_id,
        NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    }
  }, [dispatch, employeeData]);

  const handleDocumentClick = useCallback((e) => {
    const container = navbarCollapseRef.current;
    if (
      navbarCollapseRef?.current?.classList?.contains('show') &&
      e.target !== container &&
      !container.contains(e.target)
    ) {
      navbarToggleRef.current.click();
    }
  }, []);
  useEffect(() => {
    const checkOverflow = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const isOverflowingRight = rect.right > window.innerWidth;

        if (isOverflowingRight) {
          const overflowAmount = rect.right - window.innerWidth;
          setTransformStyle({ right: 0 });
        } else {
          setTransformStyle({ left: 'auto' });
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [isVisible, title]);
  useEffect(() => {
    callEmployeeDetailApi();
  }, [employeeData]);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, true);
  }, [handleDocumentClick]);

  const renderFeature = (section, feature, featureIndex) => {
    return (
      <Feature
        key={featureIndex.toString()}
        eventKey={feature.id}
        section={section}
        feature={feature}
        featureIndex={featureIndex}
        setIsFavoriteModalVisible={setIsFavoriteModalVisible}
      />
    );
  };

  const renderSection = (section, sectionIndex) => {
    const filteredFeatures =
      section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION
        ? section.features
        : sortBySequence(section.features);

    const isSectionActive = filteredFeatures.find((feature) => {
      const featureRoute =
        '/' +
        getFeatureComponent(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
          feature.identifier
        );
      if (featureRoute === location.pathname) {
        return true;
      }
    });

    if (
      section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION &&
      filteredFeatures.length === 0
    ) {
      return (
        <Nav.Link
          key={sectionIndex.toString()}
          eventKey="0"
          onClick={() => {
            setIsFavoriteModalVisible(true);
          }}>
          <div className="header-highlighted-section-item-fev">ADD FAVOURITES +</div>
        </Nav.Link>
      );
    }

    const menuColumnArray = [];
    for (let i = 0; i < filteredFeatures.length; i += 9) {
      const menuOption = filteredFeatures.slice(i, i + 9);
      menuColumnArray.push(menuOption);
    }
    const handleMouseEnter = () => {
      setTitle(section.title); // Set title when section is hovered
      setIsVisible(true); // Show the dropdown
    };

    const handleMouseLeave = () => {
      setIsVisible(false); // Hide the dropdown
    };
    return (
      <div className="header-section-item-wrapper">
        <NavDropdown
          key={sectionIndex.toString()}
          renderMenuOnMount={true}
          active={isSectionActive}
          className="header-section-item"
          title={section.title}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          {window.innerWidth >= 991 ? (
            <div ref={dropdownRef} className="dropdown-container" style={{ ...transformStyle }}>
              <div className="drawer-inner-middle">
                <div className="drawer-inner">
                  {/* {setTitle(section.title)} */}
                  {menuColumnArray.map((element, elementIndex) => {
                    return (
                      <div key={elementIndex}>
                        {element.map((feature, featureIndex) => {
                          return renderFeature(section, feature, featureIndex);
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="dropdown-container">
              {filteredFeatures.map((feature, featureIndex) => {
                return renderFeature(section, feature, featureIndex);
              })}
            </div>
          )}
        </NavDropdown>
      </div>
    );
  };

  const renderMenu = () => {
    if (menuData.data) {
      return sortBySequence(menuData.data.section_list).map((section, sectionIndex) => {
        if (sectionIndex === 0 || section.features.length > 0) {
          return renderSection(section, sectionIndex);
        } else {
          null;
        }
      });
    }
  };

  if (window.innerWidth >= 991) {
    $('body').on('mouseenter mouseleave', '.dropdown', function (e) {
      var dropdown = $(e.target).closest('.dropdown');
      var menu = $('.dropdown-menu', dropdown);
      dropdown[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
      menu[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
    });
  }

  const callSubmitFunction = (event) => {
    event.preventDefault();
    if (searchText) {
      setShowSearchPopup(false);
      globalSearchRef.current.reset();
      history.push(NAVIGATION_ROUTES.GLOBAL_SEARCH, {
        searchText: searchText,
      });
    }
  };

  const resetSearch = () => {
    setShowSearchPopup(false);
    globalSearchRef.current.reset();
    setAllfeeds(null);
    setFeedTypes(null);
  };

  const searchFeed = useCallback(
    async (query_field) => {
      if (query_field.length > 2) {
        setAllfeeds(null);
        setFeedTypes(null);
        let searchQuery;
        if (query_field) {
          searchQuery = query_field;
        }
        let payload = {
          query: searchQuery,
          news: 'YES',
          articles: 'YES',
          policies: 'YES',
          cmdchat: 'YES',
          wall: 'YES',
          customers: 'YES',
          quickSearch: 'YES',
          directory: 'YES',
          broadcasts: 'YES',
        };

        const apiResponse = await apiRequest(GLOBAL_SEARCH_FILTER, REQUEST_TYPE.GET, payload);
        let result = {};
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            result = apiResponse.response;
            if (result.feeds.length > 0) {
              setAllfeeds(result.feeds);

              if (result.feeds.length > 0) {
                let feedTypesVal = result.feeds.map((feed, index) => {
                  return feed.type;
                });
                feedTypesVal = Array.from(new Set(feedTypesVal));
                let temp = [];
                feedTypesVal.forEach((type, index) => {
                  try {
                    if (type.indexOf('customer') > -1) {
                      temp[index] = { category: 'customer', name: 'Our Customer' };
                    }
                    if (type.indexOf('chat') > -1) {
                      temp[index] = { category: 'chat', name: 'CEO Chat' };
                    }
                    if (type.indexOf('news') > -1) {
                      temp[index] = { category: 'news', name: 'Microland in The News' };
                    }
                    if (type.indexOf('policy') > -1) {
                      temp[index] = { category: 'policy', name: 'Policies' };
                    }
                    if (type.indexOf('article') > -1) {
                      temp[index] = { category: 'article', name: 'Dear Microlander' };
                    }
                    if (type.indexOf('broadcasts') > -1) {
                      temp[index] = { category: 'broadcasts', name: 'Broadcasts' };
                    }
                    if (type.indexOf('wall') > -1) {
                      temp[index] = { category: 'wall', name: 'The Wall' };
                    }
                    if (type.indexOf('directory') > -1) {
                      temp[index] = { category: 'directory', name: 'Directory' };
                    }
                  } catch (error) {
                    //error
                  }
                });
                const uniqueFeedType = [
                  ...new Map(temp.map((item) => [item['category'], item])).values(),
                ];
                feedTypesVal = uniqueFeedType;
                setFeedTypes((prev) => [...feedTypesVal]);
              }
            } else {
              let message = result.status ? 'No results found.' : result.message;
              setAllfeeds(null);
              setFeedTypes(null);
            }
          }
        }
      } else {
        setAllfeeds(null);
        setFeedTypes(null);
      }
    },
    [dispatch]
  );

  const onChangeText = (event) => {
    setSearchText(event.target.value);
    debounceFn(event.target.value);
  };

  const debounceFn = useMemo(() => debounce(searchFeed, 500), [searchFeed]);

  const filterDataFunc = (feedType) => {
    const data = allFeeds;

    if (data && data.length > 0) {
      const filteredArr = data.filter((feed) => {
        return feed.type.toLowerCase().indexOf(feedType.toLowerCase()) > -1;
      });
      return filteredArr;
    } else {
      return null;
    }
  };

  const onSeeAllPress = (tab) => {
    resetSearch();
    history.push(NAVIGATION_ROUTES.GLOBAL_SEARCH, {
      searchText: searchText,
      activeTab: tab.category,
    });
  };

  const getSeeAllButton = (tab) => {
    return (
      <div
        className="common_quick_suggestion_see_all common-cursor-pointer"
        onClick={() => onSeeAllPress(tab)}>
        See All
      </div>
    );
  };

  const onPressNewsItem = async (item) => {
    resetSearch();
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.NEWS}/${item.article_id}`);
    }
  };

  const onPressBroadcastItem = async (item) => {
    resetSearch();
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${item.article_id}`);
    }
  };

  const onPressArticleItem = async (item) => {
    resetSearch();
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`);
    }
  };

  const onPressPolicyItem = (item) => {
    resetSearch();
    history.push(`${NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN}/${item.policy_id}`);
  };

  const onPressWallItem = (item) => {
    resetSearch();
    history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${item.post_id}`);
  };

  const onAccoladePressItem = (item) => {
    resetSearch();
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
      customerId: item.accolade_id,
      dataType: OUR_CUSTOMERS_DATA_TYPES['accolades'],
      parentName: 'ACCOLADES',
      apiType: OUR_CUSTOMERS_API_TYPES['accolades'],
      type: 'accolades',
      likeType: OUR_CUSTOMERS_API_TYPES['accolades'],
    });
  };

  const onPressPorfileItem = (item) => {
    resetSearch();
    if (item.employee_id === employeeData.employee_id) {
      history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
    } else {
      history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${item.employee_id}`);
    }
  };

  const onChatPressItem = (item) => {
    resetSearch();
    history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${item.question_id}`);
  };

  const commonTabSection = (tab, onPress) => {
    let tabData = filterDataFunc(tab.category);
    return (
      <div>
        {tabData?.map((item, index) => {
          return (
            <div
              key={`suggestion_item_${tab.category + index}`}
              onClick={() => onPress(item)}
              className="common_quick_suggestion_text common-cursor-pointer">
              {item.title}
            </div>
          );
        })}
        {getSeeAllButton(tab)}
      </div>
    );
  };

  const handleHtmlClicks = (e) => {
    setShowSearchPopup(false);
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const renderTabs = (tab) => {
    let tabData = filterDataFunc(tab.category);
    switch (tab.category) {
      case 'news':
        return commonTabSection(tab, onPressNewsItem);
      case 'broadcasts':
        return commonTabSection(tab, onPressBroadcastItem);
      case 'article':
        return commonTabSection(tab, onPressArticleItem);
      case 'policy':
        return commonTabSection(tab, onPressPolicyItem);
      case 'wall':
        return (
          <div>
            {tabData?.map((item, index) => {
              return (
                <div
                  key={`suggestion_item_${tab.category + index}`}
                  onClick={() => onPressWallItem(item)}
                  className="common_quick_suggestion_text common-cursor-pointer">
                  <HtmlView html={item.description} onClick={handleHtmlClicks} />
                </div>
              );
            })}
            {getSeeAllButton(tab)}
          </div>
        );
      case 'customer':
        return (
          <div>
            {tabData?.map((item, index) => {
              return (
                <div
                  className="common-cursor-pointer"
                  key={`suggestion_item_${tab.category + index}`}
                  onClick={() => onAccoladePressItem(item)}>
                  <div className="common_quick_suggestion_title common_quick_suggestion_author">
                    {item.author}
                  </div>
                  <div
                    key={`suggestion_item_${tab.category + index}`}
                    className="common_quick_suggestion_text">
                    {item.title}
                  </div>
                </div>
              );
            })}
            {getSeeAllButton(tab)}
          </div>
        );
      case 'chat':
        return (
          <div>
            {tabData?.map((item, index) => {
              return (
                <div
                  key={`suggestion_item_${tab.category + index}`}
                  className="common_quick_suggestion_text common-cursor-pointer"
                  onClick={() => onChatPressItem(item)}>
                  <HtmlView html={item.question} onClick={handleHtmlClicks} />
                </div>
              );
            })}
            {getSeeAllButton(tab)}
          </div>
        );
      case 'directory':
        return (
          <div>
            {tabData?.map((item, index) => {
              return (
                <div
                  className="row common_quick_suggestion_profile_container common-cursor-pointer"
                  onClick={() => onPressPorfileItem(item)}
                  key={`suggestion_item_${tab.category + index}`}>
                  <UserProfilePhoto
                    imageBaseUrl={item?.profile_picture?.base_url}
                    imagePath={item?.profile_picture?.image_path}
                    imageClass="common-user-profile-photo-sm"
                    employeeId={item.employee_id}
                  />
                  <div className="common_quick_suggestion_title ml-3">
                    {`${item?.first_name + ' '}${item?.middle_name + ' '}${item?.last_name}`}
                  </div>
                </div>
              );
            })}
            {getSeeAllButton(tab)}
          </div>
        );
    }
  };

  const getDeviceScreenDensityQualifier = () => {
    return SCREEN_DENSITY_QUALIFIERS.LDPI;
  };

  const formatUrlWithDeviceScreenDensityQualifier = (url) => {
    let transformedUrl = url.split('/');
    transformedUrl.splice(-1, 0, getDeviceScreenDensityQualifier());
    transformedUrl = transformedUrl.join('/');
    return transformedUrl;
  };
  return (
    <div>
      <FavoriteModal
        isFavoriteModalVisible={isFavoriteModalVisible}
        setIsFavoriteModalVisible={setIsFavoriteModalVisible}
      />
      <Navbar
        onToggle={(val) => {
          setShowBackdrop(val);
        }}
        collapseOnSelect
        sticky="top"
        className="header-custom-navbar mainmenu"
        bg="light"
        expand="lg">
        <Navbar.Brand as={Link} to={NAVIGATION_ROUTES.HOME}>
          {configurations._accessibility_base_url && (
            <img
              className="header-logo-image"
              src={formatUrlWithDeviceScreenDensityQualifier(
                configurations._accessibility_base_url + companyLogo
              )}
            />
          )}
        </Navbar.Brand>
        <div className={showBackdrop ? 'navbar-collapse-backdrop' : ''} />
        {width < 769 ? (
          <LogoutBtn />
        ) : (
          <Navbar.Toggle
            ref={(e) => {
              navbarToggleRef.current = e;
            }}
            aria-controls="basic-navbar-nav"
          />
        )}
        <Navbar.Collapse
          id="basic-navbar-nav"
          ref={(e) => {
            navbarCollapseRef.current = e;
          }}>
          <Nav activeKey={location.pathname} className="ml-auto d-flex align-items-center">
            <div className="header-Menu-container">{renderMenu()}</div>
            {/* <Nav.Link
              eventKey="2"
              as={Link}
              to={NAVIGATION_ROUTES.DOWNLOAD_APP}
              className="header-highlighted-section-item">
              Download the App
            </Nav.Link> */}
            {/* //secondSection */}
            <div className="d-flex align-items-center secondSection ">
              <Nav.Link
                onClick={openSearchPopupHandler}
                eventKey="3"
                className="d-flex align-items-center">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.NEWSEARCH_V2
                  )}
                  className="header-icon"
                />
              </Nav.Link>
              <div className="header-Menu-container1">
                <div className="header-section-item-wrapper">
                  <NavDropdown
                    key={dropdownKey}
                    className="header-section-item1"
                    title={
                      <div className="notification-count-view" onClick={handleViewAllClick}>
                        <img
                          src={getHeaderComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_HEADER_COMPONENTS.NOTIFICATIONV2
                          )}
                          className="header-icon"
                        />
                        {notificationCount > 0 && (
                          <div className="notification-bg-view">{notificationCount}</div>
                        )}
                      </div>
                    }
                    show={dropdownOpen}
                    onToggle={handleToggle}
                    onMouseEnter={handleToggle}
                    onMouse>
                    <div
                      className="dropdown-container drop-down-container-notification"
                      style={{ right: 0 }}>
                      <div className="drawer-inner-middle">
                        <div className="drawer-inner flex-column">
                          {notificationData &&
                            notificationData.slice(0, 5).map((element, elementIndex) => {
                              return (
                                <div key={elementIndex}>
                                  <NavItem>
                                    <NotificationListItem
                                      onPressItem={() =>
                                        notificationListingNavigation(element?.data, history)
                                      }
                                      item={element}
                                      key={`${elementIndex}`}
                                    />
                                  </NavItem>
                                  {elementIndex === 4 && (
                                    <Nav.Link onClick={handleViewAllClick}>
                                      <div className="viewAllContainer">
                                        <div className="viewAllChange">View All</div>
                                      </div>
                                    </Nav.Link>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              </div>
              <div className="header-Menu-container1">
                <div className="header-section-item-wrapper">
                  <NavDropdown
                    className="header-section-item1"
                    title={
                      <div className="profileHeader d-flex align-items-center">
                        <div>
                          <div className="common-user-profile-photo-container">
                            <img
                              src={
                                profile?.profile_picture?.image_path
                                  ? getUrlWithSpecificRendition(
                                      profile?.profile_picture?.base_url,
                                      SCREEN_DENSITY_QUALIFIERS.LDPI,
                                      profile?.profile_picture?.image_path
                                    )
                                  : getFeatureComponentUrlLdpi(
                                      accessibilityData,
                                      ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                      ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                    )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    }>
                    <div
                      className="dropdown-container drop-down-container-notification"
                      style={{ right: 0 }}>
                      <div className="drawer-inner-middle1">
                        <div>
                          <NavItem>
                            <div className="logout-menu" ref={menuRef}>
                              <LogoutBtn />
                            </div>
                          </NavItem>
                        </div>
                      </div>
                    </div>
                  </NavDropdown>
                </div>
              </div>
            </div>
          </Nav>
          <div className={showSearchPopup ? 'open' : ''} id="header-fullscreen-search-icon">
            <form onSubmit={callSubmitFunction} ref={globalSearchRef}>
              <div className="row">
                <div className="col-sm-2 header-fullscreen-search-left-section-container"></div>
                <div className="col-sm-10 header-fullscreen-search-right-section-container">
                  <div className="row">
                    <div className="col-sm-10">
                      <input
                        autoFocus
                        type="search"
                        placeholder="Type to search"
                        onChange={onChangeText}
                      />
                    </div>
                    <div
                      className="col-sm-1 d-flex align-items-center justify-content-center common-cursor-pointer"
                      onClick={callSubmitFunction}>
                      <IoIosSearch size="2em" color={colors.silverChaliceLight} />
                    </div>
                    <div
                      onClick={closeSearchPopupHandler}
                      className="home-fullscreen-search-close-icon col-sm-1 d-flex align-items-center common-cursor-pointer">
                      <VscChromeClose size="2rem" color={colors.silverDark} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2 header-fullscreen-search-left-section-container"></div>
                <div className="col-sm-10 common_quick_suggestion_container">
                  {feedTypes?.map((tab, index) => {
                    return (
                      <div key={`suggestion_${index}`} className="pl-4 pr-4">
                        <div className="common_quick_suggestion_title">{tab.name}</div>
                        {renderTabs(tab, index)}
                        <Divider style="mt-2 mb-4" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
