import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

const PublicPrivateModal = ({ visible, setIsVisible, submit, question }) => {
  const [isPublicLocal, setIsPublicLocal] = useState(false);
  useEffect(() => {
    setIsPublicLocal(question.is_public);
  }, [question]);

  const renderBody = () => {
    return (
      <div className="common-custom-modal-container">
        <div className="common-custom-modal-title">Choose audience</div>
        <div className="mt-2 mb-2">
          <div className="d-flex flex-row align-items-start mb-2">
            <input
              type="radio"
              checked={isPublicLocal}
              className="ceo-chat-radio"
              onChange={() => {
                setIsPublicLocal(true);
              }}
            />
            <div>
              <div className="estore-regular-text">Public</div>
              <div className="mi-auction-artist">Show to all employees</div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-start mb-2">
            <input
              type="radio"
              className="ceo-chat-radio"
              checked={!isPublicLocal}
              onChange={() => {
                setIsPublicLocal(false);
              }}
            />
            <div>
              <div className="estore-regular-text">Private</div>
              <div className="mi-auction-artist">
                Show to
                <span className="mi-auction-artist-value">{` ${question?.employee?.first_name} ${question?.employee?.middle_name} ${question?.employee?.last_name} `}</span>
                and tagged employees if any
              </div>
            </div>
          </div>
        </div>
        <div className="ceo-chat-modal-buttons-block">
          <div
            className="common-custom-modal-secondary-button"
            onClick={(e) => {
              setIsVisible(false);
              e.stopPropagation();
            }}>
            <div className="common-custom-modal-secondary-button-title">CANCEL</div>
          </div>
          <div
            className="common-custom-modal-primary-button"
            onClick={(e) => {
              setIsVisible(false);
              submit(isPublicLocal);
              e.stopPropagation();
            }}>
            <div className="common-custom-modal-primary-button-title">SUBMIT</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      onHide={() => setIsVisible(false)}
      centered
      show={visible}
      dialogClassName="ceo-chat-public-private-modal"
      aria-labelledby="contained-modal-title-vcenter">
      {renderBody()}
    </Modal>
  );
};

export default PublicPrivateModal;
