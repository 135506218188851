import React from 'react';
import './Slide.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { notificationListingNavigation } from '../../navigation/NavigationHandler';

const SliderForImage = React.memo(function (StackedCarouselSlideProps) {
  const { data, dataIndex, imageBase } = StackedCarouselSlideProps;
  const history = useHistory();
  const coverImage = `${imageBase}${data.picture_path}`;
  return (
    <div className="card-card" draggable={false}>
      <div
        key={'1'}
        className="card-overlay fill"
        onClick={() => {
          notificationListingNavigation(JSON.parse(data?.greeting_payload), history);
        }}>
        <img alt="j" className="embed-responsive embed-responsive-16by9" src={coverImage} />
      </div>
    </div>
  );
});

SliderForImage.displayName = 'Slide';
export { SliderForImage };
