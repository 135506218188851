import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  FIREBASE_DB_NODES,
  HOME_SECTION_IDENTIFIERS,
  HOME_WIDGET_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { selectDashboardData, selectGreentingData } from '../../state/DashboardData';
import { selectBaseUrl } from '../../state/UIState';
import { selectMenuData } from '../../state/MenuData';
import Section from './components/Section';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import Banner from './components/Banner';
import FavoritesSection from './components/FavoritesSection';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectChatEnabled, selectML35Config } from '../../state/MasterData';
import { selectUserChatGroups } from '../../state/ChatData';
import { getNodeReference, uId } from '../../utils/ChatHandler';
import { selectEmployeeData } from '../../state/EmployeeData';
import { handleML35 } from '../../utils/featureNavigation';
import { generateToken, messaging } from '../../config/firebasePushNotification';
import { onMessage } from 'firebase/messaging';
import { notificationListingNavigation } from '../../navigation/NavigationHandler';
import { SET_NOTIFICATION_TOKEN } from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { Modal } from 'react-bootstrap';
import { StackedCarousel, ResponsiveContainer } from 'react-stacked-center-carousel';
import './Slide.css';
import { Slide } from './Slider';
import { VscChromeClose } from 'react-icons/vsc';
import { SliderForImage } from './ImageViewAppBanner';

import toast, { Toaster } from 'react-hot-toast';
import { selectHasShownBanner, setHasShownBanner } from '../../state/AppBanner';
import useWindowDimensions from '../../custom_hooks/useWindowDimensions';
import NotificationListItem from '../../common/ui/header/components/NotificationListingItem';
import { updateNotificationCount, updateNotificationListItem } from '../../state/NotificationData';
import { formatDateDDMonthYYYY } from '../../utils/common';

const HomeScreen = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [chatUnreadCount, setChatUnreadCount] = useState(0);
  const employeeData = useSelector(selectEmployeeData);
  const userChatGroups = useSelector(selectUserChatGroups);
  const chatEnabled = useSelector(selectChatEnabled);
  const accessibilityData = useSelector(selectAccessibilityData);
  const menuData = useSelector(selectMenuData);
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const dashboardData = useSelector(selectDashboardData);
  const greetings = useSelector(selectGreentingData);
  const ML35Config = useSelector(selectML35Config);
  const [fevariteSections, setFevarioteSections] = useState([]);
  const [showBanner, setShowAppBanner] = useState(false);
  const [imageBaseUrlForBanner, setImageBaseUrlForBanner] = useState();
  const [cardData, setCardData] = useState([]);
  const customScales = [1, 0.85, 0.7, 0.55];
  const [chatType, setChatType] = useState(false);
  const [sysType, setSysType] = useState(false);
  const hasShownBanner = useSelector(selectHasShownBanner);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const calculateCustomScales = (maxVisibleSlide) => {
    const scalesLength = Math.ceil((maxVisibleSlide + 3) / 2);
    return customScales.slice(0, scalesLength);
  };

  useEffect(() => {
    // Ensure that the effect runs only once after login
    if (greetings !== undefined && !hasShownBanner) {
      setShowAppBanner(true);
      setCardData(greetings?.cards_data);
      setImageBaseUrlForBanner(greetings?.base_url);

      dispatch(setHasShownBanner(true));
    }
  }, [greetings, hasShownBanner, dispatch]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (baseUrl !== '') {
      dispatch({ type: ACTION_TYPES.GET_DASHBOARD, payload: { type: 'web' } });
      dispatch({ type: ACTION_TYPES.NOTIFICATION_COUNT, payload: {} });
    }
  }, [baseUrl, dispatch]);

  useEffect(() => {
    setFevarioteSections(
      menuData.data
        ? menuData.data.section_list.find(
            (section) => section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION
          ).features
        : []
    );
  }, [menuData.data]);

  useEffect(() => {
    if (chatEnabled && chatEnabled === true) {
      if (Object.keys(userChatGroups).length > 0) {
        let i = 0;
        const uid = uId(employeeData);
        Object.values(userChatGroups).forEach((value) => {
          if (value.members && uid in value.members) {
            if (value.members[uid].unread_group_count !== null) {
              i = i + value.members[uid].unread_group_count;
            }
          }
        });
        if (i > 0) {
          if (i > 99) {
            setChatUnreadCount('99+');
          } else {
            setChatUnreadCount(i);
          }
        } else {
          setChatUnreadCount(0);
        }
      }
    }
  }, [chatEnabled, employeeData, userChatGroups]);
  // export const navigationForBackground = (data) => {
  //   notificationListingNavigation(data, history);
  // };
  const renderSection = (data, index) => {
    if (
      (data.items.length && data.identifier !== HOME_SECTION_IDENTIFIERS.FOOTER) ||
      HOME_WIDGET_IDENTIFIERS.includes(data.identifier)
    ) {
      return (
        <Section
          key={index}
          sectionId={data.id}
          sectionTitle={data.title}
          sectionIdentifier={data.identifier}
          sectionItemType={data.template}
          sectionRoute={data.route}
          sectionItems={data.items}
        />
      );
    }
  };
  const ref = React.useRef(StackedCarousel);

  const renderHomeSections = () => {
    return dashboardData.map((section, index) => {
      return renderSection(section, index);
    });
  };

  const onPressChat = () => {
    history.push(NAVIGATION_ROUTES.CHAT);
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div>
      <div className="home-container">
        {chatEnabled && (
          <div
            onClick={onPressChat}
            className="d-flex common-cursor-pointer chat-floating-icon p-2">
            <div className="position-relative flex-grow-1 d-flex justify-content-center align-items-center">
              <img
                className="chat-icon"
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CHAT_WHITE,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
              />
              <div className="position-absolute chat-floating-icon-text-bubble d-flex justify-content-center align-items-center p-1">
                <p className="mb-0">{chatUnreadCount.toString()}</p>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid p-0">
          <Banner />
        </div>
        <div className="container">
          <FavoritesSection sectionItems={fevariteSections} />
          {ML35Config.isActive && (
            <img
              onClick={() => handleML35(true)}
              className={
                fevariteSections.length > 0
                  ? 'ml-35-home-banner'
                  : 'ml-35-home-banner-no-fev-sections'
              }
              src={ML35Config.webBannerImage}
            />
          )}
          {dashboardData.length ? (
            renderHomeSections()
          ) : (
            <div className="home-loader-outer-view">
              <div className="home-loader-outer-view mt-3">
                <ActivityLoader visible={true} />
              </div>
            </div>
          )}
        </div>
        {width > 800 && cardData.length >= 1 && (
          <Modal
            show={showBanner}
            className="home-fullscreen-modal-dialog1 fade-scale"
            aria-labelledby="example-custom-modal-styling-title">
            <div
              className="home-fullscreen-modal-dialog-close-button-container1"
              onClick={() => {
                setShowAppBanner(false);
                dispatch(setHasShownBanner(true));
              }}>
              <VscChromeClose size="4em" color="#fff" />
            </div>
            <div className="card">
              <div style={{ width: '100%', position: 'relative' }}>
                {cardData && cardData?.length && cardData?.length >= 3 && (
                  <ResponsiveContainer
                    carouselRef={ref}
                    render={(width, carouselRef) => {
                      return (
                        <StackedCarousel
                          ref={carouselRef}
                          slideComponent={(props) => (
                            <Slide {...props} imageBase={imageBaseUrlForBanner} />
                          )}
                          slideWidth={cardData.legth === 3 ? 450 : 300}
                          carouselWidth={width}
                          data={cardData}
                          imageBase={imageBaseUrlForBanner}
                          maxVisibleSlide={cardData.length < 5 ? cardData.length : 5}
                          disableSwipe={false}
                          customScales={calculateCustomScales(
                            cardData.length < 5 ? cardData.length : 5
                          )}
                          transitionTime={450}
                        />
                      );
                    }}
                  />
                )}
                {cardData && cardData?.length && cardData?.length === 1 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100vh', // ensures the parent takes full viewport height
                      width: '100%', // ensures the parent takes full available width
                    }}>
                    <SliderForImage data={cardData[0]} imageBase={imageBaseUrlForBanner} />
                  </div>
                )}

                {cardData && cardData?.length && cardData?.length >= 3 && (
                  <div
                    className="card-button left"
                    size="small"
                    onClick={() => ref.current?.goBack()}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.5217 24L19.3125 21.18L10.2473 12L19.3125 2.82L16.5217 -2.43981e-07L4.64584 12L16.5217 24Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
                {cardData && cardData?.length && cardData?.length >= 3 && (
                  <div
                    className="card-button right"
                    size="small"
                    onClick={() => ref.current?.goNext()}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.47832 0L4.6875 2.82L13.7527 12L4.6875 21.18L7.47832 24L19.3542 12L7.47832 0Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
