import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectAnniversaryConfig } from '../../../../state/MasterData';
import { showToast } from '../../../../utils/common';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ML35VideoComponent from '../../components/ML35VideoComponent';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { COMMENT_BY_ID } from '../../../../services/ApiUrls';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { Modal } from 'react-bootstrap';
import { VscChromeClose } from 'react-icons/vsc';
import ReactPlayer from 'react-player';
import {
  saveAnniversaryGeoData,
  saveSomTabData,
  selectAnniversaryGeoData,
} from '../../../../state/TabData';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import ML35PictureGalleryItem from '../../components/ML35PictureGalleryItem';

const CelebrationsTab = ({ identifier, geo = 'INDIA' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const anniversaryGeoData = useSelector(selectAnniversaryGeoData);
  const [selectedGeo, setSelectedGeo] = useState(
    anniversaryGeoData && anniversaryGeoData !== '' ? anniversaryGeoData : 'INDIA'
  );

  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );

  const somButtonConfig = anniversaryConfig?.som_button_config;

  const [MLDayContent, setMLDayContent] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [show, setShow] = useState(false);
  const [mlDayPictureGalleryData, setMlDayPictureGalleryData] = useState([]);

  const callMLDayContentApi = useCallback(
    async (geo) => {
      setError(false);
      setMLDayContent([]);
      setMlDayPictureGalleryData([]);
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        if (anniversaryConfig.video_geos?.includes(geo)) {
          const params = { geography: geo || 'INDIA' };

          const apiResponse = await apiRequest(
            identifier + '/videogallery',
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setMLDayContent(apiResponse.response?.data ?? []);
            }
          }
          dispatch(resetLoaderState());
        } else {
          const params = { geography: geo };

          const apiResponse = await apiRequest(
            identifier + '/picturegallerynonindia',
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setMlDayPictureGalleryData(apiResponse.response?.data);
            }
          }
          dispatch(resetLoaderState());
        }
      } catch (err) {
        handleError(err, {}, identifier + '/videogallery', 'CelebrationsTab');
        dispatch(resetLoaderState());
        setError(false);
      }
    },
    [anniversaryConfig.video_geos, dispatch, identifier]
  );

  useEffect(() => {
    callMLDayContentApi(selectedGeo, 1);
  }, [callMLDayContentApi, selectedGeo]);

  const updateGeo = (geo) => {
    dispatch(saveAnniversaryGeoData(geo));
    setSelectedGeo(geo);
    callMLDayContentApi(geo, 1);
  };

  const callLikeApi = async (referId) => {
    setError(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    try {
      params.append('type', FEATURE_TYPE.ML35VID);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              callMLDayContentApi(selectedGeo);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.THE_WALL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      setError(true);
    }
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ML35_VIDEO_LIKE,
      item.video_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.video_id, item.liked);
  };

  const onPressWhoLikedHandler = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.ML35_VIDEO_LIKED_COUNT, item.video_id, item.title);
    if (item.like_count > 0) {
      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
        referId: item.video_id,
        parentType: FEATURE_TYPE.ML35VID,
      });
    }
  };

  const onPressCommentButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.ML35_VIDEO_COMMENTS, item.video_id, item.video_title);
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.video_id,
      likeType: FEATURE_TYPE.ML35VID,
      fromRoute: NAVIGATION_ROUTES.ANNIVERSARY_SCREEN,
      commentCount: item.comment_count,
    });
  };

  const gotoPictures = (item) => {
    history.push(NAVIGATION_ROUTES.ML35_PICTURES_SCREEN, {
      identifier: identifier,
      geography: selectedGeo,
      day_id: item.day_id,
      title: item.title,
    });
  };

  const celebrationItem = (item, index) => {
    return (
      <div key={`${index}`} className="celebration-item-container-padding">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h2 className="celebration-item-title-text">{item.title}</h2>
          {anniversaryConfig.video_geos?.includes(selectedGeo) && (
            <div className="mb-2">
              <CustomButton onClick={() => gotoPictures(item)}>Pictures</CustomButton>
            </div>
          )}
        </div>
        <div className="celebration-item-border" />
        <div className="d-flex justify-content-between align-items-center bottom-padding">
          <span className="celebration-item-subtitle">{item.sub_title}</span>
        </div>
        <div>
          {item.videos && item.videos.length > 0 ? (
            <div className="row">
              {item.videos.map((video, index) => (
                <ML35VideoComponent
                  onPressLikeButton={onPressLikeButton}
                  handleLikedPeople={onPressWhoLikedHandler}
                  onPressCommentButton={onPressCommentButton}
                  item={video}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <div>No videos available</div>
          )}
        </div>
      </div>
    );
  };

  const celebrationPictureGalleryItem = (item, index) => {
    return (
      <div key={`${index}`} className="w-100 bottom-padding">
        {item.content_type !== 'html' && (
          <>
            <div className="d-flex flex-row justify-content-between align-items-center w-100">
              <h2 className="celebration-item-title-text">{item.title}</h2>
            </div>
            <div className="celebration-item-border" />
            <div className="d-flex justify-content-between align-items-center bottom-padding">
              <span className="celebration-item-subtitle">{item.sub_title}</span>
            </div>
          </>
        )}
        <div>
          {item.pictures && item.pictures.length > 0 ? (
            <div>
              <ML35PictureGalleryItem
                item={item.pictures}
                key={`${index}`}
                identifier={identifier}
                geography={selectedGeo}
                day_id={item.day_id}
                title={item.title}
              />
            </div>
          ) : item.content_type === 'html' && !!item.html && item.html !== '' ? (
            <HtmlView html={item.html} htmlStyle="ml-35-html-text" />
          ) : (
            <div>No pictures available</div>
          )}
        </div>
      </div>
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onPressWallpapers = () => {
    history.push(NAVIGATION_ROUTES.WALL_PAPERS_SCREEN, {
      identifier: identifier,
    });
  };

  const onPressECards = () => {
    history.push(NAVIGATION_ROUTES.ECARDS, {
      tabKey: anniversaryConfig.ecard_id,
    });
  };

  const onPressSom = () => {
    dispatch(saveSomTabData(''));
    history.push({
      pathname: `${NAVIGATION_ROUTES.SOM_MAIN}/${somButtonConfig.som_id}`,
      state: {
        show_business_awardee: somButtonConfig.show_business_awardee,
        visible_tabs: somButtonConfig.visible_tabs,
      },
    });
  };

  const tabButtonComponent = () => {
    return (
      <div className="row h-100">
        {anniversaryConfig && anniversaryConfig?.wallpapers_active && (
          <>
            <div
              onClick={onPressWallpapers}
              className="celebration-tab-button-style common-cursor-pointer">
              WallPapers
            </div>
            <div className="vertical-divider-celebrations" />
          </>
        )}
        {anniversaryConfig && anniversaryConfig?.ecards_active && (
          <>
            <div
              onClick={onPressECards}
              className="celebration-tab-button-style common-cursor-pointer">
              e-Cards
            </div>
            <div className="vertical-divider-celebrations" />
          </>
        )}
        {somButtonConfig?.show_button && (
          <div onClick={onPressSom} className="celebration-tab-button-style common-cursor-pointer">
            {somButtonConfig?.som_button_text}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        {tabButtonComponent()}
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="home-fullscreen-modal-dialog fade-scale"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className="animate-bottom">
          <div
            className="home-fullscreen-modal-dialog-close-button-container"
            onClick={handleClose}>
            <div className="custom-video-close-icon">
              <VscChromeClose size="3em" color="#fff" />
            </div>
          </div>
          <ReactPlayer
            url={anniversaryConfig.celebrations_home_video}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            className="home-video-container"
          />
        </Modal.Body>
      </Modal>
      <div
        style={{
          height: '660px',
          width: '100%',
          backgroundImage: `url(${anniversaryConfig.celebration_home_image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}>
        <div className="geo-container common-cursor-pointer">
          {anniversaryConfig.geos?.map((item, index) => (
            <div className="geo-button" key={index} onClick={() => updateGeo(item)}>
              <div
                className={`geo-button-inner ${item === selectedGeo ? 'selected-geo-button' : ''}`}>
                <span className="geo-button-text">{item}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="mb-4">
          <div className="anniversary-celebration-video-inner-container">
            <div className="anniversary-celebration-play-button" onClick={handleShow}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="anniversary-celebration-play-button-img"
              />
            </div>
            <img
              className="anniversary-celebration-video-thumbnail"
              src={anniversaryConfig.celebrations_home_video_thumbnail}
            />
          </div>
        </div>
      </div>
      <div style={{ padding: '20px' }}>
        {MLDayContent.map((item, index) => {
          return (
            <div className="row" key={`${index}`}>
              {celebrationItem(item, index)}
            </div>
          );
        })}
      </div>
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        {mlDayPictureGalleryData.map((item, index) => {
          return (
            <div className="row" key={`${index}`}>
              {celebrationPictureGalleryItem(item, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CelebrationsTab;
