import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const CustomTextInput = (props) => {
  const { isTitle = true, prefix = '' } = props;

  return (
    <Form.Group className={props.formGroupClassName}>
      {isTitle && (
        <Form.Label>
          {props.title}
          {props.required && <span className="my-profile-required ml-1">(required)</span>}
        </Form.Label>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {prefix && <span style={{ marginRight: '4px' }}>{prefix}</span>}
        <Form.Control
          custom={props.custom}
          accept={props.accept}
          size={props.size}
          name={props.name}
          onBlur={props.onBlur}
          required={props.required}
          isInvalid={props.isInvalid}
          isValid={props.isValid}
          min={props.min}
          max={props.max}
          maxLength={props.maxLength}
          minLength={props.minLength}
          pattern={props.pattern}
          as={props.as}
          type={props.type}
          disabled={props.disabled}
          placeholder={props.placeholder}
          className={`${props.style}`}
          value={props.value}
          readOnly={props.readOnly}
          defaultValue={props.defaultValue}
          onChange={props.onChange}>
          {props.children}
        </Form.Control>
      </div>
      {props.errorMessage && (
        <Form.Control.Feedback type="invalid">{props.errorMessage}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default CustomTextInput;
