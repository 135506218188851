import React from 'react';
import './Slide.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { notificationListingNavigation } from '../../navigation/NavigationHandler';

const Slide = React.memo(function (StackedCarouselSlideProps) {
  const { data, dataIndex, isCenterSlide, swipeTo, slideIndex, imageBase } =
    StackedCarouselSlideProps;
  const history = useHistory();
  const coverImage = `${imageBase}${data[dataIndex].picture_path}`;
  return (
    <div className="card-card" draggable={false} key={`key${dataIndex}`}>
      <div
        className="card-overlay fill"
        onClick={() => {
          if (!isCenterSlide) {
            swipeTo(slideIndex);
            return;
          }
          notificationListingNavigation(JSON.parse(data[dataIndex].greeting_payload), history);
        }}>
        <img alt="j" className="embed-responsive embed-responsive-16by9" src={coverImage} />
      </div>
    </div>
  );
});

Slide.displayName = 'Slide';
export { Slide };
