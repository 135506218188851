import React, { useCallback, useEffect, useRef, useState } from 'react';
import Questiondiv from './components/QuestionView';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import LikeCommentView from './components/LikeCommentView';
import { useDispatch, useSelector } from 'react-redux';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { apiRequest, handleError } from '../../services/Service';
import { CEO_CHAT, COMMENT_BY_ID, QUESTION_VISIBILITY } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectBaseUrl,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { selectAccessData } from '../../state/UserAccessData';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import PublicPrivateModal from './components/PublicPrivateModal';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';

const CeoChatScreen = ({ history, location }) => {
  const [ceoChats, setCeoChats] = useState([]);
  const dispatch = useDispatch();
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [isSearchEnabled, setIsSearchEnabled] = useState(location?.hash ? true : false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const textInputRef = useRef();
  const searchTextRef = useRef(location?.hash ?? '');
  const inputSearchRef = useRef();
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);
  const [isCmd, setIsCmd] = useState();
  const accessData = useSelector(selectAccessData);
  const [showQuestionButton, setShowQuestionButton] = useState(true);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [showPublicPrivateModal, setShowPublicPrivateModal] = useState(false);
  const ceoChatAccessData = accessData?.data
    ? accessData?.data?.find((item) => item.feature_key === '_cmdChat')
    : {};

  useEffect(() => {
    const actions = ceoChatAccessData?.actions;
    if (actions) {
      setShowQuestionButton(actions?.includes('_question'));
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [ceoChatAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CEO_CHAT, NAVIGATION_ROUTES.CEO_CHAT);
  }, []);

  const setLikeFunction = async (questionId) => {
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.CEO_CHAT);
    try {
      logEvent(ANALYTICS_EVENT_TYPES.CEO_QUESTION_LIKE, questionId, ANALYTICS_ITEM_NAMES.LIKE);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + questionId + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            pageId.current = 1;
            loadMoreFlag.current = true;
            callCeoChatApi(searchTextRef.current);
          }
        }
      }
    } catch (err) {
      handleError(err, formData, COMMENT_BY_ID + questionId + '/like', NAVIGATION_ROUTES.CEO_CHAT);
      console.error(err);
    }
  };

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  const callCeoChatApi = useCallback(
    async (text = '', isQuickSearch = false) => {
      setLoadMoreBtn(true);
      if (loadMoreFlag.current) {
        if (isQuickSearch) {
          setQuickSearchLoading(true);
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
        }
        dispatch(saveShowEmptyContentView(true));
        let params = { page_id: pageId.current };
        try {
          if (searchTextRef.current) {
            params = { ...params, ...{ query: text } };
            logEvent(
              ANALYTICS_EVENT_TYPES.CEO_CHAT_SEARCH,
              searchTextRef?.current ?? '',
              ANALYTICS_ITEM_NAMES.SEARCH
            );
          }

          const apiResponse = await apiRequest(CEO_CHAT, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.session) {
                const data = apiResponse.response.data;
                if (data) {
                  setIsCmd(data.is_cmd);
                  if (data.questions.length > 0) {
                    if (pageId.current === 1) {
                      setCeoChats(data.questions);
                    } else {
                      setCeoChats((prev) => [...prev, ...data.questions]);
                    }
                    loadMoreFlag.current = true;
                  } else {
                    setCeoChats([]);
                    loadMoreFlag.current = false;
                    setLoadMoreBtn(false);
                  }
                }
              }
            }
          }
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(err, params, CEO_CHAT, NAVIGATION_ROUTES.CEO_CHAT);
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          //error
        }
      }
    },
    [dispatch]
  );

  const debouncedCallback = (event) => {
    searchTextRef.current = event.target.value;
    if (event && event.target.value.length > 2) {
      pageId.current = 1;
      loadMoreFlag.current = true;
      callCeoChatApi(searchTextRef.current, true);
    }
    if (event.target.value === '') {
      searchTextRef.current = '';
      pageId.current = 1;
      loadMoreFlag.current = true;
      setCeoChats([]);
      callCeoChatApi('');
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const renderScreen = useCallback(async () => {
    callCeoChatApi(searchTextRef.current);
  }, [callCeoChatApi]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  const loadMoreHandler = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callCeoChatApi(searchTextRef.current);
    }
  };

  const onPressWhoLikedHandler = (commentId) => {
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: commentId,
      parentType: FEATURE_TYPE.CEO_CHAT,
    });
  };
  const onClickButton = () => {
    history.push(NAVIGATION_ROUTES.CEO_ASK_QUESTION);
  };

  const callSubmitFunction = (event) => {
    searchTextRef.current = event.target.value;
  };

  const onSearchSubmitHandler = (event) => {
    event?.preventDefault();
    if (searchTextRef.current.length < 3) {
      pageId.current = 1;
      loadMoreFlag.current = true;
      setCeoChats([]);
      callCeoChatApi(searchTextRef.current);
    }
  };

  const resetSearchHandler = useCallback(() => {
    if (location.hash) {
      history.replace(NAVIGATION_ROUTES.CEO_CHAT);
      return;
    }
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageId.current = 1;
    loadMoreFlag.current = true;
    setCeoChats([]);
    callCeoChatApi('');
    setIsSearchEnabled(false);
  }, [callCeoChatApi]);

  const onClickSearchHandler = () => {
    logEvent(ANALYTICS_EVENT_TYPES.CEO_CHAT_SEARCH_CLICKED, '', ANALYTICS_ITEM_NAMES.CEO_CHAT);
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    if (inputSearchRef.current && location?.hash) {
      inputSearchRef.current.value = location?.hash;
      logEvent(ANALYTICS_EVENT_TYPES.HASHTAG_QUERY, location?.hash, ANALYTICS_ITEM_NAMES.HASHTAG);
    }
  }, [inputSearchRef.current, location]);

  const updateQuestionStatus = async (isPublic = false) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const formData = new URLSearchParams();
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.CEO_QUESTION_STATUS_CHANGE,
        selectedQuestion.question_id,
        isPublic ? ANALYTICS_ITEM_NAMES.PUBLIC : ANALYTICS_ITEM_NAMES.PRIVATE
      );
      formData.append('question_id', selectedQuestion.question_id);
      formData.append('question_visibility', isPublic ? 1 : 0);
      const apiResponse = await apiRequest(QUESTION_VISIBILITY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        setSelectedQuestion({});
        if (apiResponse.response.status === true) {
          pageId.current = 1;
          loadMoreFlag.current = true;
          callCeoChatApi(searchTextRef.current);
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    } catch (err) {
      handleError(err, formData, QUESTION_VISIBILITY, NAVIGATION_ROUTES.CEO_CHAT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  return (
    <div className="container">
      {Object.keys(selectedQuestion).length >= 0 && (
        <PublicPrivateModal
          question={selectedQuestion}
          setIsVisible={setShowPublicPrivateModal}
          submit={updateQuestionStatus}
          visible={showPublicPrivateModal}
        />
      )}
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled
              ? 'search-bar-with-icon search-bar-border mr-3'
              : 'search-bar-with-icon mr-3'
          }>
          {isSearchEnabled ? (
            <form
              className="directory-search-bar"
              onSubmit={onSearchSubmitHandler}
              ref={textInputRef}>
              <input
                autoFocus
                className="ml-2 directory-search-bar pr-3"
                type="text"
                ref={inputSearchRef}
                placeholder="Search.."
                maxLength={50}
                onChange={debounceInputHandler}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}

          {isSearchEnabled ? (
            <div className="gcdo-icon-row" onClick={resetSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon mr-2"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon mr-2"
              />
            </div>
          )}
        </div>
        {!isCmd && showQuestionButton && (
          <div className="gcdo-icon-row">
            <CustomButton
              buttonMainContainer="text-nowrap ceo-chat-ask-button"
              isSecondaryButton={true}
              onClick={onClickButton}>
              {'ASK QUESTION'}
            </CustomButton>
          </div>
        )}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />

      <div className="py-3 mx-auto">
        <ActivityLoader visible={quickSearchLoading} />
        {ceoChats?.length > 0
          ? ceoChats.map((item, pos) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${item.question_id}`);
                  }}
                  key={item.question_id}
                  className="ceo-chat-ceo-container material-card">
                  <Questiondiv
                    questionId={item.question_id}
                    question={item.question}
                    askedOn={item.asked_on}
                    employee={item.employee}
                    imageClassName="common-user-profile-photo-sm"
                    position={item.employee.position_title}
                    location={item.employee.location}
                    canAnswer={item.can_answer}
                    isPublic={item.is_public}
                    isCmd={isCmd}
                    onSelectChangeStatus={() => {
                      setSelectedQuestion(item);
                      setShowPublicPrivateModal(true);
                    }}
                  />
                  {Object.keys(item.latest_answer).length > 0 && (
                    <div className="ceo-chat-border" />
                  )}
                  {Object.keys(item.latest_answer).length > 0 && (
                    <div>
                      <Questiondiv
                        questionId={item.latest_answer.answer_id}
                        question={item.latest_answer.answer}
                        askedOn={item.latest_answer.answer_on}
                        employee={item.latest_answer.employee}
                        imageClassName="common-user-profile-photo-xs"
                      />
                      {/* {item.latest_answer.tagged_employees?.length > 0 && (
                        <div className="ceo-chat-ceo-tagged-view">
                          <div className="ceo-chat-ceo-tag-text">{' Tagged :  '}</div>
                          {item.latest_answer.tagged_employees.map((val, index) => (
                            <div
                              key={index}
                              onClick={(e) => {
                                history.push(
                                  `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${val.employee_id}`
                                );
                                e.stopPropagation();
                              }}
                              className="ceo-chat-ceo-tag-text common-cursor-pointer">{` ${
                              val.first_name
                            } ${val.last_name}${
                              index !== item.latest_answer.tagged_employees.length - 1 ? ',' : ''
                            }`}</div>
                          ))}
                        </div>
                      )} */}
                      {item.latest_answer.tagged_employees.length > 0 && (
                        <div className="ceo-chat-ceo-tagged-view">
                          <div className="ceo-chat-ceo-tag-text-label mb-2">Tagged:</div>
                          <div>
                            {item.latest_answer.tagged_employees.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                      `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${val.employee_id}`
                                    );
                                  }}
                                  className="d-flex flex-row mb-2 w-100 align-items-center">
                                  <UserProfilePhoto
                                    imageBaseUrl={val.profile_picture.base_url}
                                    imagePath={val.profile_picture.image_path}
                                    imageClass="ceo-chat-tagged-emp-pfp mr-2"
                                    employeeId={val.employee_id}
                                  />
                                  <div className="ceo-chat-ceo-tag-text common-cursor-pointer ml-2">
                                    {` ${val.first_name} ${val.last_name}`}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <LikeCommentView
                      showLikeButton={showLikeButton}
                      showCommentButton={showCommentButton}
                      question={item}
                      likeFunction={() => {
                        setLikeFunction(item.question_id);
                      }}
                      commentFunction={() => {
                        history.push(NAVIGATION_ROUTES.COMMENTS, {
                          referId: item.question_id,
                          likeType: FEATURE_TYPE.CEO_CHAT,
                        });
                      }}
                      replyFunction={() => {
                        history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${item.question_id}`);
                      }}
                      whoLikedFunction={() => {
                        onPressWhoLikedHandler(item.question_id);
                      }}
                    />
                    {item.latest_answer?.answer && (
                      <div className="ceo-chat-latest-reply-text">Latest Reply</div>
                    )}
                    {!item.latest_answer?.answer && (
                      <div className="ceo-chat-reply-waiting-text">
                        {item.can_answer ? 'New Question' : 'Awaiting reply'}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          : showEmptyContentView &&
            !progressLoadingState?.isProgressLoading && (
              <DefaultContentView
                style="mt-3"
                message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
                iconUri={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
                  ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
                )}
              />
            )}
        {loadMoreBtn && !progressLoadingState?.isProgressLoading && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn
              onClick={loadMoreHandler}
              isLoading={progressLoadingState.isProgressLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CeoChatScreen;
