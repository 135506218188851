import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { COMMUNITIES_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import CommunityMembersListItem from './components/CommunityMembersListItem';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { useParams } from 'react-router-dom';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';

const ViewMembersScreen = () => {
  const { communityId } = useParams();
  const [employees, setEmployees] = useState([]);
  const [title, setTitle] = useState('');
  const [membersCount, setMembersCount] = useState(0);
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [noMatchFlag, setNoMatchFlag] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.VIEW_MEMBERS, NAVIGATION_ROUTES.VIEW_MEMBERS);
  }, []);

  const callMembersData = useCallback(
    async (pageId = 1) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      if (pageId === 1) {
        setEmployees([]);
      }
      setNoMatchFlag(false);
      try {
        const response = await apiRequest(
          COMMUNITIES_BY_ID + communityId + '/members',
          REQUEST_TYPE.GET,
          { page_id: pageId }
        );
        if (Object.keys(response).length > 0) {
          if (response.response.status === true) {
            if (response.response.data.members) {
              const data = response.response.data.members;
              setTitle(response.response.data.title ?? '');
              setMembersCount(response.response.data.total_members ?? 0);
              if (data.length > 0) {
                if (pageId === 1) {
                  setEmployees(data);
                } else {
                  setEmployees((prev) => [...prev, ...data]);
                }
              }
            }
          }
          if (response.response.data.members.length === 0) {
            setLoadMoreFlag(false);
            setNoMatchFlag(true);
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(
          err,
          {},
          COMMUNITIES_BY_ID + communityId + '/members',
          NAVIGATION_ROUTES.VIEW_MEMBERS
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [communityId, dispatch]
  );

  useEffect(() => {
    callMembersData();
  }, [callMembersData]);

  const loadMore = () => {
    if (loadMoreFlag) {
      callMembersData(pageNo + 1);
      setPageNo(pageNo + 1);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <BackButton />
      <div className="main-content-container">
        <div className="mt-1 communities-title-text">{title}</div>
        <div className="d-flex mr-3 mb-3 mt-1">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_MEMBERS,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="header-icon mr-1"
          />
          {employees.length > 1 ? (
            <div className="mt-1 communities-member-detail">{membersCount} Members</div>
          ) : employees.length === 1 ? (
            <div className="mt-1 communities-member-detail">{membersCount} Member</div>
          ) : (
            <div className="mt-1 communities-member-detail">0 Members</div>
          )}
        </div>
        <div className="row">
          {employees.length > 0 &&
            employees.map((item, index) => {
              return <CommunityMembersListItem key={`${index}`} item={item} />;
            })}
        </div>
        {noMatchFlag && employees?.length === 0 && (
          <DefaultContentView message={ERROR_MESSAGES.NO_MEMBERS_AVAILABLE} />
        )}
      </div>
      {loadMoreFlag && <LoadMoreBtn onClick={loadMore} buttonStyle={'mb-2'} />}
    </div>
  );
};

export default ViewMembersScreen;
