import { createSlice } from '@reduxjs/toolkit';

const saveBookingDataReducer = (state, action) => {
  state.selectedType = action.payload;
};

const saveSeatArrayReducer = (state, action) => {
  state.seatArray = action.payload;
};

// REDUCER
const bookingDataSlice = createSlice({
  name: 'seatData',
  initialState: {
    selectedType: 'upcoming',
    seatArray: {},
  },
  reducers: {
    saveBookingData: saveBookingDataReducer,
    saveSeatArray: saveSeatArrayReducer,
  },
});

// ACTIONS
const { saveBookingData, saveSeatArray } = bookingDataSlice.actions;

// SELECTOR
const selectBookingData = ({ seatData }) => seatData.selectedType;
const selectSeatArray = ({ seatData }) => seatData.seatArray;

const bookingDataSliceReducer = bookingDataSlice.reducer;

export {
  bookingDataSliceReducer,
  saveBookingData,
  selectBookingData,
  saveSeatArray,
  selectSeatArray,
};
