import React, { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { selectEmployeeData } from '../../state/EmployeeData';
import {
  callCancelApi,
  getBookingDetail,
  getGallery,
  getLevelDetail,
  getSpaceDetail,
  getTipsApi,
} from './action';
import Divider from '../../common/ui/divider/Divider';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { msApiRequest } from '../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  BUTTON_TITLES,
  MODAL_TITLES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { Carousel } from 'react-bootstrap';
import CustomModal from '../../common/ui/custom_modal/CustomModal';
import { useHistory } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import StatusIndicator from '../../common/components/StatusIndicator';
import HelpfulTipsItem from './components/HelpfulTipsItem';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../state/UserAccessData';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

const BookingPreviewScreen = ({ location }) => {
  const formatDate = (dateString) => {
    const date = dayjs(dateString, 'YYYY-MM-DD');
    return date.format('dddd, DD MMMM YYYY');
  };
  const formattedDate = formatDate(location.state.selectedDate);
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);
  const [combinedProvisions, setCombinedProvisions] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [facilityId, setFacilityId] = useState(null);
  const [bookings, setBookings] = useState();
  const [facilityFullUrl, setFacilityFullUrl] = useState('');
  const [bookingMessage, setBookingMessage] = useState('');
  const [levelDetails, setLevelDetails] = useState();
  const [spaceDetails, setSpaceDetails] = useState();
  const [levelProvisions, setLevelProvisions] = useState([]);
  const [spaceProvisions, setSpaceProvisions] = useState([]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const history = useHistory();
  const [cancelMessage, setCancelMessage] = useState('');
  const [tips, setTips] = useState();

  const accessData = useSelector(selectAccessData);
  const parkAccessData = accessData?.data?.find((item) => item.feature_key === '_vehicleParking');
  const [showCancelButton, setShowCancelButton] = useState(true);

  useEffect(() => {
    const actions = parkAccessData?.actions;
    setShowCancelButton(actions?.includes('_cancel'));
  }, [parkAccessData?.actions]);

  const isFutureDate = dayjs(location.state.entryDatetime).isSameOrAfter(dayjs(), 'day');

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VEHICLE_PARKING_PREVIEW,
      NAVIGATION_ROUTES.VEHICLE_BOOKING_PREVIEW_SCREEN
    );
  }, []);

  useEffect(() => {
    if (levelDetails && levelDetails.provisions && levelDetails.provisions.length > 0) {
      setLevelProvisions(levelDetails.provisions);
    }
  }, [levelDetails]);

  useEffect(() => {
    if (spaceDetails && spaceDetails.provisions && spaceDetails.provisions.length > 0) {
      setSpaceProvisions(spaceDetails.provisions);
    }
  }, [spaceDetails]);

  useEffect(() => {
    if (levelProvisions.length > 0 || spaceProvisions.length > 0) {
      setCombinedProvisions([...levelProvisions, ...spaceProvisions]);
    }
  }, [levelProvisions, spaceProvisions]);

  const BookASeat = useCallback(async () => {
    if (location.state.fromRoute === 'BookingScreen') {
      return;
    } else {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const formData = new URLSearchParams();
        location.state.space_id && formData.append('space_id', location.state.space_id);
        employeeData.employee_id && formData.append('user_id', employeeData.employee_id);
        location.state.entryDatetime &&
          formData.append('entry_datetime', location.state.entryDatetime);
        location.state.exitDatetime &&
          formData.append('exit_datetime', location.state.exitDatetime);
        localTimezone && formData.append('timezone', localTimezone);
        formData.append('org_id', '1');
        const response = await msApiRequest(
          'vehicle-parking/bookings/book',
          REQUEST_TYPE.POST,
          formData
        );
        if (response.response.statusCode === 201) {
          setBookingMessage(response.response.message);
          setShowSuccessDialog(true);
        }
        dispatch(resetLoaderState());
      } catch (error) {
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    }
  }, [
    dispatch,
    employeeData.employee_id,
    localTimezone,
    location.state.entryDatetime,
    location.state.exitDatetime,
    location.state.fromRoute,
    location.state.space_id,
  ]);

  const callBookingDetailApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const response = await getBookingDetail(location.state.booking_id);
      if (response.response.statusCode === 200) {
        setBookings(response.response.data);
      }
      dispatch(resetLoaderState());
    } catch (error) {
      dispatch(resetLoaderState());
      console.error(error);
      return null;
    }
  }, [dispatch, location.state.booking_id]);

  const callCancelBookingApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const response = await callCancelApi(location.state.booking_id, employeeData.employee_id);
      if (response.response.statusCode === 200) {
        setCancelMessage(response.response.message);
        setShowCancelSuccessModal(true);
      }
      dispatch(resetLoaderState());
    } catch (error) {
      dispatch(resetLoaderState());
      console.error(error);
      return null;
    }
  }, [dispatch, employeeData.employee_id, location.state.booking_id]);

  const callGalleryApi = useCallback(
    async (facilityId) => {
      if (!facilityId) return;
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const response = await getGallery(facilityId);
        if (response.response.statusCode === 200) {
          setGalleryData(response.response.data.images);
        }
        dispatch(resetLoaderState());
      } catch (error) {
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    },
    [dispatch]
  );

  const getFacilityDetailApi = useCallback(
    async (facilityId) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const response = await msApiRequest('employee/facilities/' + facilityId, REQUEST_TYPE.GET);
        if (response.response.status_code === 200) {
          setFacilityFullUrl(response.response.data.full_url);
        }
        dispatch(resetLoaderState());
      } catch (error) {
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    },
    [dispatch]
  );

  const callLevelDetailApi = useCallback(
    async (levelId) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const response = await getLevelDetail(levelId);
        if (response.response.statusCode === 200) {
          setLevelDetails(response.response.data);
        }
        dispatch(resetLoaderState());
      } catch (error) {
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    },
    [dispatch]
  );

  const callSpaceDetailApi = useCallback(
    async (spaceId) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const response = await getSpaceDetail(spaceId);
        if (response.response.statusCode === 200) {
          setSpaceDetails(response.response.data);
        }
        dispatch(resetLoaderState());
      } catch (error) {
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    },
    [dispatch]
  );

  const callTipsApi = useCallback(async () => {
    try {
      const response = await getTipsApi();
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setTips(data.tips);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (location.state.fromRoute === 'BookingScreen') {
      callBookingDetailApi();
    } else {
      callLevelDetailApi(location.state.level.value);
      callSpaceDetailApi(location.state.space.value || location.state.space._id);
      const facilityId = location.state.facility?.value;
      if (facilityId) {
        setFacilityId(facilityId);
      }
    }
  }, [callBookingDetailApi, location.state, callLevelDetailApi, callSpaceDetailApi]);

  useEffect(() => {
    if (bookings && bookings?.levelsDetails) {
      callLevelDetailApi(bookings?.levelsDetails._id);
    }
  }, [bookings, callLevelDetailApi]);

  useEffect(() => {
    if (bookings && bookings?.spaceDetails) {
      callSpaceDetailApi(bookings?.spaceDetails._id);
    }
  }, [bookings, callSpaceDetailApi]);

  useEffect(() => {
    if (facilityId) {
      callGalleryApi(facilityId);
    }
  }, [facilityId, callGalleryApi]);

  useEffect(() => {
    if (
      location.state.fromRoute === 'BookingScreen' &&
      bookings?.facilityDetails?.[0]?.facility_id
    ) {
      setFacilityId(bookings.facilityDetails[0].facility_id);
    }
  }, [bookings, location.state.fromRoute]);

  useEffect(() => {
    if (facilityId) {
      getFacilityDetailApi(facilityId);
    }
  }, [facilityId, getFacilityDetailApi]);

  useEffect(() => {
    callTipsApi();
  }, [callTipsApi]);

  const getEmbedUrl = (fullUrl) => {
    if (fullUrl) {
      const coordsRegex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
      const placeIdRegex = /!1s([^!]+)!/;
      const placeNameRegex = /\/place\/([^/]+)\//;

      const coordsMatch = fullUrl.match(coordsRegex);
      const placeIdMatch = fullUrl.match(placeIdRegex);
      const placeNameMatch = fullUrl.match(placeNameRegex);

      if (coordsMatch && placeIdMatch && placeNameMatch) {
        const lat = coordsMatch[1];
        const lng = coordsMatch[2];
        const placeId = placeIdMatch[1];
        const placeName = decodeURIComponent(placeNameMatch[1]);

        return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.809222036497!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s${placeId}!2s${placeName}!5e0!3m2!1sen!2sin!4v${Date.now()}!5m2!1sen!2sin`;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const mapEmbedUrl = facilityFullUrl ? getEmbedUrl(facilityFullUrl) : '';

  const htmlContent = `
  <!DOCTYPE html>
  <html>
  <head>
    <style>
      body { margin: 0; }
      iframe { width: 100%; height: 200px; border: 0; }
    </style>
  </head>
  <body>
    <iframe 
      src="${mapEmbedUrl}" 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade">
    </iframe>
  </body>
  </html>
  `;

  const mapRedirect = (link) => {
    window.open(link, '_blank').focus();
  };

  return (
    <div className="container">
      <SectionHeader title={'MiPARKINGS'} />
      <BackButton />
      <CustomModal
        visible={showConfirmationDialog}
        onClose={() => {
          null;
        }}
        description={'Are you sure?<br />You would like to block this parking.'}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => setShowConfirmationDialog(false)}
        onClickPrimaryButton={() => {
          BookASeat();
          setShowConfirmationDialog(false);
        }}
      />
      <CustomModal
        visible={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
        }}
        description={`${bookingMessage}`}
        title={MODAL_TITLES.SUBMISSION_SUCCESS}
        primaryButtonTitle={BUTTON_TITLES.OK}
        onClickPrimaryButton={() => {
          setShowSuccessDialog(false);
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
        }}
      />
      <CustomModal
        visible={showCancelConfirmationModal}
        onClose={() => {
          setShowCancelConfirmationModal(false);
        }}
        description={'Are you sure?<br />You want to cancel this booking.'}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => setShowCancelConfirmationModal(false)}
        onClickPrimaryButton={() => {
          setShowCancelConfirmationModal(false);
          callCancelBookingApi();
        }}
      />
      <CustomModal
        visible={showCancelSuccessModal}
        onClose={() => {
          setShowCancelSuccessModal(false);
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
        }}
        description={cancelMessage}
        title={MODAL_TITLES.SUBMISSION_SUCCESS}
        primaryButtonTitle={BUTTON_TITLES.OK}
        onClickPrimaryButton={() => {
          setShowCancelSuccessModal(false);
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
        }}
      />
      <div className="seat-booking-colored-container p-2 d-flex flex-row">
        <div className="seat-booking-white-background p-2 mr-2 vehicle-preview-left-con-width">
          <div className="d-flex flex-row justify-content-between align-items-center">
            {location.state.selectedDate &&
              (location.state.fromRoute === 'BookingScreen' ? (
                <div className="base-14px-font-bold mb-2">{location.state.selectedDate}</div>
              ) : (
                <div className="base-14px-font-bold mb-2">{formattedDate}</div>
              ))}
            <div className="d-flex align-items-center mb-2">
              {bookings?.status && <StatusIndicator status={bookings?.status} />}
            </div>
          </div>
          <Divider style={'mb-2'} />
          {location.state.checkInTime && (
            <div className="d-flex flex-row justify-content-between mb-1">
              <div>Check-in Time</div>
              <div>{location.state.checkInTime}</div>
            </div>
          )}
          {location.state.checkOutTime && (
            <div className="d-flex flex-row justify-content-between mb-1">
              <div>Check-out Time</div>
              <div>{location.state.checkOutTime}</div>
            </div>
          )}
          {bookings &&
            Object.keys(bookings).length > 0 &&
            bookings.facilityDetails &&
            bookings.facilityDetails.length > 0 && (
              <div className="d-flex flex-row justify-content-between mb-1">
                <div>Facility</div>
                <div>{bookings.facilityDetails[0].title}</div>
              </div>
            )}
          {location.state.facility && (
            <div className="d-flex flex-row justify-content-between mb-1">
              <div>Facility</div>
              <div>{location.state.facility.label}</div>
            </div>
          )}
          {bookings &&
            Object.keys(bookings).length > 0 &&
            bookings?.levelsDetails &&
            Object.keys(bookings?.levelsDetails).length > 0 && (
              <div className="d-flex flex-row justify-content-between mb-1">
                <div>Level</div>
                <div>{bookings?.levelsDetails.name}</div>
              </div>
            )}
          {location.state.level && (
            <div className="d-flex flex-row justify-content-between mb-1">
              <div>Level</div>
              <div>{location.state.level.label}</div>
            </div>
          )}
          {location.state.parkingNumber &&
            (location.state.fromRoute === 'BookingScreen' ? (
              <div className="d-flex flex-row justify-content-between">
                <div>Parking Number</div>
                <div>{location.state.parkingNumber}</div>
              </div>
            ) : (
              <div className="d-flex flex-row justify-content-between">
                <div>Parking Number</div>
                <div>{location.state.parkingNumber.label || location.state.parkingNumber.name}</div>
              </div>
            ))}

          <Divider style={'mb-2 mt-2'} />

          <div className="base-12px-font"></div>

          {bookings &&
            Object.keys(bookings).length > 0 &&
            bookings.facilityDetails &&
            bookings.facilityDetails.length > 0 && (
              <div>
                <iframe
                  title="Map"
                  srcDoc={htmlContent}
                  style={{ width: '100%', height: '200px', border: 0 }}
                  allowFullScreen
                />
                <div>
                  <div>
                    <div className="base-12px-font">{bookings.facilityDetails[0].address}</div>

                    {bookings.facilityDetails[0].url && (
                      <div
                        className="seat-booking-navigate-text common-cursor-pointer"
                        onClick={() => {
                          mapRedirect(facilityFullUrl);
                        }}>
                        Navigate to map
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          {location.state.facility && location.state.facility.address && (
            <div>
              <iframe
                title="Map"
                srcDoc={htmlContent}
                style={{ width: '100%', height: '200px', border: 0 }}
                allowFullScreen
              />
              <div>
                <div>
                  <div className="base-12px-font">{location.state.facility.address}</div>
                  {location.state.facility && location.state.facility.link && (
                    <div
                      className="seat-booking-navigate-text common-cursor-pointer"
                      onClick={() => {
                        mapRedirect(facilityFullUrl);
                      }}>
                      Navigate to map
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isFutureDate &&
            bookings?.status !== 'Rejected' &&
            (location.state.fromRoute === 'BookingScreen' &&
            bookings?.status !== 'Cancelled' &&
            showCancelButton ? (
              <div className="d-flex justify-content-center">
                <div className="w-100">
                  <CustomButton
                    buttonStyle="estore-add-to-cart-button mt-3"
                    onClick={() => {
                      setShowCancelConfirmationModal(true);
                      logEvent(
                        ANALYTICS_EVENT_TYPES.VEHICLE_PARKING_PREVIEW_CANCEL_BUTTON_CLICKED,
                        '',
                        ANALYTICS_ITEM_NAMES.MIPARKING
                      );
                    }}>
                    CANCEL
                  </CustomButton>
                </div>
              </div>
            ) : (
              location.state.fromRoute !== 'BookingScreen' && (
                <div className="d-flex justify-content-center">
                  <div className="w-100">
                    <CustomButton
                      buttonStyle="estore-add-to-cart-button mt-3"
                      onClick={() => {
                        setShowConfirmationDialog(true);
                        logEvent(
                          ANALYTICS_EVENT_TYPES.VEHICLE_PARKING_PREVIEW_BOOK_BUTTON_CLICKED,
                          '',
                          ANALYTICS_ITEM_NAMES.MIPARKING
                        );
                      }}>
                      BOOK
                    </CustomButton>
                  </div>
                </div>
              )
            ))}
        </div>
        <div className="d-flex flex-row">
          {combinedProvisions && combinedProvisions.length > 0 && (
            <div className="seat-booking-white-background p-2 vehicle-preview-bottom-con mr-2">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">PARKING PROVISIONS</div>
              </div>
              {combinedProvisions.map((provision, index) => {
                return (
                  <>
                    <div key={`${index}`} className="d-flex flex-row">
                      <img src={provision.icon_path} className="seat-booking-provision-icon mr-4" />
                      <div>
                        <div className="base-14px-font">{provision.title}</div>
                        <HtmlView
                          html={provision.description}
                          htmlStyle="seat-booking-provision-description"
                        />
                      </div>
                    </div>
                    {index !== combinedProvisions.length - 1 && <Divider style={'mb-3'} />}
                  </>
                );
              })}
            </div>
          )}
          {galleryData && galleryData?.length > 0 && (
            <div className="seat-booking-white-background p-2 vehicle-preview-gallery-con">
              <Carousel
                interval={null}
                activeIndex={currentActiveSlideIndex}
                className="seat-booking-gallery-carousel"
                nextIcon={
                  galleryData?.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-next-icon" />
                  )
                }
                prevIcon={
                  galleryData?.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-prev-icon" />
                  )
                }
                onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                {galleryData?.map((imageItem, index) => {
                  return (
                    <Carousel.Item key={`${index}`}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="vehicle-preview-gallery-image seat-booking-gallery-carousel-border"
                          src={imageItem.name}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          )}
          {/* {tips && tips?.length > 0 && (
              <div className="seat-booking-white-background p-2 vehicle-preview-bottom-con">
                <>
                  <div className="estore-home-tab-products-header">
                    <div className="estore-product-type-title">HELPFUL TIPS</div>
                  </div>
                  {tips.map((item, index) => {
                    return (
                      <div key={`${index}`}>
                        <HelpfulTipsItem item={item} index={index} />
                        {index !== tips.length - 1 && <Divider />}
                      </div>
                    );
                  })}
                </>
              </div>
            )} */}
        </div>
      </div>
    </div>
  );
};

export default BookingPreviewScreen;
