import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { CEO_CHAT_ID, QUESTION_VISIBILITY } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getProfanityFilter, removeHtmlContent, showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import TagEmployeeView from '../wall/components/TagPeopleView';
import AnswerView from './components/AnswerView';
import Picker from 'emoji-picker-react';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { Modal } from 'react-bootstrap';
import { selectProfinityFilterWarning } from '../../state/MasterData';
import PublicPrivateModal from './components/PublicPrivateModal';

const CeoReplyScreen = ({ history, location }) => {
  const { post = {} } = location?.state ?? {};
  const [tags, setTags] = useState(
    location?.state?.taggedEmp
      ? location?.state?.taggedEmp
      : post && post.tags && post.tags.length > 0
      ? post.tags.map((employeeNode) => employeeNode)
      : []
  );
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [commentText, setCommentText] = useState(location?.state?.answerText || '');
  const questionId = location.state.questionId;
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);
  const [showEmojiDiv, setShowEmojiDiv] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);
  const [isPublic, setIsPublic] = useState(false);
  const [showPublicPrivateModal, setShowPublicPrivateModal] = useState(false);
  const [question, setQuestion] = useState({});
  const [isCmd, setIsCmd] = useState(false);
  const isEditCase = location.state.isEdit || false;

  const sendCommentFunction = async () => {
    if (tags.length > 5) {
      showToast(ERROR_MESSAGES.CEO_TAG_EMPLOYEE_MAX_LIMIT);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.CEO_LATEST_REPLY, question.question_id, '');
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      let tagString = '';
      if (tags.length > 0) {
        var tagArray = [];
        for (var i = 0; i < tags.length; i++) {
          tagArray.push(tags[i].employee_id);
        }
        tagString = tagArray.join(',');
      }
      const formData = new URLSearchParams();
      formData.append('answer', commentText);
      formData.append('tags', tagString);
      formData.append('question_visibility', isPublic ? 1 : 0);
      try {
        const apiResponse = await apiRequest(
          CEO_CHAT_ID + question.question_id + '/answer',
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              history.goBack();
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          formData,
          CEO_CHAT_ID + question.question_id + '/answer',
          NAVIGATION_ROUTES.CEO_CHAT
        );
        //error
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const callEditAnswerApi = async () => {
    if (tags.length > 5) {
      showToast(ERROR_MESSAGES.CEO_TAG_EMPLOYEE_MAX_LIMIT);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.CEO_LATEST_REPLY, question.question_id, '');
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      let tagString = '';
      if (tags.length > 0) {
        var tagArray = [];
        for (var i = 0; i < tags.length; i++) {
          tagArray.push(tags[i].employee_id);
        }
        tagString = tagArray.join(',');
      }
      const formData = new URLSearchParams();
      formData.append('answer', commentText);
      formData.append('tags', tagString);
      formData.append('question_visibility', isPublic ? 1 : 0);
      try {
        const apiResponse = await apiRequest(
          CEO_CHAT_ID + location.state.answerId + '/editanswer',
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              history.goBack();
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          formData,
          CEO_CHAT_ID + question.question_id + '/editanswer',
          NAVIGATION_ROUTES.CEO_CHAT
        );
        //error
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (tags.includes(employee)) {
      const index = tags.indexOf(employee);
      if (index > -1) {
        setTags(tags.filter((item) => item !== tags[index]));
      }
    }
  };
  const onEmployeeSelected = (tags) => {
    if (tags.length > 0) {
      setTags([...tags]);
      setShowTagEmployeeView(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmojiDiv(false);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    setCommentText(commentText + emojiObject.emoji);
    setShowEmojiDiv(false);
  };

  const callQuestionApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CEO_CHAT_ID + 'question/' + questionId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.question) {
            setIsCmd(apiResponse.response.data.is_cmd);
            const data = apiResponse.response.data.question;
            if (data) {
              setQuestion(data);
              setIsPublic(data.is_public);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        CEO_CHAT_ID + 'question/' + questionId,
        NAVIGATION_ROUTES.CEO_CHAT_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, questionId]);

  const renderScreen = useCallback(async () => {
    if (questionId) {
      callQuestionApi(questionId);
    }
  }, [callQuestionApi, questionId]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const updateQuestionStatus = async (isPublic = false) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const formData = new URLSearchParams();
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.CEO_QUESTION_STATUS_CHANGE,
        question.question_id,
        isPublic ? ANALYTICS_ITEM_NAMES.PUBLIC : ANALYTICS_ITEM_NAMES.PRIVATE
      );
      formData.append('question_id', question.question_id);
      formData.append('question_visibility', isPublic ? 1 : 0);

      const apiResponse = await apiRequest(QUESTION_VISIBILITY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          callQuestionApi();
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    } catch (err) {
      handleError(err, formData, QUESTION_VISIBILITY, NAVIGATION_ROUTES.CEO_CHAT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const renderTagEmployee = () => {
    return (
      <div>
        {showTagEmployeeView && (
          <TagEmployeeView onEmployeeSelected={onEmployeeSelected} tags={tags} />
        )}
        <div className="d-flex flex-wrap mt-3">
          {tags.length > 0 &&
            tags.map((item, index) => {
              return (
                <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 mb-4">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-lg"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div className="container pb-5">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <BackButton containerStyle="pt-3" />
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      {Object.keys(question).length > 0 && (
        <PublicPrivateModal
          question={question}
          setIsVisible={setShowPublicPrivateModal}
          submit={updateQuestionStatus}
          visible={showPublicPrivateModal}
        />
      )}
      {Object.keys(question).length > 0 && question.employee && (
        <div className="main-content-container material-card mb-3">
          <AnswerView
            employeeId={question.employee.employee_id}
            baseUrl={
              question.employee.profile_picture ? question.employee.profile_picture.base_url : ''
            }
            imagePath={
              question.employee.profile_picture ? question.employee.profile_picture.image_path : ''
            }
            employeeName={
              question.employee.first_name +
              ' ' +
              question.employee.middle_name +
              ' ' +
              question.employee.last_name +
              ' '
            }
            askedOn={question.asked_on}
            question={question.question}
            isQuestion={true}
            location={question.employee.location}
            position={question.employee.position_title}
            canAnswer={question.can_answer}
            isCmd={isCmd}
            isPublic={question.is_public}
            onSelectChangeStatus={() => setShowPublicPrivateModal(true)}
          />
        </div>
      )}
      <div>
        {/* <CommentBox
          getCommentText={sendCommentFunction}
          onChangehandler={(val) => {
            setCommentText(val);
          }}
          showLoader={progressLoadingState.isProgressLoading}
        /> */}
        <div className="common-comment-box my-3">
          <div
            ref={wrapperRef}
            className="common-comment-emoji-icon-container-ceo-chat common-cursor-pointer">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.EMOJI_SMILE_ICON,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              id="common-comment-emoji-icon"
              className="common-comment-emoji-icon mr-3"
              onClick={() => {
                setShowEmojiDiv(true);
              }}
            />
            {showEmojiDiv && (
              <div className="common-comment-emoji-div">
                <Picker onEmojiClick={onEmojiClick} />
              </div>
            )}
          </div>
          <textarea
            name="description"
            id="description"
            style={{ color: '#212529', fontSize: 15.008 }}
            rows="4"
            placeholder="Type a comment..."
            spellCheck="false"
            value={removeHtmlContent(commentText)}
            onChange={(event) => setCommentText(event.target.value)}
          />
        </div>
      </div>
      <div
        onClick={() => {
          setShowTagEmployeeView(true);
        }}
        className="d-flex common-cursor-pointer ml-2">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_TAG_PEOPLE,
            ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
          )}
          className="header-icon mr-2"
        />
        <div className="communities-tag-people">Tag Member(s)</div>
      </div>
      <div className="d-flex">
        <div className="common-container-md">{renderTagEmployee()}</div>
      </div>
      {Object.keys(question).length > 0 && isCmd && (
        <div>
          <div className="estore-product-type-title mb-2">Choose audience</div>
          <div>
            <div className="d-flex flex-row align-items-start mb-2">
              <input
                type="radio"
                checked={isPublic}
                className="ceo-chat-radio"
                onChange={() => {
                  setIsPublic(true);
                }}
              />
              <div>
                <div className="estore-regular-text">Public</div>
                <div className="mi-auction-artist">Show to all employees</div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-start mb-2">
              <input
                type="radio"
                className="ceo-chat-radio"
                checked={!isPublic}
                onChange={() => {
                  setIsPublic(false);
                }}
              />
              <div>
                <div className="estore-regular-text">Private</div>
                <div className="mi-auction-artist">
                  Show to
                  <span className="mi-auction-artist-value">{` ${question.employee.first_name} ${question.employee.middle_name} ${question.employee.last_name} `}</span>
                  and tagged employees if any
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CustomButton
        buttonStyle={isEditCase ? 'common-submit-btn-auto mt-2' : 'common-submit-btn mt-2'}
        onClick={() => {
          if (getProfanityFilter().isProfane(commentText)) {
            setShowWarningDialog(true);
          } else {
            if (isEditCase) {
              callEditAnswerApi();
            } else {
              sendCommentFunction(commentText);
            }
          }
        }}
        showLoader={progressLoadingState.isProgressLoading}>
        {isEditCase ? 'Confirm Edit' : 'Submit'}
      </CustomButton>
    </div>
  );
};

export default CeoReplyScreen;
