import React, { useCallback, useEffect, useRef, useState } from 'react';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { msApiRequest } from '../../../services/Service';
import { MEETING_ROOM_FACILITIES, MEETING_ROOM_SEARCH } from '../../../services/ApiUrls';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import {
  resetLoaderState,
  saveProgressLoadingState,
  selectProgressLoadingState,
} from '../../../state/UIState';
import { getTomorrowDateInYYYYMMDD, showToast } from '../../../utils/common';
import { selectMiMeetingMinInterval } from '../../../state/MasterData';
import dayjs from 'dayjs';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveMeetingArray } from '../../../state/MeetingData';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import Select from 'react-select';

const RoomsResultsScreen = ({ location }) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const now = dayjs();
  const roundedMinutes = Math.ceil(now.minute() / 30) * 30;
  const roundedTime = now.minute(roundedMinutes).second(0);

  const initialStartTime = roundedTime.format('HH:mm');
  const initialEndTime = roundedTime.add(30, 'minute').format('HH:mm');

  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const { query, facility_id, facilityTitle } = location.state;

  const [selectedDate, setSelectedDate] = useState(
    location.state.selectedDate || getTomorrowDateInYYYYMMDD()
  );
  const [checkInTime, setCheckInTime] = useState(
    location.state.checkInTime || initialStartTime || ''
  );
  const [checkOutTime, setCheckOutTime] = useState(
    location.state.checkOutTime || initialEndTime || ''
  );

  const entryDatetime = useCallback(() => {
    if (!selectedDate || !checkInTime) {
      return location.state?.entryDateTime || null;
    }
    return dayjs(`${selectedDate} ${checkInTime}`).format('YYYY-MM-DD HH:mm:ss');
  }, [checkInTime, location.state?.entryDateTime, selectedDate]);

  const exitDatetime = useCallback(() => {
    if (!selectedDate || !checkOutTime) {
      return location.state?.exitDateTime || null;
    }
    return dayjs(`${selectedDate} ${checkOutTime}`).format('YYYY-MM-DD HH:mm:ss');
  }, [checkOutTime, location.state?.exitDateTime, selectedDate]);

  const entryDateTime = entryDatetime();

  const exitDateTime = exitDatetime();

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const minIntervalInMins = useSelector(selectMiMeetingMinInterval);
  const formattedCheckInTime = dayjs(`${selectedDate} ${checkInTime}`).format('hh:mm A');
  const formattedCheckOutTime = dayjs(`${selectedDate} ${checkOutTime}`).format('hh:mm A');
  const [facilities, setFacilities] = useState([]);
  const [facilityID, setFacilityID] = useState(facility_id || null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const initialSelectedFacility =
    facility_id && facilityTitle ? { value: facility_id, label: facilityTitle } : null;

  const [selectedFacility, setSelectedFacility] = useState(initialSelectedFacility);

  const dropdownRef = useRef(null);

  const facilityOptions = facilities.map((facility) => ({
    value: facility.facility_id,
    label: facility.title,
    facilityData: facility,
  }));

  const handleFacilitySelect = (option) => {
    setSelectedFacility(option);
    setFacilityID(option ? option.value : null);
    callMeetingRoomsApi(query, option ? option.value : null);
  };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    const today = dayjs().startOf('day');

    if (dayjs(newDate).isBefore(today, 'day')) {
      showToast("Past dates can't be selected");
    } else {
      if (newDate !== selectedDate) {
        setCheckInTime('');
        setCheckOutTime('');
      }
      setSelectedDate(newDate);
    }
  };

  const handleCheckInTimeChange = (e) => {
    const selectedCheckInTime = e.target.value;
    const selectedEntryDateTime = dayjs(`${selectedDate} ${selectedCheckInTime}`);
    if (selectedEntryDateTime.isBefore(dayjs())) {
      showToast('The selected entry time is in the past. Please choose a future time.');
      return;
    }

    if (selectedCheckInTime !== checkInTime) {
      setCheckOutTime('');
    }

    setCheckInTime(selectedCheckInTime);
  };

  const handleCheckoutTimeChange = (e) => {
    const selectedCheckoutTime = e.target.value;

    if (!checkInTime) {
      showToast('Please select a Check-in time first.');
      return;
    }

    const checkInDateTime = dayjs(checkInTime, 'HH:mm');
    const checkOutDateTime = dayjs(selectedCheckoutTime, 'HH:mm');

    if (checkOutDateTime.isBefore(checkInDateTime)) {
      showToast('Check-out time should be after Check-in time.');
      return;
    }

    if (checkOutDateTime.diff(checkInDateTime, 'minute') < minIntervalInMins) {
      showToast(
        `Check-out time should be at least ${minIntervalInMins} minutes later than Check-in time.`
      );
      return;
    }

    setCheckOutTime(selectedCheckoutTime);
  };

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await msApiRequest(MEETING_ROOM_FACILITIES, REQUEST_TYPE.GET, {
        org_id: 1,
        source: 'meeting',
      });
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status_code === 200) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data.length > 0) {
              setFacilities(data);
            }
          }
        }
      }
      dispatch(resetLoaderState());
    } catch (err) {
      dispatch(resetLoaderState());
    }
  }, [dispatch]);

  const callMeetingRoomsApi = useCallback(
    async (query = '', facilityId = facilityID) => {
      if (!checkInTime || !checkOutTime) {
        setSearchList([]);
        return;
      }
      if (checkInTime && checkOutTime) {
        try {
          setSearchList([]);
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const response = await msApiRequest(MEETING_ROOM_SEARCH, REQUEST_TYPE.GET, {
            org_id: 1,
            query: query,
            facility_id: facilityId || null,
            entry_datetime: entryDateTime,
            exit_datetime: exitDateTime,
            timezone: localTimezone,
          });
          if (Object.keys(response).length > 0) {
            if (response.response.statusCode === 200) {
              if (response.response.data) {
                const data = response.response.data;
                setSearchList(data.rooms);
              }
            }
          }
          dispatch(resetLoaderState());
        } catch (err) {
          dispatch(resetLoaderState());
        }
      } else {
        return;
      }
    },
    [checkInTime, checkOutTime, dispatch, entryDateTime, exitDateTime, facility_id, localTimezone]
  );

  useEffect(() => {
    callMeetingRoomsApi(query);
    callFacilitiesApi();
  }, [query, entryDateTime, exitDateTime, callMeetingRoomsApi, callFacilitiesApi]);

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MI_MEETING_ROOMS_RESULTS,
      NAVIGATION_ROUTES.MI_MEETING_ROOMS_RESULTS_SCREEN
    );
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={
          !checkInTime || !checkOutTime
            ? 'Please choose a time slot before continuing.'
            : MESSAGES.NO_ROOMS_FOUND
        }
      />
    );
  };

  return (
    <div className="container">
      <SectionHeader title={'MiMEETING'} />
      <BackButton />
      <div className="seat-booking-colored-container p-2">
        <div className="seat-booking-white-background p-2 d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row">
            <div className="mr-2 room-res-date-container">
              <div className="base-12px-font-semi-bold">Date</div>
              <CustomTextInput
                name="destination_pref_date"
                isTitle={false}
                placeholder="Date"
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
            <div className="mr-2 room-res-date-container">
              <div className="base-12px-font-semi-bold">Start time</div>
              <CustomTextInput
                isTitle={false}
                placeholder="Time"
                name="pref_time"
                type="time"
                value={checkInTime}
                onChange={handleCheckInTimeChange}
              />
            </div>
            <div className="room-res-date-container">
              <div className="base-12px-font-semi-bold">End time</div>
              <CustomTextInput
                isTitle={false}
                placeholder="Time"
                name="pref_time"
                type="time"
                value={checkOutTime}
                onChange={handleCheckoutTimeChange}
                meeting={'end'}
              />
            </div>
          </div>
          {facility_id && facilityTitle && (
            <div className="d-flex flex-column mt-n3 room-res-date-container">
              <div className="base-12px-font-semi-bold">Select Facility</div>
              <Select
                options={facilityOptions}
                value={selectedFacility}
                onChange={handleFacilitySelect}
                placeholder="Select Facility"
              />
            </div>
          )}
        </div>
        <div className="mi-meet-grid-con mt-2">
          {searchList?.length > 0 &&
            searchList.map((item, index) => {
              return (
                <div
                  className="mb-1 common-cursor-pointer"
                  key={`${index}`}
                  onClick={() => {
                    dispatch(saveMeetingArray({}));
                    history.push(NAVIGATION_ROUTES.MEETING_PREVIEW, {
                      checkInTime: formattedCheckInTime,
                      checkOutTime: formattedCheckOutTime,
                      item: item,
                      entryDateTime: entryDateTime,
                      exitDateTime: exitDateTime,
                      selectedDate: selectedDate,
                    });
                    logEvent(
                      ANALYTICS_EVENT_TYPES.MI_MEETING_ROOM_CLICKED,
                      item._id,
                      ANALYTICS_ITEM_NAMES.MEETING_ROOM
                    );
                  }}>
                  <div className="seat-booking-white-background p-2 mi-meet-rs-item-con mi-meeting-rs-border-top-radius w-100">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div className="base-14px-font-semi-bold">
                        {item?.title?.length > 30
                          ? item?.title.substring(0, 31) + '...'
                          : item?.title}
                      </div>
                      <img
                        className="mi-meet-right-arrow"
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_RIGHT_ARROW,
                          ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                        )}
                      />
                    </div>
                    <div className="base-10px-semibold mt-1">{item.facility_details?.title}</div>
                    <div className="d-flex flex-row mt-2">
                      {item?.equipments.slice(0, 4).map((provision, index) => {
                        return (
                          <div key={`${index}`} className="meet-prev-provision-container">
                            <img
                              src={provision.icon_path}
                              className="mi-meet-provision-icon mr-1 common-cursor-pointer"
                            />
                            <div className="meet-prev-tooltip">{provision.title}</div>
                          </div>
                        );
                      })}
                      {item.equipments.length > 4 && (
                        <div className="d-flex flex-row align-items-center">
                          <div className="mi-meet-prov-remaining-text">
                            +{item.equipments.length - 4}
                          </div>
                        </div>
                      )}
                    </div>
                    <HtmlView
                      html={
                        item.description.length > 180
                          ? item.description.substring(0, 179) + '...'
                          : item.description
                      }
                      htmlStyle="base-10px-font mt-1 pb-3 mi-meet-html-view-container"
                    />
                  </div>
                  <div className="mi-meet-rs-max-con p-2 d-flex flex-row mi-meeting-rs-border-bottom-radius">
                    <div className="mi-meet-max-limit-text pr-1">No of seats:</div>
                    <div className="mi-meet-max-limit-text">{item.capacity} people</div>
                  </div>
                </div>
              );
            })}
        </div>
        <div>
          {searchList?.length === 0 && !progressLoadingState.isProgressLoading && emptyListView()}
        </div>
      </div>
    </div>
  );
};

export default RoomsResultsScreen;
