import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { formatDateDDMonthYYYY, getAwardIcon } from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_CHEERS, SUBMIT_LIKE_COMMENT_CHEERS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import {
  resetLoaderState,
  saveProgressLoadingState,
  saveShowEmptyContentView,
} from '../../state/UIState';

import { useDispatch, useSelector } from 'react-redux';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import Divider from '../../common/ui/divider/Divider';
import _ from 'lodash';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectEmployeeData } from '../../state/EmployeeData';
import HtmlView from '../../common/ui/html_view/HTMLView';

const ProfileCheersListingScreen = ({ history, location }) => {
  const profileData = location?.state?.empData;
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [collapsibleItems, setCollapsibleItems] = useState([]);
  const employeeData = useSelector(selectEmployeeData);

  const handleShowhidePress = (index) => {
    let itemsList = _.cloneDeep(collapsibleItems);
    if (itemsList.includes(index)) {
      let newList = itemsList.filter((it, ind) => it !== index);

      setCollapsibleItems(newList);
    } else {
      itemsList.push(index);
      setCollapsibleItems(itemsList);
    }
  };

  const callMyCheersApi = useCallback(
    async (pageid, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        try {
          setLoading(true);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
              progressMessage: LOADING_MESSAGES.FETCH_CHEERS,
            })
          );
          let apiResponse = await apiRequest(
            GET_CHEERS + `/${profileData?.employee_id}/list?page_id=${pageid}`,
            REQUEST_TYPE.GET,
            {}
          );
          dispatch(resetLoaderState());
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setCheersList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            {},
            GET_CHEERS + `/${profileData?.employee_id}/list?page_id=${pageid}`,
            NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN
          );
          setLoading(false);
          dispatch(resetLoaderState());
        }
      }
    },
    [dispatch, profileData]
  );

  useEffect(() => {
    callMyCheersApi(1, true);
  }, [callMyCheersApi]);

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CHEERS_RECEIVED_COMMENT,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.cheer_employee_award_id,
      likeType: FEATURE_TYPE.CHEERS,
      fromRoute: NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN,
      type: 'reward',
    });
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CHEERS_RECEIVED_LIKE,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.cheer_employee_award_id);
  };

  const callLikeApi = async (cheersEmployeeAwardId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.CHEERS);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(cheersEmployeeAwardId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const updateLikeCount = (cheersEmployeeAwardId) => {
    let arr = cheersList;
    let oldCount = arr.find((element) => element.cheer_employee_award_id === cheersEmployeeAwardId)
      .likes.count;
    let objIndex = arr.findIndex(
      (element) => element.cheer_employee_award_id === cheersEmployeeAwardId
    );
    let isLiked = arr[objIndex].likes.is_liked;
    arr[objIndex].likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].likes.is_liked = !isLiked;
    setCheersList([...arr]);
  };

  const onPressOthers = (nominationsList) => {
    history.push(NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN, {
      nominationsList: nominationsList,
      from: NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN,
      title: 'Nominated by',
    });
  };
  ``;

  const onPressLoadMore = () => {
    callMyCheersApi(pageId, loadMoreFlag);
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PROFILE_CHEERS_LISTING_SCREEN,
      NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN
    );
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="material-card p-3 mb-3">
        <div className="row align-items-center pl-3 flex-nowrap">
          <UserProfilePhoto
            imageBaseUrl={profileData?.profile_picture?.base_url}
            imagePath={profileData?.profile_picture?.image_path}
            imageClass="common-user-profile-photo-lg"
            employeeId={
              employeeData.employee_id === profileData?.employee_id ? '' : profileData?.employee_id
            }
          />
          <div className="ml-3 cheers-outer-view cheers-center-view">
            <div>
              <div className="cheers-emp-name">
                {profileData?.first_name +
                  `${profileData?.middle_name ? ' ' + profileData?.middle_name + ' ' : ' '}` +
                  profileData?.last_name}
              </div>
              <div className="cheers-emp-position">{profileData?.position_title}</div>
              <div className="cheers-emp-position">{profileData?.location?.title}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {cheersList.map((item, index) => {
          return (
            <div className="material-card p-3 mb-3" key={`${item?.cheer_employee_award_id}`}>
              <div className="d-block">
                <div className="cheers-outer-view justify-content-between">
                  <div className="cheers-icon-text-view cheers-outer-view">
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        getAwardIcon(item.award_details.icon_name),
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon"
                    />
                    <div className="ml-2 cheers-award-title-text">{item.award_details.title}</div>
                  </div>

                  <div className="cheers-date-view-style">
                    {item?.likes?.is_liked ? (
                      <div onClick={() => onPressLikeButton(item)}>
                        <img
                          src={getHeaderComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                            ACCESSIBILITY_IDENTIFIERS.CHEERS
                          )}
                          className="cheers-normal-icon ml-3 common-cursor-pointer"
                        />
                      </div>
                    ) : (
                      <div onClick={() => onPressLikeButton(item)}>
                        <img
                          src={getHeaderComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                            ACCESSIBILITY_IDENTIFIERS.CHEERS
                          )}
                          className="cheers-normal-icon ml-3 common-cursor-pointer"
                        />
                      </div>
                    )}
                    <div
                      className="ml-3 likesCountText common-cursor-pointer"
                      onClick={() => {
                        item?.likes?.count > 0
                          ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                              referId: item.cheer_employee_award_id,
                              parentType: FEATURE_TYPE.CHEERS,
                            })
                          : () => {};
                      }}>
                      {item?.likes?.count}
                    </div>
                    <div onClick={() => onPressCommentButton(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                    <div className="ml-3 commentCountText">{item?.comment_count}</div>
                  </div>
                </div>
                <div className="cheers-date-text mt-2">
                  {formatDateDDMonthYYYY(item.created_on)}
                </div>
              </div>
              <Divider style="mt-2" />
              <div>
                {collapsibleItems.includes(index) && (
                  <div>
                    <div className="cheers-select-option-text cheers-select-padding">
                      Why is this award for?
                    </div>
                    <div className="mt-2 mb-2 cheers-citation-text">
                      <HtmlView html={item.citation} />
                    </div>
                    {item.citation_text && (
                      <div className="cheers-select-option-text">Citation</div>
                    )}
                    {item.citation_text && (
                      <p className="mt-2 mb-2 cheers-citation-text">
                        <HtmlView html={item.citation_text} />
                      </p>
                    )}
                    <Divider />
                  </div>
                )}

                <div className="cheers-date-view-style justify-content-between align-items-center mt-3">
                  <div className="cheers-date-view-style">
                    <div className="cheers-given-nominate-text mr-1">Nominated by:</div>
                    {item.nominations.length > 0 && (
                      <div
                        onClick={() => {
                          onPressOthers(item.nominations);
                        }}
                        className="cheers-nom-user-text common-cursor-pointer">
                        {`${item.nominations[0].nominated_by.first_name} ${item.nominations[0].nominated_by.last_name}`}
                      </div>
                    )}
                    {item.nominations.length > 1 && (
                      <div
                        onClick={() => {
                          onPressOthers(item.nominations);
                        }}
                        className="cheers-nom-user-text common-cursor-pointer">
                        {item.nominations.length === 2
                          ? ` + (${item.nominations.length - 1}) Other`
                          : ` + (${item.nominations.length - 1}) Others`}
                      </div>
                    )}
                    {item.nominations.length === 0 && (
                      <div className="cheers-nom-user-text">{'Admin'}</div>
                    )}
                  </div>
                  {collapsibleItems.includes(index) ? (
                    <div
                      className="cheers-date-view-style common-cursor-pointer"
                      onClick={() => handleShowhidePress(index)}>
                      <div className="cheers-show-hide-detail-text">Hide Details</div>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_UP,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-2"
                      />
                    </div>
                  ) : (
                    <div
                      className="cheers-date-view-style common-cursor-pointer"
                      onClick={() => handleShowhidePress(index)}>
                      <div className="cheers-show-hide-detail-text">Show Details</div>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_DOWN,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-2"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="m-3">
        {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
      </div>
    </div>
  );
};

export default ProfileCheersListingScreen;
