import { createSlice } from '@reduxjs/toolkit';

const saveVehicleBookingDataReducer = (state, action) => {
  state.selectedType = action.payload; // Or update state.bookings based on what you're trying to achieve
};

const saveParkArrayReducer = (state, action) => {
  state.parkArray = action.payload;
};

// REDUCER
const bookingDataSlice = createSlice({
  name: 'parkData',
  initialState: {
    selectedType: 'upcoming',
  },
  reducers: {
    saveBookingData: saveVehicleBookingDataReducer,
    saveParkArray: saveParkArrayReducer,
  },
});

// ACTIONS
const { saveVehicleBookingData, saveParkArray } = bookingDataSlice.actions;

// SELECTOR
const selectBookingData = ({ parkData }) => parkData.selectedType;
const selectParkArray = ({ parkData }) => parkData.seatArray;

const vehcileBookingDataSliceReducer = bookingDataSlice.reducer;

export {
  vehcileBookingDataSliceReducer,
  saveVehicleBookingData,
  selectBookingData,
  saveParkArray,
  selectParkArray,
};
