import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import Navigation from './navigation/Navigation';
import { saveMasterdata, selectChatEnabled } from './state/MasterData';
import { getMasterdata, getProfanityListData } from './utils/FirebaseDBUtils';
import {
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FIREBASE_DB_NODES,
  ORG_NAMES,
  REQUEST_TYPE,
} from './common/constants/AppConstants';
import {
  saveBaseUrl,
  selectBaseUrl,
  saveLamdaUrl,
  saveIsFullScreenLoaderLoading,
  saveOrgDomain,
  selectShowAttendanceModal,
} from './state/UIState';
import 'react-toastify/dist/ReactToastify.css';
import { fetchMyGroupsOnly, fetchUserGroupsById, getNodeReference, uId } from './utils/ChatHandler';
import { selectMenuData } from './state/MenuData';
import { selectEmployeeLoginData } from './state/EmployeeData';
import { selectAccessibilityData } from './state/AccessibilityData';
import { logEvent, setUserId } from './utils/FirebaseAnalyticsUtils';
import { formatDateDDMonthYYYY, showToast } from './utils/common';
import Header from './common/ui/header/NewHeader';
import Footer from './common/ui/footer/Footer';
import DownloadAppPopup from './common/ui/download_app_popup/DownloadAppPopup';
import FullScreenLoader from './common/ui/full_screen_loader/FullScreenLoader';
import ProgressDialog from './common/ui/progress_dialog/ProgressDialog';
import { saveProfanityList, selectProfanityList } from './state/ProfanityData';
import AccessDeniedComponent from './common/ui/access_denied/AccessDeniedComponent';
import MandatoryAcknwoledgementModal from './common/ui/acknowledgement/MandatoryAcknowledgementModal';
// import PublicProfile from './common/ui/public_profile/PublicProfile';
import AcknowledgementResponseModal from './common/ui/acknowledgement/AcknowledgementResponseModal';
import AttendanceModal from './common/ui/attendance/AttendanceModal';
import StoreUnAuthorisedError from './common/ui/estore_no_access_modal/StoreUnAuthorisedError';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { notificationListingNavigation } from './navigation/NavigationHandler';
import {
  saveNotificationListItem,
  updateNotificationCount,
  updateNotificationListItem,
} from './state/NotificationData';
import { onMessage } from 'firebase/messaging';
import toast, { Toaster } from 'react-hot-toast';
import { generateToken, isBrowserSupported, messaging } from './config/firebasePushNotification';
import {
  GET_NOTIFICATION_COUNT,
  GET_NOTIFICATION_LIST,
  SET_NOTIFICATION_TOKEN,
} from './services/ApiUrls';
import uuid from 'react-uuid';
import { apiRequest } from './services/Service';

let utc = require('dayjs/plugin/utc');
let relativeTime = require('dayjs/plugin/relativeTime');
let customParseFormat = require('dayjs/plugin/customParseFormat');

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const menuData = useSelector(selectMenuData);
  const chatEnabled = useSelector(selectChatEnabled);
  const employeeData = useSelector(selectEmployeeLoginData);
  const baseUrl = useSelector(selectBaseUrl);
  const profanityList = useSelector(selectProfanityList);
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(customParseFormat);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(false);
  const [acknowledgementModalData, setAcknowledgementModalData] = useState({});
  // const [showPublicProfile, setShowPublicProfile] = useState(false);
  // const [publicProfileId, setPublicProfileId] = useState('');
  const [acknowledgementResponse, setAcknowledgementResponse] = useState(null);
  const [showAcknowledgementResponseModal, setShowAcknowledgementResponseModal] = useState(false);
  const showAttendanceModal = useSelector(selectShowAttendanceModal);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const apiResponse = await apiRequest(GET_NOTIFICATION_LIST, REQUEST_TYPE.GET, {
          page_id: 1,
        });

        if (!apiResponse || !apiResponse.response || !apiResponse.response.data) {
          console.error('Invalid API response:', apiResponse);
          return;
        }

        if (apiResponse.response.status === true) {
          const feeds = apiResponse.response.data.feeds;
          if (Array.isArray(feeds)) {
            dispatch(saveNotificationListItem(feeds.slice(0, 5)));
          } else {
            console.error('Expected feeds to be an array, but got:', feeds);
          }
        } else {
          console.error('API response status is false:', apiResponse);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [dispatch]);

  useEffect(() => {
    if (isBrowserSupported()) {
      onMessage(messaging, (payload) => {
        const notification = new Notification(payload?.notification?.title, {
          body: payload?.notification?.body,
          icon: payload?.data?.sender_image || '',
          badge: payload?.data?.sender_image || '',
        });
        toast.remove();
        if (payload?.data?.sender_name === undefined) {
          dispatch(updateNotificationCount());
          const newNotification = {
            body: payload?.notification?.body,
            data: payload?.data,
            notification_id: payload.messageId,
            timestamp: formatDate(new Date()),
            title: payload?.notification?.title,
          };
          dispatch(updateNotificationListItem(newNotification));
          toast.custom((t) => (
            <div
              className="common-cursor-pointer material-card1 p-2"
              style={{
                backgroundColor: '#F6F8FC',
                marginRight: 30,
                marginTop: 55,
                maxWidth: 500,
                minWidth: 300,
                borderRadius: 10,
              }}
              onClick={() => toast.dismiss(t.id)}>
              <div className="notifications-cross-axis-view justify-content-between">
                <div className="notifications-title-active1">{payload?.notification?.title}</div>
              </div>
              <div className="mt-1 notification-body1">{payload?.notification?.body}</div>
              <div className="notifications-timeStamp1">
                {formatDateDDMonthYYYY(formatDate(new Date()))}
              </div>
            </div>
          ));
        } else {
          toast.custom((t) => (
            <div
              className="common-cursor-pointer material-card1 p-2"
              style={{
                backgroundColor: '#F6F8FC',
                marginRight: 30,
                marginTop: 55,
                maxWidth: 500,
                minWidth: 300,
                borderRadius: 10,
              }}>
              <div className="notifications-cross-axis-view justify-content-between">
                <div className="notifications-title-active1">{payload?.notification?.title}</div>
              </div>
              <div className="mt-1 notification-body">{payload?.notification?.body}</div>
              <div className="notifications-timeStamp1">
                {formatDateDDMonthYYYY(formatDate(new Date()))}
              </div>
            </div>
          ));
        }

        notification.addEventListener('click', () => {
          notification.close();
          const data = payload.data;
          notificationListingNavigation(data, history);
        });
      });
    }
  }, []);

  const getDeviceId = () => {
    const storedId = localStorage.getItem('deviceId');
    if (storedId) {
      return storedId;
    }
    const newId = uuid();
    localStorage.setItem('deviceId', newId);
    return newId;
  };

  const updateFCMTokenAndDeviceId = async (deviceId, fcmToken, userId) => {
    const deviceRef = getNodeReference(FIREBASE_DB_NODES.USERS).child(userId).child('deviceIds');
    await deviceRef.once('value', (snapshot) => {
      const devices = snapshot.val();
      if (devices) {
        Object.keys(devices).forEach((deviceId) => {
          const device = devices[deviceId];
          if (device.device_type === 'web') {
            deviceRef
              .child(deviceId)
              .remove()
              .catch((error) => {
                console.error('Error deleting device:', error);
              });
          }
        });
      } else {
        console.log('No devices found.');
      }
    });
    await getNodeReference(FIREBASE_DB_NODES.USERS)
      .child(userId)
      .child('deviceIds')
      .child(deviceId)
      .set({
        fcm_token: fcmToken,
        device_type: 'web',
      });
  };

  const updateFCMTokenAndDeviceIdForBackend = async (fcmToken) => {
    const formData = new URLSearchParams();
    formData.append('notification_token', fcmToken);
    try {
      const apiResponse = await apiRequest(SET_NOTIFICATION_TOKEN, REQUEST_TYPE.POST, formData);
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.action === 'notification-clicked') {
          notificationListingNavigation(event.data.url, history);
        }
      });
    } else {
      console.log('Service Worker is not supported in this browser.');
    }
  }, []);
  useEffect(() => {
    if (employeeData && Object.keys(employeeData).length > 0) {
      let groupMap = {};
      const uid = uId(employeeData);
      if (chatEnabled && chatEnabled === true) {
        fetchUserGroupsById(uid, (isGroupExists, groups) => {
          if (isGroupExists) {
            fetchMyGroupsOnly(groups, (isExist, sGroupMap) => {
              groupMap = sGroupMap;
            });
          }
        });
      }
      generateToken().then((token) => {
        const deviceId = getDeviceId();
        const uid = uId(employeeData);
        if (token && uid) {
          updateFCMTokenAndDeviceId(deviceId, token, uid);
          updateFCMTokenAndDeviceIdForBackend(token);
        }
      });
      return () => {
        if (chatEnabled && chatEnabled === true) {
          getNodeReference(FIREBASE_DB_NODES.USERS).child(uid).child('group').off('value');
          Object.keys(groupMap).forEach((groupId) => {
            getNodeReference(FIREBASE_DB_NODES.GROUP).child(groupId).off('value');
          });
        }
      };
    }
  }, [chatEnabled, employeeData]);

  // const checkPublicProfile = () => {
  //   if (window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)) {
  //     const publicProfileId = window.location.href.split('/').pop();
  //     setPublicProfileId(publicProfileId);
  //     setShowPublicProfile(true);
  //   }
  // };

  const callbackAfterButtonPress = (callbackData) => {
    setAcknowledgementResponse(callbackData);
    setShowAcknowledgementResponseModal(true);
  };

  const getFirebaseData = useCallback(() => {
    let config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
      appId: process.env.REACT_APP_API_ID,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app();
    }
    firebase.analytics();
    const perf = firebase.performance();
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        getMasterdata('microland', (masterdata) => {
          if (masterdata) {
            dispatch(saveLamdaUrl(masterdata.configurations._api_lamda_url));
            dispatch(saveMasterdata(masterdata));
            // do not use generic tokens for api calls
            // dispatch(saveGenericToken(masterdata.generic_token));
            dispatch(saveBaseUrl(masterdata.configurations._api_base_url));
            // checkPublicProfile();
          } else {
            showToast(ERROR_MESSAGES.FIREBASE_DB_ERROR);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 'auth/operation-not-allowed') {
          console.log('Enable anonymous in your firebase console.');
        }
        showToast(ERROR_MESSAGES.UNAUTHORIZED_USER);
      });
  }, [dispatch]);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(saveIsFullScreenLoaderLoading(true));
    getFirebaseData();
  }, [dispatch, getFirebaseData]);

  useEffect(() => {
    if (baseUrl !== '') {
      dispatch({ type: ACTION_TYPES.GET_ACCESSIBILITY, payload: {} });
      getProfanityListData('microland', (profanityListData) => {
        if (profanityListData) {
          dispatch(saveProfanityList(profanityListData));
        }
      });
      // if (!window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)) {
      dispatch({ type: ACTION_TYPES.GET_MENU, payload: {} });
      dispatch({ type: ACTION_TYPES.GET_EMPLOYEE, payload: {} });
      dispatch({ type: ACTION_TYPES.GET_ACCESS, payload: {} });
      // }
      dispatch(saveOrgDomain(ORG_NAMES.MICROLAND));
    }
  }, [baseUrl, dispatch]);

  useEffect(() => {
    if (
      baseUrl !== '' &&
      Object.keys(menuData).length &&
      Object.keys(employeeData).length &&
      Object.keys(accessibilityData).length &&
      profanityList &&
      profanityList.length > 0
    ) {
      dispatch(saveIsFullScreenLoaderLoading(false));
      logEvent(ANALYTICS_EVENT_TYPES.SESSION_STARTED, '', ANALYTICS_ITEM_NAMES.SESSION_STARTED);
      setUserId();
    }
  }, [accessibilityData, baseUrl, dispatch, employeeData, menuData, profanityList]);

  useEffect(() => {
    if (Object.keys(employeeData).length) {
      if (employeeData?.complete_access_denial == 1) {
        setIsAccessDenied(true);
      } else {
        if (employeeData?.acknowledgement_data?.is_available) {
          setShowAcknowledgementModal(true);
          setAcknowledgementModalData(employeeData?.acknowledgement_data ?? {});
        } else {
          setShowAcknowledgementModal(false);
        }
      }
    }
  }, [employeeData]);

  return (
    <div>
      {/* {showPublicProfile && <PublicProfile publicProfileId={publicProfileId} />} */}
      <FullScreenLoader />
      {/* {baseUrl !== '' && !showPublicProfile && ( */}
      {baseUrl !== '' && (
        <div>
          {isAccessDenied ? (
            <AccessDeniedComponent />
          ) : (
            <>
              <MandatoryAcknwoledgementModal
                show={showAcknowledgementModal}
                content={acknowledgementModalData}
                setShow={setShowAcknowledgementModal}
                callbackOnButtonPress={callbackAfterButtonPress}
              />
              <AcknowledgementResponseModal
                setShowAcknowledgementResponseModal={setShowAcknowledgementResponseModal}
                acknowledgementResponse={acknowledgementResponse}
                showAcknowledgementResponseModal={showAcknowledgementResponseModal}
              />
              <ToastContainer position="bottom-center" autoClose={3000} />
              <Header />
              {showAttendanceModal && <AttendanceModal />}
              <StoreUnAuthorisedError />
              <DownloadAppPopup />
              <Navigation />
              <ProgressDialog />
              <Footer />
              <Toaster position={'top-right'} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
