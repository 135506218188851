import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMiMeetingTabsTitles } from '../../state/MasterData';
import MiMeetingHomeTab from './tabs/MiMeetingHomeTab';
import MeetingBookings from './tabs/MeetingBookings';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { saveMiMeetingTabData, selectMiMeetingTabData } from '../../state/TabData';
import { Tab } from 'react-bootstrap';

const MeetingRoomMainScreen = () => {
  const dispatch = useDispatch();
  const meetingTabs = useSelector(selectMiMeetingTabsTitles);
  const miMeetingTabData = useSelector(selectMiMeetingTabData);

  const renderTabs = (tab, index) => {
    switch (tab.key) {
      case 'book_a_room':
        return <MiMeetingHomeTab />;
      case 'my_bookings':
        return <MeetingBookings />;
    }
  };
  return (
    <div className="container">
      <SectionHeader title={'MiMEETING'} />
      <NavTabs
        activeKey={miMeetingTabData === '' ? 'book_a_room' : miMeetingTabData}
        onSelect={(k) => dispatch(saveMiMeetingTabData(k))}>
        {meetingTabs &&
          meetingTabs.map((tab, index) => {
            return (
              <Tab
                key={`${index}`}
                eventKey={tab.key}
                title={tab.title}
                className="main-content-container">
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
      </NavTabs>
    </div>
  );
};

export default MeetingRoomMainScreen;
