import {
  ACCESSIBILITY_IDENTIFIERS,
  DIALOG_TYPE,
  FEATURE_TYPE,
  firebaseDataPayload,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_LIKE_TYPES,
  PODCAST_TAB_IDENTIFIERS,
  SCREEN_TITLE,
  WISH_TYPES,
} from '../common/constants/AppConstants';
import { getCurrentYear } from '../utils/common';

export const pushNotificationNavigation = (data, history) => {
  if (Object.keys(data).length > 0) {
    if (!data.action || data.action === null || data.action === '') {
      switch (data.reference) {
        case firebaseDataPayload.NOTIFICATION:
          history.push(NAVIGATION_ROUTES.NOTIFICATION_LISTING, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.PASSWORD_RESET_SUCCESS:
          history.push(NAVIGATION_ROUTES.NOTIFICATION_LISTING, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ASKML:
          break;
        case firebaseDataPayload.STARS:
          history.push(NAVIGATION_ROUTES.SOM_MAIN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CELEBRATIONS:
          history.push(NAVIGATION_ROUTES.CELEBRATIONS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.COMMUNITIES:
          history.push(NAVIGATION_ROUTES.COMMUNITIES, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.COMMUNITIES_POST:
          history.push(`${NAVIGATION_ROUTES.POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CONTEST:
          history.push(NAVIGATION_ROUTES.CONTESTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ARTICLES:
          history.push(NAVIGATION_ROUTES.DEAR_EMPLOYER, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.EMPLOYEES:
          history.push(NAVIGATION_ROUTES.DIRECTORY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.EVENT:
          history.push(NAVIGATION_ROUTES.EVENTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.DOWNLOADABLES:
          history.push(NAVIGATION_ROUTES.ECARDS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.FACILITIES:
          history.push(NAVIGATION_ROUTES.FACILITY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.WALLPOSTS:
          history.push(NAVIGATION_ROUTES.THE_WALL, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.IJPS:
          history.push(NAVIGATION_ROUTES.IJPR, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.NEWS:
          history.push(NAVIGATION_ROUTES.NEWS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ANNOUCNEMENTS_LIST:
          history.push(NAVIGATION_ROUTES.BROADCASTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GCDO_ANNOUCNEMENTS:
          history.push(NAVIGATION_ROUTES.GCDO, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MICROBIZ:
          history.push(NAVIGATION_ROUTES.MICROBIZ, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BTO:
          history.navigate(NAVIGATION_ROUTES.BTO, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.POLICIES:
          history.push(NAVIGATION_ROUTES.POLICIES, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.POLLS:
          history.push(NAVIGATION_ROUTES.POLLS_AND_SURVEYS, {
            tabKey: 'polls',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.SURVEYS:
          history.push(NAVIGATION_ROUTES.POLLS_AND_SURVEYS, {
            tabKey: 'surveys',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CMDCHAT:
          history.push(NAVIGATION_ROUTES.CEO_CHAT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CUSTOMER_ACCOLADES:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER, {
            tabKey: 'accolades',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CUSTOMER_WINS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER, {
            tabKey: 'wins',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_CLIENT_VISITS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'clientvisits',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_EVENTS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'events',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_STARS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'stars',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_FACILITIES:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'facilities',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_MICROGIVE:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'microgive',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_COMMUNITIES:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'communities',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_CONTESTS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'contests',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_LEARNING:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'learning',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ML30:
          break;
        case firebaseDataPayload.DNI_ANNOUCNEMENTS:
          history.push(NAVIGATION_ROUTES.DI, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.DNI_PLEDGE:
          history.push(NAVIGATION_ROUTES.DI, {
            tabKey: 'pledge',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COVID_SUPPORT:
          history.push(NAVIGATION_ROUTES.PANDEMIC_SUPPORT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.TRAVEL:
          history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.PODCASTS:
          history.push(NAVIGATION_ROUTES.PODCASTS, {
            tabKey: PODCAST_TAB_IDENTIFIERS.CHANNELS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.DASHBOARD:
          history.push(NAVIGATION_ROUTES.HOME, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MICRO_LAW:
          history.push(NAVIGATION_ROUTES.MICRO_LAW, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICRO_LAW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MONDAY_ANNOUNCEMENTS:
          history.push(NAVIGATION_ROUTES.ML_MONDAY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.NEW_JOINEES:
          history.push(NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS, {
            tabkey: 'checklists',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ESTORE:
          history.push(NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        default:
          break;
      }
    } else {
      switch (data.action) {
        case firebaseDataPayload.TOPIC_NEWS:
          history.push(`${NAVIGATION_ROUTES.NEWS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.REQUEST:
          break;
        case firebaseDataPayload.PASSWORD_RESET:
          switch (data.reference) {
            case firebaseDataPayload.PASSWORD_EXPIRED:
              break;
            case firebaseDataPayload.PASSWORD_RESET_FAILED:
              break;
            case firebaseDataPayload.PASSWORD_REMAINDER:
              break;
          }
          break;
        case firebaseDataPayload.BROADCAST:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GCDO:
          history.push(`${NAVIGATION_ROUTES.GCDO_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ERP_MICROBIZ:
          history.push(`${NAVIGATION_ROUTES.MICROBIZ_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BTO_ACTION:
          history.push(`${NAVIGATION_ROUTES.BTO_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MICROKNOW:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICROKNOW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MICRO_LAW_ACTION:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICRO_LAW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.STARS:
          history.push(`${NAVIGATION_ROUTES.SOM_MAIN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.COMMENTS:
          history.push(NAVIGATION_ROUTES.COMMENTS, {
            customerId: data.id,
            type: FEATURE_TYPE.STARS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GALLERY_TAG:
          history.push(`${NAVIGATION_ROUTES.SOM_GALLERY}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BREAKOUT_SESSION:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'agenda',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BREAKOUT_UPDATE:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'updates',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BREAKOUT_DISCUSSION:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'discussion',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GRAFFITI:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.GRAFITTIS:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.POLICY:
          history.push(`${NAVIGATION_ROUTES.POLICIES}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.FACILITY:
          history.push(`${NAVIGATION_ROUTES.FACILITY}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ACCOLADES:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'accolade',
            parentName: 'ACCOLADES',
            apiType: 'accolade',
            type: 'accolades',
            likeType: 'CAC',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.TESTIMONIALS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'testimonial',
            parentName: 'TESTIMONIALS',
            apiType: 'testimonial',
            type: 'testimonials',
            likeType: 'CT',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.WINS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'wins',
            parentName: 'WINS',
            apiType: 'win',
            type: 'wins',
            likeType: 'CW',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.VISITS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'visits',
            parentName: 'VISITS',
            apiType: 'visit',
            type: 'visits',
            likeType: 'CV',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.KNOW:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN, {
            customerId: data.id,
            dataType: 'know',
            parentName: 'KNOW',
            apiType: 'know',
            type: 'know',
            likeType: 'CK',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CHEER_CENTRAL:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            type: 'reward',
          });
          break;
        case firebaseDataPayload.CHEER_NOMINATOR:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
            type: 'listNominators',
            apiType: 'nominators',
          });
          break;
        case firebaseDataPayload.CHEER_RECIPIENT:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
            type: 'listRecipients',
            apiType: 'recipients',
          });
          break;
        case firebaseDataPayload.WALLPOST:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.TOPIC_ANNOUNCEMENTS:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.MICROGIVE_FEEDBACK:
          history.push(NAVIGATION_ROUTES.MICROGIVE_FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
          });
          break;
        case firebaseDataPayload.EVENTS:
          history.push(`${NAVIGATION_ROUTES.EVENTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.EVENT_FEEDBACK:
          history.push(NAVIGATION_ROUTES.FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
            type: 'event',
          });
          break;
        case firebaseDataPayload.CONTESTS:
          history.push(`${NAVIGATION_ROUTES.CONTESTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.CONTEST_FEEDBACK:
          history.push(NAVIGATION_ROUTES.FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
            type: 'contest',
          });
          break;
        case firebaseDataPayload.IJP:
          history.push(NAVIGATION_ROUTES.IJP_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BIRTHDAY:
          {
            const year = getCurrentYear();
            history.push(
              `${NAVIGATION_ROUTES.CELEBRATIONS}/${WISH_TYPES.BIRTHDAY}/${data.id}/${year}`,
              {
                fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
              }
            );
          }
          break;
        case firebaseDataPayload.PROFILEPIC:
          history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ANNIVERSARY:
          {
            const year = getCurrentYear();
            history.push(
              `${NAVIGATION_ROUTES.CELEBRATIONS}/${WISH_TYPES.ANNIVERSARY}/${data.id}/${year}`,
              {
                fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
              }
            );
          }
          break;
        case firebaseDataPayload.ADVANCEMENT:
          {
            const year = getCurrentYear();
            history.push(
              `${NAVIGATION_ROUTES.CELEBRATIONS}/${WISH_TYPES.ADVANCEMENT}/${data.id}/${year}`,
              {
                fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
              }
            );
          }
          break;
        case firebaseDataPayload.TOPIC_COMMUNITIES:
          history.push(`${NAVIGATION_ROUTES.COMMUNITIES_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.TOPIC_COMMUNITIES_POST:
          history.push(`${NAVIGATION_ROUTES.POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.QUESTION_COMMENT:
          history.push(NAVIGATION_ROUTES.COMMENTS, {
            referId: data.id,
            likeType: FEATURE_TYPE.CEO_CHAT,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.SURVEY:
          history.push(`${NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.POLL:
          history.push(`${NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.QUESTION:
          history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.DNI:
          history.push(`${NAVIGATION_ROUTES.DI}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.COVID_ACTION:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.TREQUEST:
          history.push(`${NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.PODCAST:
          history.push(`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/${data.id}`, {
            isPlay: false,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ML_MONDAY:
          history.push(`${NAVIGATION_ROUTES.ML_MONDAY_CONTENT}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.BREAKOUT_ANSWERS:
          history.push(
            `${NAVIGATION_ROUTES.SESSION_DETAILS}/${data.session_id}/${data.speaker_id}/${data.question_id}`,
            {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
              screenIdetifier: ACCESSIBILITY_IDENTIFIERS.BREAKOUTS,
            }
          );
          break;
        case firebaseDataPayload.COVID_SUPPORT:
          history.push(NAVIGATION_ROUTES.PANDEMIC_SUPPORT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ESTORE_HOME:
          history.push(NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_DESK:
          history.push(NAVIGATION_ROUTES.SEAT_BOOKING_MAIN_SCREEN, {});
          break;
        case firebaseDataPayload.MI_PARKING:
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
          break;
        case firebaseDataPayload.MI_MEETING:
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
          break;
        case firebaseDataPayload.DOWNLOADABLE:
          history.push(NAVIGATION_ROUTES.ECARDS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            tabKey: data.id,
          });
          break;
        default:
          break;
      }
    }
  }
};

export const notificationListingNavigation = (data, history) => {
  if (Object.keys(data).length > 0) {
    if (!data.action || data.action === null || data.action === '') {
      switch (data.reference) {
        case firebaseDataPayload.ASKML:
          break;
        case firebaseDataPayload.STARS:
          history.push(NAVIGATION_ROUTES.SOM_MAIN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CELEBRATIONS:
          history.push(NAVIGATION_ROUTES.CELEBRATIONS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COMMUNITIES:
          history.push(NAVIGATION_ROUTES.COMMUNITIES, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COMMUNITIES_POST:
          history.push(`${NAVIGATION_ROUTES.POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CONTEST:
          history.push(NAVIGATION_ROUTES.CONTESTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ARTICLES:
          history.push(NAVIGATION_ROUTES.DEAR_EMPLOYER, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.EMPLOYEES:
          history.push(NAVIGATION_ROUTES.DIRECTORY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.EVENT:
          history.push(NAVIGATION_ROUTES.EVENTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.DOWNLOADABLES:
          history.push(NAVIGATION_ROUTES.ECARDS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.FACILITIES:
          history.push(NAVIGATION_ROUTES.FACILITY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.WALLPOSTS:
          history.push(NAVIGATION_ROUTES.THE_WALL, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.IJPS:
          history.push(NAVIGATION_ROUTES.IJPR, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.NEWS:
          history.push(NAVIGATION_ROUTES.NEWS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ANNOUCNEMENTS_LIST:
          history.push(NAVIGATION_ROUTES.BROADCASTS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GCDO_ANNOUCNEMENTS:
          history.push(NAVIGATION_ROUTES.GCDO, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MICROKNOW_REF:
          history.push(NAVIGATION_ROUTES.MICROKNOW, {
            headerTitle: SCREEN_TITLE.MICROKNOW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ESG_REF:
          history.push(NAVIGATION_ROUTES.ESG, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            screenIdetifier: SCREEN_TITLE.ESG,
          });
          break;
        case firebaseDataPayload.MLF_REF:
          history.push(NAVIGATION_ROUTES.MLF, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            screenIdetifier: SCREEN_TITLE.MLF,
          });
          break;
        case firebaseDataPayload.MICROBIZ:
          history.push(NAVIGATION_ROUTES.MICROBIZ, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BTO:
          history.push(NAVIGATION_ROUTES.BTO, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.POLICIES:
          history.push(NAVIGATION_ROUTES.POLICIES, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.POLLS:
          history.push(NAVIGATION_ROUTES.POLLS_AND_SURVEYS, {
            tabKey: 'polls',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.SURVEYS:
          history.push(NAVIGATION_ROUTES.POLLS_AND_SURVEYS, {
            tabKey: 'surveys',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CMDCHAT:
          history.push(NAVIGATION_ROUTES.CEO_CHAT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CUSTOMER_ACCOLADES:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER, {
            tabKey: 'accolades',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CUSTOMER_WINS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER, {
            tabKey: 'wins',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_CLIENT_VISITS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'clientvisits',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_EVENTS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'events',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_STARS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'stars',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_FACILITIES:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'facilities',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_MICROGIVE:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'microgive',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_COMMUNITIES:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'communities',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_CONTESTS:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'contests',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_LEARNING:
          history.push(NAVIGATION_ROUTES.GALLERY, {
            tabKey: 'learning',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ML30:
          break;
        case firebaseDataPayload.DNI_ANNOUCNEMENTS:
          history.push(NAVIGATION_ROUTES.DI, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.DNI_PLEDGE:
          history.push(NAVIGATION_ROUTES.DI, {
            tabKey: 'pledge',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COVID_SUPPORT:
          history.push(NAVIGATION_ROUTES.PANDEMIC_SUPPORT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.TRAVEL:
          history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.PODCASTS:
          history.push(NAVIGATION_ROUTES.PODCASTS, {
            tabKey: PODCAST_TAB_IDENTIFIERS.CHANNELS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.DASHBOARD:
          history.push(NAVIGATION_ROUTES.HOME, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_HR_REF:
          history.push(NAVIGATION_ROUTES.MI_HR_SUPPORT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MONDAY_ANNOUNCEMENTS:
          history.push(NAVIGATION_ROUTES.ML_MONDAY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ML_DAY_33:
          history.push(
            `${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER}`,
            {
              identifier: ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER,
              tabKey: 'central',
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            }
          );
          break;
        case firebaseDataPayload.ML_DAY_34:
          history.push(
            `${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER}`,
            {
              identifier: ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER,
              tabKey: 'central',
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            }
          );
          break;
        case firebaseDataPayload.ML_DAY_35:
          history.push(
            `${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER}`,
            {
              identifier: ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER,
              tabKey: 'central',
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            }
          );
          break;
        case firebaseDataPayload.NEW_JOINEES:
          history.push(NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS, {
            tabkey: 'checklists',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.EMERGENCY_PLAYBOOKS:
          history.push(NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_AUCTION:
          if (data.id) {
            history.push(`${NAVIGATION_ROUTES.MI_AUCTION}/${data.id}`, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            });
          } else {
            history.push(NAVIGATION_ROUTES.MI_AUCTION, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            });
          }
          break;
        case firebaseDataPayload.MLTV:
          history.push(`${NAVIGATION_ROUTES.MLTV}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CUSTOMERS_RESEARCHERS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT, {
            customerId: data.id,
            dataType: OUR_CUSTOMERS_DATA_TYPES.researches,
            parentName: 'MICROVIEW',
            apiType: OUR_CUSTOMERS_API_TYPES.researches,
            type: 'researches',
            likeType: OUR_CUSTOMERS_LIKE_TYPES.researches,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ESTORE:
          history.push(NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_DESK:
          history.push(NAVIGATION_ROUTES.SEAT_BOOKING_MAIN_SCREEN, {});
          break;
        case firebaseDataPayload.MI_PARKING:
          history.push(NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN, {});
          break;
        case firebaseDataPayload.MI_MEETING:
          history.push(NAVIGATION_ROUTES.MIMEETING_MAIN_SCREEN, {});
          break;
        default:
          break;
      }
    } else {
      switch (data.action) {
        case firebaseDataPayload.TOPIC_NEWS:
          history.push(`${NAVIGATION_ROUTES.NEWS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.REQUEST:
          break;
        case firebaseDataPayload.PASSWORD_RESET:
          switch (data.reference) {
            case firebaseDataPayload.PASSWORD_EXPIRED:
              break;
            case firebaseDataPayload.PASSWORD_RESET_FAILED:
              break;
            case firebaseDataPayload.PASSWORD_REMAINDER:
              break;
          }
          break;
        case firebaseDataPayload.BROADCAST:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MLTV_ACTION:
          history.push(`${NAVIGATION_ROUTES.MLTV}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_HR:
          history.push(`${NAVIGATION_ROUTES.MI_HR_SUPPORT}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GCDO:
          history.push(`${NAVIGATION_ROUTES.GCDO_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ERP_MICROBIZ:
          history.push(`${NAVIGATION_ROUTES.MICROBIZ_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BTO_ACTION:
          history.push(`${NAVIGATION_ROUTES.BTO_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MICROKNOW_REF:
          history.push(NAVIGATION_ROUTES.MICROKNOW, {
            headerTitle: SCREEN_TITLE.MICROKNOW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.WOMENS_DAY_REF:
          history.push(NAVIGATION_ROUTES.WOMENS_DAY, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            screenIdetifier: SCREEN_TITLE.WOMENS_DAY,
          });
          break;
        case firebaseDataPayload.MICROKNOW:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICROKNOW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MICRO_LAW:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICRO_LAW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MICRO_LAW_ACTION:
          history.push(`${NAVIGATION_ROUTES.BROADCASTS}/${data.id}`, {
            headerTitle: ACCESSIBILITY_IDENTIFIERS.MICRO_LAW,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.STARS:
          history.push(`${NAVIGATION_ROUTES.SOM_MAIN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COMMENTS:
          history.push(NAVIGATION_ROUTES.COMMENTS, {
            referId: data.id,
            likeType: FEATURE_TYPE.STARS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GALLERY_TAG:
          history.push(`${NAVIGATION_ROUTES.SOM_GALLERY}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BREAKOUT_SESSION:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'agenda',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BREAKOUT_UPDATE:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'updates',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BREAKOUT_DISCUSSION:
          history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${data.id}`, {
            tabKey: 'discussion',
            title: SCREEN_TITLE.BREAKOUTS,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GRAFFITI:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GRAFITTIS:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.POLICY:
          history.push(`${NAVIGATION_ROUTES.POLICIES}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.FACILITY:
          history.push(`${NAVIGATION_ROUTES.FACILITY}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ACCOLADES:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'accolade',
            parentName: 'ACCOLADES',
            apiType: 'accolade',
            type: 'accolades',
            likeType: 'CAC',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.TESTIMONIALS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'testimonial',
            parentName: 'TESTIMONIALS',
            apiType: 'testimonial',
            type: 'testimonials',
            likeType: 'CT',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.WINS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'wins',
            parentName: 'WINS',
            apiType: 'win',
            type: 'wins',
            likeType: 'CW',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.VISITS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
            customerId: data.id,
            dataType: 'visits',
            parentName: 'VISITS',
            apiType: 'visit',
            type: 'visits',
            likeType: 'CV',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.KNOW:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN, {
            customerId: data.id,
            dataType: 'know',
            parentName: 'KNOW',
            apiType: 'know',
            type: 'know',
            likeType: 'CK',
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CHEER_CENTRAL:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            type: 'reward',
          });
          break;
        case firebaseDataPayload.CHEER_NOMINATOR:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
            type: 'listNominators',
            apiType: 'nominators',
          });
          break;
        case firebaseDataPayload.CHEER_RECIPIENT:
          history.push(NAVIGATION_ROUTES.CHEERS_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
            type: 'listRecipients',
            apiType: 'recipients',
          });
          break;
        case firebaseDataPayload.WALLPOST:
          history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.TOPIC_ANNOUNCEMENTS:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MICROGIVE_FEEDBACK:
          history.push(NAVIGATION_ROUTES.MICROGIVE_FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
          });
          break;
        case firebaseDataPayload.EVENTS:
          history.push(`${NAVIGATION_ROUTES.EVENTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.EVENT_FEEDBACK:
          history.push(NAVIGATION_ROUTES.FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
            type: 'event',
          });
          break;
        case firebaseDataPayload.CONTESTS:
          history.push(`${NAVIGATION_ROUTES.CONTESTS}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.CONTEST_FEEDBACK:
          history.push(NAVIGATION_ROUTES.FEEDBACK_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
            event_id: data.id,
            type: 'contest',
          });
          break;
        case firebaseDataPayload.IJP:
          history.push(NAVIGATION_ROUTES.IJP_DETAIL, {
            id: data.id,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.BIRTHDAY:
          {
            const year = getCurrentYear();
            history.push(NAVIGATION_ROUTES.CELEBRATION_WISHES, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
              celebrationDetails: { type: WISH_TYPES.BIRTHDAY, post_id: data.id },
              year,
            });
          }
          break;
        case firebaseDataPayload.PROFILEPIC:
          history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ANNIVERSARY:
          {
            const year = getCurrentYear();
            history.push(NAVIGATION_ROUTES.CELEBRATION_WISHES, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
              celebrationDetails: { type: WISH_TYPES.ANNIVERSARY, post_id: data.id },
              year,
            });
          }
          break;
        case firebaseDataPayload.ADVANCEMENT:
          {
            const year = getCurrentYear();
            history.push(NAVIGATION_ROUTES.CELEBRATION_WISHES, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
              celebrationDetails: { type: WISH_TYPES.ADVANCEMENT, post_id: data.id },
              year,
            });
          }
          break;
        case firebaseDataPayload.TOPIC_COMMUNITIES:
          history.push(`${NAVIGATION_ROUTES.COMMUNITIES_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.TOPIC_COMMUNITIES_POST:
          history.push(`${NAVIGATION_ROUTES.POST_DETAIL}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.QUESTION_COMMENT:
          history.push(NAVIGATION_ROUTES.COMMENTS, {
            referId: data.id,
            likeType: FEATURE_TYPE.CEO_CHAT,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.SURVEY:
          history.push(`${NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.POLL:
          history.push(`${NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.QUESTION:
          history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.DNI:
          history.push(`${NAVIGATION_ROUTES.DI}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.COVID_ACTION:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.TREQUEST:
          history.push(`${NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.PODCAST:
          history.push(`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/${data.id}`, {
            isPlay: false,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ML_MONDAY:
          history.push(`${NAVIGATION_ROUTES.ML_MONDAY_CONTENT}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION,
          });
          break;
        case firebaseDataPayload.ML_DAY_33_ACTION:
          history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ML_DAY_34_ACTION:
          history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ML_DAY_35_ACTION:
          history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.WOMENS_DAY:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            screenIdetifier: ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY,
          });
          break;
        case firebaseDataPayload.ESG:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            screenIdetifier: ACCESSIBILITY_IDENTIFIERS.ESG,
          });
          break;
        case firebaseDataPayload.MLF:
          history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${data.id}`, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            screenIdetifier: ACCESSIBILITY_IDENTIFIERS.MLF,
          });
          break;
        case firebaseDataPayload.BREAKOUT_ANSWERS:
          history.push(
            `${NAVIGATION_ROUTES.SESSION_DETAILS}/${data.session_id}/${data.speaker_id}/${data.question_id}`,
            {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
              screenIdetifier: ACCESSIBILITY_IDENTIFIERS.BREAKOUTS,
            }
          );
          break;
        case firebaseDataPayload.COVID_SUPPORT:
          history.push(NAVIGATION_ROUTES.PANDEMIC_SUPPORT, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.MI_AUCTION_ACTION:
          if (data.id) {
            history.push(`${NAVIGATION_ROUTES.MI_AUCTION}/${data.id}`, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            });
          } else {
            history.push(NAVIGATION_ROUTES.MI_AUCTION, {
              fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            });
          }
          break;
        case firebaseDataPayload.RESEARCHERS:
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT, {
            customerId: data.id,
            dataType: OUR_CUSTOMERS_DATA_TYPES.researches,
            parentName: 'MICROVIEW',
            apiType: OUR_CUSTOMERS_API_TYPES.researches,
            type: 'researches',
            likeType: OUR_CUSTOMERS_LIKE_TYPES.researches,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ESTORE_HOME:
          history.push(NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.ONE_ON_ONE_CHAT:
          history.push(NAVIGATION_ROUTES.CHAT, {
            data: data,
            chatType: DIALOG_TYPE.ONE_ON_ONE_CHAT,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.GROUP_CHAT:
          history.push(NAVIGATION_ROUTES.CHAT, {
            data: data,
            chatType: DIALOG_TYPE.GROUP_CHAT,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.FOLLOW_GROUP:
          history.push(NAVIGATION_ROUTES.CHAT, {
            data: data,
            chatType: DIALOG_TYPE.GROUP_CHAT,
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
          });
          break;
        case firebaseDataPayload.DOWNLOADABLE:
          history.push(NAVIGATION_ROUTES.ECARDS, {
            fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
            tabKey: data.id,
          });
          break;
        default:
          break;
      }
    }
  }
};
