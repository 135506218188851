import React, { useState } from 'react';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../utils/common';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';

const AnswerView = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [textString, setTextString] = useState(
    props.question.length <= 256 ? props.question : props.question.substring(0, 256) + '...'
  );
  const [textShown, setTextShown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const employeeData = useSelector(selectEmployeeData);
  const loggedInEmployeeId = employeeData.employee_id;

  const toggleReadFlag = () => {
    if (textShown) {
      setTextShown(false);
      setTextString(props.question.substring(0, 256) + '...');
    } else {
      setTextShown(true);
      setTextString(props.question);
    }
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  return (
    <div>
      <div className="ceo-chat-answer-row ceo-chat-answer-row-container">
        <div className="ceo-chat-answer-row w-100">
          <div>
            <UserProfilePhoto
              imageBaseUrl={props.baseUrl}
              imagePath={props.imagePath}
              employeeId={props.employeeId}
              imageClass={
                props.isQuestion ? 'common-user-profile-photo-sm' : 'common-user-profile-photo-xs'
              }
            />
          </div>
          <div className="ceo-chat-employee-details w-100">
            <div className="d-flex flex-row justify-content-between align-items-start w-100">
              <div>
                <p className="ceo-chat-question-emp-name-text mb-0">{props.employeeName}</p>
                <p className="ceo-chat-question-emp-pronoun-text mb-0">{props.position}</p>
                <p className="ceo-chat-question-emp-pronoun-text mb-0">{props.location}</p>
              </div>
              <div className="d-flex flex-row align-items-center">
                <div className="ceo-chat-ques-emp-date-text">
                  {isToday(props.askedOn)
                    ? formatTimeAMPM(props.askedOn)
                    : formatDateDDMonthYYYY(props.askedOn)}
                </div>
                {!props?.isQuestion && loggedInEmployeeId === props.employeeId && !isEdit && (
                  <div
                    className="common-cursor-pointer"
                    onClick={() => {
                      setIsEdit(true);
                      history.push(NAVIGATION_ROUTES.CEO_TAG_EMPLOYEE, {
                        questionId: props.questionId,
                        isCmd: props.isCmd,
                        isEdit: true,
                        taggedEmp: props?.taggedEmployees,
                        answerText: props.question,
                        answerId: props.answerId,
                      });
                    }}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.HOME_EDIT_FAVORITES,
                        ACCESSIBILITY_IDENTIFIERS.HOME
                      )}
                      className="chat-group-detail-edit ml-2 common-cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ceo-chat-question-text">
        <HtmlView html={textString} onClick={handleHtmlClicks} />
        <div className="d-flex flex-row align-items-center justify-content-between w-100">
          {props.question.length > 256 ? (
            <span
              className="common-cursor-pointer mt-2 ceo-chat-read-style"
              onClick={() => {
                toggleReadFlag();
              }}>
              {textShown ? 'Read Less' : 'Read More'}
            </span>
          ) : (
            <div />
          )}
          {props.isCmd &&
            props.isQuestion &&
            (props.isPublic ? (
              <div
                onClick={(e) => {
                  props.isCmd && props.onSelectChangeStatus();
                  e.stopPropagation();
                }}
                className="ceo-chat-public-private-view">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.PUBLIC_QUESTION,
                    ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                  )}
                  style={{ height: 8, width: 14.5, marginRight: 5, alignSelf: 'center' }}
                />
                <div className="ceo-chat-public">Public ▼</div>
              </div>
            ) : (
              <div
                onClick={(e) => {
                  props.isCmd && props.onSelectChangeStatus();
                  e.stopPropagation();
                }}
                className="ceo-chat-public-private-view">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.PRIVATE_QUESTION,
                    ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                  )}
                  style={{ height: 8, width: 14.5, marginRight: 5 }}
                />
                <div className="ceo-chat-private">Private ▼</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default AnswerView;
