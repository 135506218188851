import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { GET_ASSIGNED_PROJECTS, SUBMIT_NOMINATON } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { getAwardIcon, removeHtmlContent } from '../../utils/common';
import AllocatePointPeer from './components/AllocatePointPeer';
import MyManagerListItem from './components/MyManagerListItem';
import { Form, Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectCitationCharLimit } from '../../state/MasterData';
import Select from 'react-select';

const ViewPeerAwardScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { selectedItem, awardKey, awardType, selectedEmployee, isIndividual } = location.state;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [buttonIsDisable, setButtonIsDisable] = useState(true);
  const [allocatedEmployeesList, setAllocatedEmployeesList] = useState([]);
  const [showAllocatedView, setShowAllocatedView] = useState(false);
  const [showAllocatedPeerView, setShowAllocatedPeerView] = useState(false);
  const [isPointsIndividually, setIsPointsIndividually] = useState(false);
  const [isPointsEqually, setIsPointsEqually] = useState(false);
  const [points, setPoints] = useState();
  const [iconName] = useState(getAwardIcon(selectedItem.icon_name));
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [peerPoints] = useState([{ points: 100 }, { points: 200 }, { points: 500 }]);
  const [citationId, setCitationId] = useState();
  const [citation, setCitation] = useState('');
  const citationLimit = useSelector(selectCitationCharLimit);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VIEW_PEER_AWARD_SCREEN,
      NAVIGATION_ROUTES.VIEW_PEER_AWARD_SCREEN
    );
  }, []);

  const callAssignedProjects = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_ASSIGNED_PROJECTS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (apiResponse?.response?.status) {
        const data = apiResponse?.response?.data ?? [];
        let pickerData = [];
        data.forEach((element) => {
          pickerData.push({
            label: element.project_title,
            value: element.project_code,
          });
        });
        setProjects(pickerData);
        if (pickerData.length === 1) {
          setSelectedProject(pickerData[0]);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressing: false }));
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    callAssignedProjects();
  }, [callAssignedProjects]);

  const callSubmitNominationApi = async (awardTo) => {
    let empsId;
    const params = new URLSearchParams();
    selectedEmployee.forEach((element, index) => {
      if (index === 0) {
        empsId = element.empId;
      } else {
        empsId = empsId + ',' + element.empId;
      }
    });
    if (isPointsEqually) {
      selectedEmployee.forEach((element, index) => {
        params.append('extra_points[' + index + '][employee_id]', element.empId);
        params.append('extra_points[' + index + '][points]', points);
      });
    } else if (isPointsIndividually) {
      allocatedEmployeesList.forEach((element, index) => {
        params.append('extra_points[' + index + '][employee_id]', element.empId);
        params.append('extra_points[' + index + '][points]', element.points);
      });
    }

    params.append('type', 'peer');
    params.append('employees', empsId);
    params.append('award_to', awardTo);
    params.append('award_key', awardKey);
    params.append('citation_id', citationId);
    params.append('citation_text', citation);
    selectedProject?.value && params.append('project_code', selectedProject.value);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.SUBMIT_NOMINATIONS,
        })
      );
      const apiResponse = await apiRequest(SUBMIT_NOMINATON, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setShowSuccessDialog(true);
        }
      }
    } catch (err) {
      handleError(err, params, SUBMIT_NOMINATON, NAVIGATION_ROUTES.VIEW_PEER_AWARD_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSubmitNominations = () => {
    if (isIndividual) {
      callSubmitNominationApi('IN');
    } else {
      callSubmitNominationApi('TE');
    }
  };

  const renderPeerPoints = (item) => {
    return (
      <div>
        {points === item.points ? (
          <CustomButton buttonStyle="cheers-selected-button-style">{item.points}</CustomButton>
        ) : (
          <CustomButton
            buttonStyle="cheers-not-selected-button-style"
            onClick={() => {
              setIsPointsIndividually(false);
              setIsPointsEqually(true);
              setPoints(item.points);
              setAllocatedEmployeesList([]);
            }}>
            {item.points}
          </CustomButton>
        )}
      </div>
    );
  };

  const renderEmployeesList = (item) => {
    return (
      <div className="mr-3 cheers-employee-view">
        <div className="text-center cheers-my-team-name">{item.name}</div>
        <div className="text-center cheers-my-team-name">{item.points}</div>
      </div>
    );
  };

  const onPressSelectPoints = (allocatedEmployee) => {
    setShowAllocatedPeerView(false);
    setAllocatedEmployeesList(allocatedEmployee);
    setShowAllocatedView(true);
    setIsPointsIndividually(true);
    setIsPointsEqually(false);
    setPoints();
  };

  const renderBottomView = () => {
    return (
      <div>
        <Form.Group style={{ width: '50%', marginTop: 10 }}>
          <Form.Label className="cheers-select-option-text">Select project*</Form.Label>
          <Select
            className="travel-request-dropdown-style"
            placeholder="Select Your Project"
            isSearchable={!!projects.length}
            options={projects}
            value={selectedProject}
            onChange={(selectedOption) => {
              setSelectedProject(selectedOption);
            }}
          />
        </Form.Group>
        <div className="mt-2 cheers-award-extra">Award Extra Points Equally</div>
        <div className="d-flex flex-row align-items-center w-50  mt-3">
          <div className="cheers-team-micro-view w-50 justify-content-between">
            {peerPoints.length > 0 &&
              peerPoints.map((data, index) => renderPeerPoints(data, index))}
          </div>
          <div className="cheers-award-extra pr-3 pl-3">OR</div>
          <div
            className="allocate-points-individually"
            onClick={() => {
              setShowAllocatedPeerView(true);
            }}>
            Allocate Points Individually
          </div>
        </div>
        <div className="w-50">
          {showAllocatedPeerView && (
            <AllocatePointPeer
              selectedEmployee={selectedEmployee}
              onPressSelectPoints={onPressSelectPoints}
            />
          )}
          {showAllocatedView && (
            <div>
              {allocatedEmployeesList.length > 0 && (
                <div className="mt-3 cheers-points-individually">
                  Points Individually Allocated To
                </div>
              )}
              <div className="d-flex mt-3 mb-2">
                {allocatedEmployeesList.length > 0 &&
                  allocatedEmployeesList.map((data, index) => renderEmployeesList(data, index))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const onRadioButtonPressed = (item, index) => {
    setCitationId(item.citation_id);
    setSelectedIndex(index);
    setButtonIsDisable(citation === '');
  };

  const renderFilters = (data, index) => {
    return (
      <MyManagerListItem
        item={data}
        onRadioButtonPressed={onRadioButtonPressed}
        key={`${index}`}
        index={index}
        selectedIndex={selectedIndex}
      />
    );
  };

  const handleCitation = (citationText) => {
    setCitation(citationText);
    setButtonIsDisable(citationId == undefined || citationId === null || citationText === '');
  };

  const onPressNominateAgain = () => {
    setShowSuccessDialog(false);
    history.go(-2);
  };

  const onPressCheersHome = () => {
    setShowSuccessDialog(false);
    history.go(-3);
  };

  const onPressBackDrop = () => {
    setShowSuccessDialog(false);
    history.go(-3);
  };

  const successDialog = () => {
    return (
      <div>
        <div className="cheers-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-success-icon"
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">Nomination Submitted</div>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressNominateAgain}>
          Nominate Again
        </CustomButton>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressCheersHome}>
          Cheers Home
        </CustomButton>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showSuccessDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={onPressBackDrop}>
          <Modal.Body>{successDialog()}</Modal.Body>
        </Modal>
        <div className="d-flex">
          <div className="cheers-nomination-type">Nomination Type:</div>
          <div className="ml-1 cheers-nomination-text">Peer</div>
        </div>
        <div className="d-flex">
          <div className="text-center mt-2 mr-3 cheers-award-type">{selectedItem.award_type}</div>
          <div className="text-center mt-2 ml-3 cheers-award-type">
            {selectedItem.award_frequency === 'Monthly' ? 'Last Month' : 'Last Quarter'}
          </div>
        </div>
        <div className="mt-3 cheers-based-on-criteria-text">Based on the criteria selected</div>
        <div className="d-flex mt-2 mb-2">
          <div className="cheers-outer-view cheers-icon-text-view">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                iconName,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
            <div className="ml-2 cheers-award-title-text">{selectedItem.title}</div>
          </div>
        </div>
        <div>
          <div className="cheers-select-option-text">Why is this award for?</div>
          {selectedItem.citations.length > 0 &&
            selectedItem.citations.map((data, index) => renderFilters(data, index))}
        </div>
        <Form.Group style={{ width: '50%', marginTop: 10 }}>
          <Form.Label className="cheers-select-option-text">Citation*</Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            maxLength={citationLimit}
            placeholder={`Enter your comments (Max ${citationLimit} Characters)`}
            value={removeHtmlContent(citation)}
            onChange={(event) => handleCitation(event.target.value)}
          />
        </Form.Group>
        {awardType === 'Monetary' && renderBottomView()}
        <CustomButton
          buttonStyle="mt-3"
          onClick={onPressSubmitNominations}
          disabled={buttonIsDisable || (awardType === 'Monetary' && !selectedProject)}
          showLoader={progressLoadingState.isProgressLoading}>
          Submit nomination
        </CustomButton>
      </div>
    </div>
  );
};

export default ViewPeerAwardScreen;
