import React, { useEffect, useCallback, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  COMMENT_BY_ID,
  INCREASE_SURVEY_VIEW_COUNT_EXTERNAL_LINK,
  SURVEY_BY_ID,
} from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import { useParams } from 'react-router-dom';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../utils/common';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  selectBeforeSurveyEndMessage,
  selectPostSurveyResultMessage,
} from '../../state/MasterData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../state/UserAccessData';

const SurveysNotificationScreen = ({ history }) => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const defaultMessageBeforeSurveyEnds = useSelector(selectBeforeSurveyEndMessage);
  const defaultMessagePostSurveyEnds = useSelector(selectPostSurveyResultMessage);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  const callSurveyApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(SURVEY_BY_ID + surveyId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.survey) {
            const data = apiResponse.response.data.survey;
            setSurvey(data);
            setLiked(data.liked);
            setCommentCount(data.comment_count);
            setLikeCount(data.like_count);
            if (data?.survey_type === 'external') {
              try {
                const es = await apiRequest(
                  INCREASE_SURVEY_VIEW_COUNT_EXTERNAL_LINK + data?.survey_id,
                  REQUEST_TYPE.GET,
                  {}
                );
              } catch (err) {
                handleError(
                  err,
                  {},
                  INCREASE_SURVEY_VIEW_COUNT_EXTERNAL_LINK + data?.survey_id,
                  NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN
                );
                //ignore if the view count request is not successful
                console.warn(err);
              }
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, SURVEY_BY_ID + surveyId, NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, surveyId]);

  useEffect(() => {
    callSurveyApi();
  }, []);

  const postLikeFunction = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    logEvent(ANALYTICS_EVENT_TYPES.SURVEY_LIKE, survey.survey_id, ANALYTICS_ITEM_NAMES.LIKE);
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.SURVEY);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + survey.survey_id + '/like',
        REQUEST_TYPE.POST,
        formData
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        COMMENT_BY_ID + survey.survey_id + '/like',
        NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, survey?.survey_id]);

  const onPressParticipate = () => {
    if (survey.survey_type === 'external') {
      window.open(survey.external_url, '_blank');
    } else {
      history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
        surveyId: survey.survey_id,
      });
    }
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.SURVEY} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />

        <div className="broadcast-header-row justify-content-end">
          {showLikeButton && (
            <div className="broadcast-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
                onClick={postLikeFunction}
              />
              <div
                className="broadcast-icon-text"
                onClick={() => {
                  if (likeCount > 0) {
                    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: survey.survey_id,
                      parentType: FEATURE_TYPE.SURVEY,
                    });
                  }
                }}>
                {likeCount}
              </div>
            </div>
          )}

          {showCommentButton && (
            <div className="broadcast-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                )}
                className="header-icon"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.COMMENTS, {
                    referId: survey.survey_id,
                    likeType: FEATURE_TYPE.SURVEY,
                  });
                }}
              />
              <div
                className="broadcast-icon-text"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.COMMENTS, {
                    referId: survey.survey_id,
                    likeType: FEATURE_TYPE.SURVEY,
                  });
                }}>
                {commentCount}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="main-content-container d-flex flex-row align-items-center justify-content-center w-100">
        {survey && (
          <div className="survey-content-container-width">
            <img
              src={getUrlWithSpecificRendition(
                survey.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                survey.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="polls-title-container p-3">
              <div className="polls-cross-axis-view mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.NEWS
                  )}
                  className="polls-calender-style"
                />
                <div className="ml-1 polls-date-text">
                  {survey.active
                    ? `Till ${formatDateDDMonthYYYY(survey.end_datetime)}`
                    : `Survey ended on ${formatDateDDMonthYYYY(survey.end_datetime)}`}
                </div>
              </div>
              <div className="polls-title-text mt-1">{survey.title}</div>
              {survey.active && survey.participated && (
                <div className="mt-3 mb-3 polls-details-participated-text text-center">
                  {survey.active && survey.participated && (
                    <div className="polls-details-participated-text text-center">
                      {`You've already participated in this survey`}
                    </div>
                  )}
                </div>
              )}
              {!survey.active && (
                <div className="polls-details-participated-text text-center mt-3 mb-3">
                  {defaultMessagePostSurveyEnds}
                </div>
              )}
              {survey.active && !survey.participated && showParticipate && (
                <CustomButton
                  buttonStyle="polls-button-style ml-0 mt-2"
                  onClick={onPressParticipate}>
                  Participate
                </CustomButton>
              )}
            </div>
            {survey?.description && (
              <div className="p-2">
                <HtmlView html={survey?.description} htmlStyle="polls-description-text" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveysNotificationScreen;
