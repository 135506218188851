import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  DIALOG_TYPE,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { showToast } from '../../utils/common';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectTotalActiveGroups, selectUserChatGroups } from '../../state/ChatData';
import { selectEmployeeData } from '../../state/EmployeeData';
import { generatePrivateChatId, uId } from '../../utils/ChatHandler';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import DialogListItem from './components/DialogListItem';
import ConversationRoom from './components/ConversationRoom';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import NewChatView from './components/NewChatView';
import NewGroupChatSecondStepView from './components/NewGroupChatSecondStepView';
import GroupDetailView from './components/GroupDetailView';
import GroupDetailAddParticipantsView from './components/GroupDetailAddParticipantsView';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import ForwardMessageView from './components/ForwardMessageView';
import { apiRequest, handleError } from '../../services/Service';
import { EMPLOYEES_BY_ID } from '../../services/ApiUrls';

const ChatScreen = (props) => {
  const { location } = props;
  const [dialogsList, setDialogsList] = useState([]);
  const [selectedChat, setSelectedChat] = useState({});
  const [leftPanelType, setLeftPanelType] = useState(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
  const [isNewGroupMode, setIsNewGroupMode] = useState(false);
  const [newGroupMemberList, setNewGroupMemberList] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState([]);
  const [isMyChatListingLoading, setIsMyChatListingLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const totalActiveGroups = useSelector(selectTotalActiveGroups);
  const employeeData = useSelector(selectEmployeeData);
  const userChatGroups = useSelector(selectUserChatGroups);
  let [otherEmpId, setOtherEmpId] = useState(location?.state?.employee_id ?? '');
  const [userMentionList, setUserMentionList] = useState([]);
  const [userChange, setUserChange] = useState(false);
  const [groupChange, setGroupChange] = useState(false);
  const fromRoute = props?.location?.state?.fromRoute || '';
  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CHAT, NAVIGATION_ROUTES.CHAT);
  }, []);

  useEffect(() => {
    if (
      leftPanelType !== CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_VIEW &&
      leftPanelType !== CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_ADD_MEMBERS_VIEW
    ) {
      setGroupMemberList([]);
    }
  }, [leftPanelType]);

  useEffect(() => {
    if (Object.keys(userChatGroups).length > 0) {
      if (Object.keys(userChatGroups).length === totalActiveGroups) {
        setIsMyChatListingLoading(false);
        let arr1 = [];
        Object.values(userChatGroups).forEach((value) => {
          arr1.push(value);
        });
        const uid = uId(employeeData);
        const a1 = arr1.filter((element) => element.members[uid].active === true);
        const a2 = arr1.filter((element) => element.members[uid].active === false);
        a1.sort((a, b) => {
          if (a.lastMessage === undefined && b.lastMessage === undefined) {
            return b.timestamp - a.timestamp;
          } else if (a.lastMessage === undefined) {
            return b.lastMessage.timestamp - a.timestamp;
          } else if (b.lastMessage === undefined) {
            return b.timestamp - a.lastMessage.timestamp;
          } else {
            return b.lastMessage.timestamp - a.lastMessage.timestamp;
          }
        });
        setDialogsList([...a1, ...a2]);
      } else {
        setIsMyChatListingLoading(true);
      }
    } else {
      setIsMyChatListingLoading(false);
    }
  }, [employeeData, totalActiveGroups, userChatGroups]);

  useEffect(() => {
    const chatType = props?.location?.state?.chatType || undefined;
    if (fromRoute === '/Notifications') {
      if (chatType && dialogsList) {
        switch (chatType) {
          case DIALOG_TYPE.ONE_ON_ONE_CHAT:
            {
              const item = findDialogByGroupId(props?.location?.state?.data?.group_id);
              if (item) {
                onPressDialog(item);
              }
            }
            break;
          case DIALOG_TYPE.GROUP_CHAT:
            {
              const item = findDialogByGroupId(props?.location?.state?.data?.group_id);
              if (item) {
                onPressDialog(item);
              }
            }
            break;
        }
      }
    }
  }, [props, dialogsList]);

  function findDialogByGroupId(groupId) {
    return dialogsList.find((dialog) => dialog.groupId === groupId);
  }
  const onPressDialog = async (item) => {
    if (item.group) {
      const uid = uId(employeeData);
      let memberDetail = item.members[uid];
      let memberList = [];
      Object.keys(item.members).forEach(async (user) => {
        let otherUserEmpId = user.replace(/[^0-9]/g, '');
        if (item.members[user].active) {
          let userData = await callEmployeeById(otherUserEmpId);
          let uid = uId(userData);
          let selfUid = uId(employeeData);
          if (userData && uid !== selfUid) {
            const data = {
              uid: uid,
              name: `${userData.first_name} ${userData.last_name}`,
              avatar: `${userData.profile_picture.base_url}${userData.profile_picture.image_path}`,
            };
            memberList.push(data);
            setUserMentionList([...memberList]);
          }
        }
      });
      if (!memberDetail.active || memberDetail.active === false) {
        showToast(ERROR_MESSAGES.NOT_PART_GROUP);
      } else {
        setUserChange(true);
        setSelectedChat({
          chatType: DIALOG_TYPE.GROUP_CHAT,
          groupId: item.groupId,
          userId: '',
          otherUser: {},
        });
      }
    } else {
      let members = item.groupId.split('_');
      let otherUserId = '';
      members.forEach((element) => {
        if (element !== uId(employeeData)) {
          otherUserId = element;
        }
      });

      let otherUserEmpId = otherUserId.replace(/[^0-9]/g, '');
      let empObj = await callEmployeeById(otherUserEmpId);
      let uid = uId(empObj);
      let userData = {
        uid: uid,
        name: `${empObj?.first_name} ${empObj?.last_name}`,
        avatar: `${empObj?.profile_picture.base_url}${empObj?.profile_picture.image_path}`,
      };
      const data = [];
      data.push(userData);
      setUserMentionList([...data]);
      setOtherEmpId(otherUserId);
      setUserChange(true);
      setSelectedChat({
        chatType: DIALOG_TYPE.ONE_ON_ONE_CHAT,
        groupId: item.groupId,
        userId: otherUserId,
        otherUser: empObj,
      });
    }
  };

  const callEmployeeById = useCallback(async (employeeId) => {
    try {
      const apiResponse = await apiRequest(EMPLOYEES_BY_ID + employeeId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          return apiResponse?.response?.data?.employee ?? {};
        }
        if (apiResponse.response.status === true) {
          return apiResponse?.response?.data?.employee ?? {};
        }
        if (apiResponse.response.status === false) {
          showToast(apiResponse?.response?.data?.error);
          return {};
        }
      }
      return {};
    } catch (err) {
      handleError(err, {}, EMPLOYEES_BY_ID + employeeId, NAVIGATION_ROUTES.CHAT);
      return {};
    }
  }, []);

  useEffect(() => {
    if (!!otherEmpId && !!dialogsList) {
      handleUserSpecificChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherEmpId, dialogsList]);

  const handleUserSpecificChat = useCallback(async () => {
    if (!isNaN(otherEmpId) && dialogsList.length) {
      let chatItems = dialogsList.filter((chat) => {
        if (chat?.group === false && chat?.groupId?.includes(otherEmpId)) {
          return true;
        }
      });

      if (chatItems?.length) {
        let empObj = await callEmployeeById(otherEmpId);
        setSelectedChat({
          chatType: DIALOG_TYPE.ONE_ON_ONE_CHAT,
          groupId: chatItems[0]?.groupId,
          userId: otherEmpId,
          otherUser: empObj,
        });
        setUserChange(true);
      } else {
        const otherUserId = `uid-${otherEmpId}`;
        const selfUserId = uId(employeeData);
        const groupId = generatePrivateChatId(selfUserId, otherUserId);
        let empObj = await callEmployeeById(otherEmpId);
        setSelectedChat({
          chatType: DIALOG_TYPE.ONE_ON_ONE_CHAT,
          groupId: groupId,
          userId: otherEmpId,
          otherUser: empObj,
        });
        setUserChange(true);
      }
    }
  }, [callEmployeeById, otherEmpId, dialogsList, employeeData]);

  const renderDialog = (item) => {
    return (
      <DialogListItem
        key={item.groupId.toString()}
        item={item}
        onPressDialog={onPressDialog}
        isHighlighted={item.groupId === selectedChat.groupId}
      />
    );
  };
  useEffect(() => {
    if (newGroupMemberList && newGroupMemberList.length > 0) {
      let memberList = [];
      newGroupMemberList.forEach((userData) => {
        let uid = uId(userData);
        let selfUid = uId(employeeData);
        if (userData && uid !== selfUid) {
          const data = {
            uid: uid,
            name: `${userData.first_name} ${userData.last_name}`,
            avatar: `${userData.profile_picture.base_url}${userData.profile_picture.image_path}`,
          };
          memberList.push(data);
          setUserMentionList([...memberList]);
        }
      });
    }
  }, [newGroupMemberList]);
  const renderLeftPanel = () => {
    return (
      <div className="col-sm-4 px-0">
        <div className="chat-sidebar-container">
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW && (
            <>
              <div className="px-3 py-2 d-flex justify-content-between align-items-center chat-header">
                <div className="chat-bold-text">All Messages</div>
                <CustomButton
                  onClick={() => {
                    setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.NEW_CHAT_VIEW);
                  }}>
                  New Chat
                </CustomButton>
              </div>
              <ActivityLoader visible={isMyChatListingLoading} />
              <div className="chat-left-panel-container">
                {dialogsList && dialogsList.length > 0 ? (
                  dialogsList.map((chatItem) => renderDialog(chatItem))
                ) : (
                  <div
                    className={`p-3 h-100 common-default-content-view-container-icon d-flex flex-column align-items-center justify-content-center`}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_CHAT_GREY,
                        ACCESSIBILITY_IDENTIFIERS.COMMON
                      )}
                      className="chat-default-icon"
                    />
                    <p className={`chat-default-message text-center common-ws-pre-line mt-3`}>
                      Click on the ‘<strong>New Chat</strong>’ and find the person you wish to have
                      a conversation
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.NEW_CHAT_VIEW && (
            <NewChatView
              groupMemberList={newGroupMemberList}
              setGroupMemberList={setNewGroupMemberList}
              setSelectedChat={setSelectedChat}
              isNewGroupMode={isNewGroupMode}
              setIsNewGroupMode={setIsNewGroupMode}
              setLeftPanelType={setLeftPanelType}
            />
          )}
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.CONFIRM_CHAT_VIEW && (
            <NewGroupChatSecondStepView
              groupMemberList={newGroupMemberList}
              setGroupMemberList={setNewGroupMemberList}
              setSelectedChat={setSelectedChat}
              setIsNewGroupMode={setIsNewGroupMode}
              setLeftPanelType={setLeftPanelType}
              setUserMentionList={setUserMentionList}
            />
          )}
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_VIEW && (
            <GroupDetailView
              groupMemberList={groupMemberList}
              setGroupMemberList={setGroupMemberList}
              groupId={selectedChat.groupId}
              setLeftPanelType={setLeftPanelType}
              setGroupChange={setGroupChange}
              setUserMentionList={setUserMentionList}
            />
          )}
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_ADD_MEMBERS_VIEW && (
            <GroupDetailAddParticipantsView
              groupMemberList={groupMemberList}
              groupId={selectedChat.groupId}
              setLeftPanelType={setLeftPanelType}
              setGroupChange={setGroupChange}
            />
          )}
          {leftPanelType === CHAT_LEFT_PANEL_IDENTIFIERS.FORWARD_VIEW && (
            <ForwardMessageView setLeftPanelType={setLeftPanelType} />
          )}
        </div>
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div className="col-sm-8 px-0">
        <ConversationRoom
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          setLeftPanelType={setLeftPanelType}
          mentionList={userMentionList}
          onUserChange={userChange}
          onGroupUserChange={groupChange}
          onSetUserChange={setUserChange}
        />
      </div>
    );
  };

  return (
    <div className="container-xl">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHAT} />
      <div className="row my-3 mx-0 chat-main-box">
        {renderLeftPanel()}
        {renderRightPanel()}
      </div>
    </div>
  );
};

export default ChatScreen;
