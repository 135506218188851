import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../state/EmployeeData';
import { saveProgressLoadingState } from '../../state/UIState';
import { getDashboardFacilityData, getQuickBookingApi, getUpcomingBookingApi } from './action';
import { Carousel } from 'react-bootstrap';
import { selectSeatBookingConfig } from '../../state/MasterData';
import UpcomingItem from './components/UpcomingItem';
import QuickBookingItem from './components/QuickBookingItem';
import OfficeItem from './components/OfficeItem';
import BookASpaceTab from './tabs/BookASpaceTab';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';

const SeatBookingHomeTab = () => {
  const seatHomeConfig = useSelector(selectSeatBookingConfig);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState();
  const [facilitiesDropDownData, setFacilitiesDropDownData] = useState(
    facilities
      ? Object.values(facilities)
          .flat()
          .map((facility) => ({
            label: facility.title,
            value: facility.facility_id,
            link: facility.link,
            address: facility.address,
          }))
      : []
  );
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [quickBookings, setQuickBookings] = useState();
  const employeeData = useSelector(selectEmployeeData);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const accessibilityData = useSelector(selectAccessibilityData);

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await getDashboardFacilityData();
      if (apiResponse.response?.status === true) {
        const data = apiResponse.response?.data || {};
        const facilitiesArray = Object.values(data).flat();
        setFacilities(facilitiesArray);
        const dropdownData = facilitiesArray.map((facility) => ({
          label: facility.title,
          value: facility.facility_id,
          link: facility.link,
          address: facility.address,
          spacesCount: facility.spacesCount,
        }));
        setFacilitiesDropDownData(dropdownData);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const callUpcomingBookingApi = useCallback(async () => {
    try {
      const response = await getUpcomingBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setUpcomingBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const callQuickBookingApi = useCallback(async () => {
    try {
      const response = await getQuickBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setQuickBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const renderScreen = useCallback(async () => {
    callFacilitiesApi();
    callUpcomingBookingApi();
    callQuickBookingApi();
  }, [callFacilitiesApi, callQuickBookingApi, callUpcomingBookingApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  return (
    <div className="d-flex flex-row">
      <div className="seat-booking-colored-container p-2 d-flex flex-row">
        <div className="mr-2">
          <div className="seat-booking-container-width seat-booking-container-height mb-2">
            {seatHomeConfig && seatHomeConfig.banners.length > 0 && (
              <Carousel
                activeIndex={currentActiveSlideIndex}
                className="seat-booking-carousel"
                nextIcon={
                  seatHomeConfig.banners.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-next-icon" />
                  )
                }
                prevIcon={
                  seatHomeConfig.banners.length > 1 && (
                    <span aria-hidden="true" className="carousel-control-prev-icon" />
                  )
                }
                onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                {seatHomeConfig.banners.map((imageItem, index) => {
                  return (
                    <Carousel.Item key={`${index}`}>
                      <div className="d-flex justify-content-center">
                        <img
                          className="seat-carousel-image seat-booking-carousel-border"
                          src={imageItem.base_url + imageItem.filename}
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )}
          </div>
          {facilitiesDropDownData && facilitiesDropDownData.length > 0 && (
            <div className="seat-booking-white-background seat-booking-container-width p-2 seat-booking-container-height">
              <div className="estore-home-tab-products-header">
                <div className="estore-product-type-title">OFFICE LOCATIONS</div>
              </div>
              <div className="d-flex flex-row justify-content-between w-100">
                <div className="w-100">
                  <>
                    {facilitiesDropDownData?.slice(0, 3).map((item, index) => {
                      return (
                        <div key={`${index}`} className="w-100">
                          <OfficeItem
                            item={item}
                            index={index}
                            length={facilitiesDropDownData?.slice(0, 3).length}
                          />
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="seat-booking-up-quick-container-width mr-2">
          {upcomingBookings && upcomingBookings.length > 0 ? (
            <div className="seat-booking-white-background p-2 mb-2 seat-booking-container-height">
              <div>
                <div className="estore-home-tab-products-header">
                  <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
                </div>
                {upcomingBookings.map((item, index) => {
                  return (
                    <div key={`${index}`}>
                      <UpcomingItem item={item} index={index} />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="seat-booking-white-background p-2 mb-2 seat-booking-container-height">
              <div>
                <div className="estore-home-tab-products-header">
                  <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
                </div>
                <div
                  className="d-flex flex-column justify-content-center align-items-center w-100 align-self-center
                seat-booking-quick-emp-container-height">
                  <div>Your upcoming bookings will appear here</div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-1 mt-n1">Book a desk now</div>
                    <div className="vph-arrow">➞</div>
                  </div>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.DESK_UPCOMING_BOOKING_ICON,
                      ACCESSIBILITY_IDENTIFIERS.SEAT_BOOKING
                    )}
                    className="park-qb-icon pt-2"
                  />
                </div>
              </div>
            </div>
          )}
          {quickBookings && quickBookings.length > 0 ? (
            <div className="seat-booking-white-background p-2 seat-booking-container-height">
              <div>
                <div className="estore-home-tab-products-header">
                  <div className="estore-product-type-title">QUICK BOOKINGS</div>
                </div>
                <div className="d-flex flex-column w-100">
                  {quickBookings.map((item, index) => {
                    return (
                      <div className="mb-2" key={`${index}`}>
                        <QuickBookingItem
                          item={item}
                          index={index}
                          onClick={(data) => setSelectedBooking(data)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="seat-booking-white-background p-2 seat-booking-container-height seat-booking-up-quick-container-width">
              <div>
                <div className="estore-home-tab-products-header">
                  <div className="estore-product-type-title">QUICK BOOKINGS</div>
                </div>
                <div
                  className="d-flex flex-column justify-content-center align-items-center w-100 align-self-center
                seat-booking-quick-emp-container-height">
                  <div>Book the same desk again with just a single click.</div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="mr-1 mt-n1">Book a desk now</div>
                    <div className="vph-arrow">➞</div>
                  </div>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.DESK_QUICK_BOOKING_ICON,
                      ACCESSIBILITY_IDENTIFIERS.SEAT_BOOKING
                    )}
                    className="park-qb-icon pt-2"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-2 h-100 seat-booking-form-background mr-auto seat-form-max-width">
          <BookASpaceTab location={selectedBooking} />
        </div>
      </div>
    </div>
  );
};

export default SeatBookingHomeTab;
