import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Linkify from 'react-linkify';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  TRAVEL_REQUEST_ACTION_OPTIONS,
  TRAVEL_REQUEST_ACTION_ROUTES,
  TRAVEL_REQUEST_TAB_IDENTIFIERS,
  TRAVEL_REQUEST_TRAVEL_CATEGORY,
  TRAVEL_REQUEST_TRAVEL_TYPE,
} from '../../common/constants/AppConstants';
import { saveTravelRequestData } from '../../state/TravelRequestMasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import Divider from '../../common/ui/divider/Divider';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { GET_REQUEST_BY_ID, SUBMIT_REQUEST } from '../../services/ApiUrls';
import { formatDateDDMonthYYYY, formatHHMMSSTimeAMPM, getSecuredPdfUrl } from '../../utils/common';
import { saveTravelForm, saveTravelRequestTabData } from '../../state/TabData';
import {
  selectGenderList,
  selectTravelRequestReviewGeneralTextData,
  selectWebBaseUrl,
} from '../../state/MasterData';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { renderRequestStatusBgColor, renderRequestStatusStyles } from '../../utils/travelRequest';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import TravelRequestTakeActionDialog from './components/TravelRequestTakeActionDialog';
import TravelRequestSuccessDialog from './components/TravelRequestSuccessDialog';
import TravelRequestConfirmDialog from './components/TravelRequestConfirmDialog';
import HtmlView from '../../common/ui/html_view/HTMLView';
import BackButton from '../../common/ui/common_back_button/BackButton';

const TravelRequestReviewScreen = ({ history }) => {
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [requestData, setRequestData] = useState({});
  const [isApproveActionDialogVisible, setIsApproveActionDialogVisible] = useState(false);
  const [isModificationActionDialogVisible, setIsModificationActionDialogVisible] = useState(false);
  const [isRejectActionDialogVisible, setIsRejectActionDialogVisible] = useState(false);
  const [isCancelActionDialogVisible, setIsCancelActionDialogVisible] = useState(false);
  const [isCancelDialogVisible, setIsCancelDialogVisible] = useState(false);
  const [isUserModificationDialogVisible, setIsUserModificationDialogVisible] = useState(false);
  const [isSuccessDialogVisible, setIsSuccessDialogVisible] = useState(false);
  const [successRequestMessage, setSuccessRequestMessage] = useState(
    'Thank you for your comments. This will be notified to the concerned employee.'
  );
  const reviewGeneralTextData = useSelector(selectTravelRequestReviewGeneralTextData);
  const actionRouteRef = useRef();
  const webUrl = useSelector(selectWebBaseUrl);
  const genderList = useSelector(selectGenderList);
  const [genderItemList, setGenderItemList] = useState([]);

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.TRAVEL_REQUEST_REVIEW,
      NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW
    );
  }, []);

  useEffect(() => {
    if (Object.keys(genderList).length) {
      let genderListFormatted = [];
      for (let item in genderList) {
        genderListFormatted.push({ label: genderList[item], value: item });
      }
      setGenderItemList(genderListFormatted);
    }
  }, [genderList]);

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.GET_TRAVEL_REQUEST_DATA, payload: { request_id: requestId } });
  }, [dispatch, requestId]);

  const callGetRequestByIdApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(GET_REQUEST_BY_ID, REQUEST_TYPE.GET, {
        request_id: requestId,
      });
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (Object.keys(apiResponse.response.request).length > 0) {
            setRequestData(apiResponse.response.request);
          }
        }
      }
    } catch (err) {
      handleError(err, requestId, GET_REQUEST_BY_ID, NAVIGATION_ROUTES.TRAVEL_REQUEST);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, requestId]);

  useEffect(() => {
    callGetRequestByIdApi();
  }, [callGetRequestByIdApi]);

  const callSubmitRequestApi = async (actionRoute, commentText) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const params = new URLSearchParams();
      if (commentText) {
        params.append('reason', commentText);
      }
      const apiResponse = await apiRequest(
        `${SUBMIT_REQUEST}${requestId}/${actionRoute}`,
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          dispatch(saveTravelForm({}));
          const message = apiResponse.response.message;
          if (message) {
            actionRouteRef.current = actionRoute;
            setRequestData(apiResponse.response.request);
            setSuccessRequestMessage(message);
            setIsSuccessDialogVisible(true);
            // dispatch(saveTravelRequestData({}));
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const renderTravelDocumentView = (document, documentIndex, totalDocuments) => {
    return (
      <div
        key={documentIndex.toString()}
        onClick={() => {
          window.open(getSecuredPdfUrl(webUrl, document.encoded_url), '_blank');
        }}>
        <div
          className={`d-flex align-items-center ${documentIndex + 1 !== totalDocuments && 'mb-2'}`}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_ATTACHMENT_BLUE,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="travel-request-doc-icon mr-2 common-cursor-pointer"
          />
          <p className="mb-0 travel-request-document-title common-cursor-pointer">
            {document.orginal_filename}
          </p>
        </div>
      </div>
    );
  };

  const renderTravellerView = (traveller, index, totalTravellerCount) => {
    return (
      <div key={index.toString()}>
        <div>
          <h5 className="mb-0 travel-request-traveller-title">{`${traveller.first_name} ${traveller.last_name}`}</h5>
          <div
            className={
              (traveller.flight_seat_pref ||
                traveller.flight_food_pref ||
                traveller.train_seat_pref ||
                traveller.bus_seat_pref) &&
              'mb-2'
            }>
            <p className="mb-n1 travel-request-plan-travel-label-view">
              {genderItemList.find((item) => item.value === traveller.gender).label},{' '}
              {traveller.nationality}
            </p>
            <p className="mb-0 travel-request-plan-travel-label-view">
              +{traveller.mobile_isd_code} {traveller.mobile_number.slice(0, 5)}{' '}
              {traveller.mobile_number.slice(5)}
            </p>
          </div>
        </div>
        <div
          className={
            (traveller.flight_seat_pref ||
              traveller.flight_food_pref ||
              traveller.train_seat_pref ||
              traveller.bus_seat_pref) &&
            'mb-2'
          }>
          {traveller.flight_seat_pref && (
            <p className="mb-0 travel-request-plan-travel-label-view">
              Flight seat preference:{' '}
              {traveller.flight_seat_pref.map((item) => item.title).join(', ')}
            </p>
          )}
          {traveller.flight_food_pref && (
            <p className="mb-0 travel-request-plan-travel-label-view">
              Flight food preference:{' '}
              {traveller.flight_food_pref.map((item) => item.title).join(', ')}
            </p>
          )}
          {traveller.train_seat_pref && (
            <p className="mb-0 travel-request-plan-travel-label-view">
              Train seat preference:{' '}
              {traveller.train_seat_pref.map((item) => item.title).join(', ')}
            </p>
          )}
          {traveller.bus_seat_pref && (
            <p className="mb-0 travel-request-plan-travel-label-view">
              Bus seat preference: {traveller.bus_seat_pref.map((item) => item.title).join(', ')}
            </p>
          )}
        </div>
        {index !== totalTravellerCount - 1 && <Divider style="my-2 " />}
      </div>
    );
  };

  const renderRequestView = (request) => {
    return (
      <div key={request.ticket_booking_id.toString()}>
        <div className="travel-request-review-tickets mb-3">
          <div className="d-flex p-3 ">
            <div className="w-100 mx-3 d-flex flex-column justify-content-center">
              <div className="text-center travel-request-review-title">{request.from_city}</div>
              {request.destination_pref === 'D' && (
                <>
                  <p className="mb-0 travel-request-plan-travel-label-view text-center">
                    {formatDateDDMonthYYYY(request.destination_pref_date, false)}
                  </p>
                  <p className="mb-0 travel-request-plan-travel-label-view text-center">
                    {formatHHMMSSTimeAMPM(request.destination_pref_time)}
                  </p>
                </>
              )}
            </div>

            <div className="w-100 d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                <div className="px-3 py-1 travel-request-mode travel-request-plan-travel-label-view">
                  {request.travel_mode_title}
                </div>
              </div>
            </div>

            <div className="w-100 mx-3 d-flex flex-column justify-content-center">
              <div className="text-center travel-request-review-title">{request.to_city}</div>
              {request.destination_pref === 'A' && (
                <>
                  <p className="mb-0 travel-request-plan-travel-label-view text-center">
                    {formatDateDDMonthYYYY(request.destination_pref_date, false)}
                  </p>
                  <p className="mb-0 travel-request-plan-travel-label-view text-center">
                    {formatHHMMSSTimeAMPM(request.destination_pref_time)}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="my-1 travel-request-dashed-divider" />

          {request.documents && request.documents.length > 0 && (
            <>
              <div className="p-3">
                {request.documents.map((document, index) =>
                  renderTravelDocumentView(document, index, request.documents.length)
                )}
              </div>
              <div className="my-1 travel-request-dashed-divider" />
            </>
          )}

          <div className="p-3">
            <h4 className="travel-request-review-title">Travellers Details</h4>
            {request.travellers &&
              Object.keys(request.travellers).length > 0 &&
              request.travellers.map((traveller, index) =>
                renderTravellerView(traveller, index, request.travellers.length)
              )}
            <div className="travel-request-comments px-3 py-2 mt-3">
              <div className="travel-request-plan-travel-label-view">Comments</div>
              <HtmlView
                html={
                  request.comments ? request.comments.replace(/\n/g, '<br>') : 'No comments added'
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAccomodationView = (accomodation) => {
    return (
      <div key={accomodation.accommodation_booking_id.toString()}>
        <div className="travel-request-review-tickets mb-3">
          <div className="d-flex p-3">
            <div className="w-100 mx-3 d-flex flex-column align-items-center justify-content-center">
              <div className="text-center travel-request-review-title">In</div>
              <p className="mb-0 travel-request-plan-travel-label-view">
                {formatDateDDMonthYYYY(accomodation.check_in_date, false)}
              </p>
            </div>

            <div className="w-100 d-flex align-items-center justify-content-center">
              <div className="align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-center travel-request-review-title">
                    {accomodation.destination}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="px-3 travel-request-mode travel-request-plan-travel-label-view">
                    {accomodation.property_type_title}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 mx-3 d-flex flex-column align-items-center justify-content-center">
              <div className="text-center travel-request-review-title">Out</div>
              <p className="mb-0 travel-request-plan-travel-label-view">
                {formatDateDDMonthYYYY(accomodation.check_out_date, false)}
              </p>
            </div>
          </div>

          <div className="my-1 travel-request-dashed-divider" />

          {accomodation.documents && accomodation.documents.length > 0 && (
            <>
              <div className="p-3">
                {accomodation.documents.map((document, index) =>
                  renderTravelDocumentView(document, index, accomodation.documents.length)
                )}
              </div>
              <div className="my-1 travel-request-dashed-divider" />
            </>
          )}

          <div className="p-3">
            <h4 className="travel-request-review-title">Guest Details</h4>
            {accomodation.travellers &&
              Object.keys(accomodation.travellers).length > 0 &&
              accomodation.travellers.map((traveller, index) =>
                renderTravellerView(traveller, index, accomodation.travellers.length)
              )}
            <div className="travel-request-comments px-3 py-2 mt-3">
              <div className="travel-request-plan-travel-label-view">Comments</div>
              <HtmlView
                html={
                  accomodation.comments
                    ? accomodation.comments.replace(/\n/g, '<br>')
                    : 'No comments added'
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.TRAVEL_REQUEST} />
      <BackButton />
      <TravelRequestTakeActionDialog
        isActionDialogVisible={isApproveActionDialogVisible}
        setIsActionDialogVisible={setIsApproveActionDialogVisible}
        title="Are you sure you want to approve?"
        placeholder="Add your comments"
        onPressSubmitHandler={(commentText) => {
          setIsApproveActionDialogVisible(false);
          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.APPROVE, commentText);
        }}
      />
      <TravelRequestTakeActionDialog
        isActionDialogVisible={isModificationActionDialogVisible}
        setIsActionDialogVisible={setIsModificationActionDialogVisible}
        title="Why are you sending for modification?"
        placeholder="Enter your reason(s)"
        onPressSubmitHandler={(commentText) => {
          setIsModificationActionDialogVisible(false);
          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.MODIFICATION, commentText);
        }}
      />
      <TravelRequestTakeActionDialog
        isActionDialogVisible={isRejectActionDialogVisible}
        setIsActionDialogVisible={setIsRejectActionDialogVisible}
        title="Why are you rejecting this request?"
        placeholder="Enter your reason(s)"
        onPressSubmitHandler={(commentText) => {
          setIsRejectActionDialogVisible(false);
          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.REJECT, commentText);
        }}
      />
      <TravelRequestTakeActionDialog
        isActionDialogVisible={isCancelActionDialogVisible}
        setIsActionDialogVisible={setIsCancelActionDialogVisible}
        title="Why are you cancelling this travel?"
        placeholder="Enter your reason(s)"
        onPressSubmitHandler={(commentText) => {
          setIsCancelActionDialogVisible(false);
          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.CANCEL, commentText);
        }}
      />
      <TravelRequestSuccessDialog
        isSuccessDialogVisible={isSuccessDialogVisible}
        setIsSuccessDialogVisible={setIsSuccessDialogVisible}
        actionRouteRef={actionRouteRef}
        bodyMessage={successRequestMessage}
      />
      <TravelRequestConfirmDialog
        isRquestConfirmDialogVisible={isUserModificationDialogVisible}
        setIsRquestConfirmDialogVisible={setIsUserModificationDialogVisible}
        title="Modify Travel"
        subtitle="Modifying ticket is subject to ticket availability. Do you wish to continue?"
        onPressFirstButton={() => setIsUserModificationDialogVisible(false)}
        onPressSecondButton={() => {
          setIsUserModificationDialogVisible(false);
          dispatch(saveTravelRequestData(requestData));
          dispatch(saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL));
          history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
            isEditRequest: true,
            requestFormData: requestData,
          });
        }}
      />
      <TravelRequestConfirmDialog
        isRquestConfirmDialogVisible={isCancelDialogVisible}
        setIsRquestConfirmDialogVisible={setIsCancelDialogVisible}
        title="Cancel Travel"
        subtitle="Are you sure you want to cancel this travel?"
        onPressFirstButton={() => setIsCancelDialogVisible(false)}
        onPressSecondButton={() => {
          setIsCancelDialogVisible(false);
          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.CANCEL);
        }}
      />

      <div className="main-content-container">
        <div className="d-flex justify-content-center">
          <div className="common-container-md">
            {requestData && Object.keys(requestData) && Object.keys(requestData).length > 0 && (
              <div>
                {requestData.reason_show && (
                  <div className="mb-3">
                    <p className="mb-2">{requestData.reason_title}</p>
                    <div
                      className={`${renderRequestStatusStyles(
                        requestData.status.identifier
                      )} travel-request-comment-box-base p-3`}>
                      <p className="mb-0">{requestData.reason}</p>
                    </div>
                  </div>
                )}
                <div className="d-flex">
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Travel Request Id</div>
                    <p className="mb-0">{requestData.request_code}</p>
                  </div>
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Travel Type</div>
                    <p className="mb-0">
                      {requestData.travel_type &&
                        TRAVEL_REQUEST_TRAVEL_TYPE.find(
                          (item) => item.value === requestData.travel_type
                        ).label}
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Travel category</div>
                    <p className="mb-0">
                      {requestData.travel_range &&
                        TRAVEL_REQUEST_TRAVEL_CATEGORY.find(
                          (item) => item.value === requestData.travel_range
                        ).label}
                    </p>
                  </div>
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Project Name</div>
                    <p className="mb-0">{requestData.project_title}</p>
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Duration</div>
                    <p className="mb-0">
                      {formatDateDDMonthYYYY(requestData.start_date, false)} to{' '}
                      {formatDateDDMonthYYYY(requestData.end_date, false)}
                    </p>
                  </div>
                  <div className="w-50">
                    <div className="travel-request-plan-travel-label-view">Status</div>
                    <div className="d-flex align-items-center">
                      <div
                        className={`travel-request-request-indicator-base mr-2 ${renderRequestStatusBgColor(
                          requestData.status.identifier
                        )}`}
                      />
                      <p className="mb-0 travel-request-status">{requestData.status.title}</p>
                    </div>
                  </div>
                </div>
                {requestData?.billing_type && (
                  <div className="w-100 mt-3">
                    <div className="travel-request-plan-travel-label-view">Billing Type</div>
                    <p className="mb-0">
                      {requestData?.billing_type === 'N' ? 'Non-Billable' : 'Billable'}
                    </p>
                  </div>
                )}
                <div className="w-100 mt-3">
                  <div className="travel-request-plan-travel-label-view">Purpose</div>
                  <p className="mb-0">{requestData.purpose}</p>
                </div>
                {!!requestData.justification && (
                  <div className="d-flex mt-3">
                    <div className="w-100">
                      <div className="travel-request-plan-travel-label-view">Justification</div>
                      <p className="mb-0">
                        <HtmlView html={requestData.justification.replace(/\n/g, '<br>')} />
                      </p>
                    </div>
                  </div>
                )}
                {requestData.tickets.length > 0 && (
                  <div>
                    <p className="mb-2 mt-3 travel-request-plan-travel-label-view">
                      Travel Details
                    </p>
                    {requestData.tickets.map((request) => renderRequestView(request))}
                  </div>
                )}
                {requestData.accommodations.length > 0 && (
                  <div>
                    <p className="mb-2 mt-3 travel-request-plan-travel-label-view">
                      Accomodation Details
                    </p>
                    {requestData.accommodations.map((accommodation) =>
                      renderAccomodationView(accommodation)
                    )}
                  </div>
                )}
                <div className="mt-3">
                  <Linkify>{reviewGeneralTextData}</Linkify>
                </div>
                <div className="d-flex mt-3 justify-content-end">
                  {requestData.actions.edit && requestData.actions.edit === true && (
                    <CustomButton
                      buttonStyle="travel-request-proceed-btn mr-2"
                      onClick={() => {
                        logEvent(
                          ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                          requestData.request_id.toString(),
                          ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                          TRAVEL_REQUEST_ACTION_OPTIONS.MODIFY
                        );
                        if (requestData.modification_reason_required) {
                          setIsUserModificationDialogVisible(true);
                        } else {
                          dispatch(saveTravelRequestData(requestData));
                          dispatch(
                            saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL)
                          );
                          history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
                            isEditRequest: true,
                            requestFormData: requestData,
                          });
                        }
                      }}>
                      Modify
                    </CustomButton>
                  )}
                  {requestData.actions.cancel && requestData.actions.cancel === true && (
                    <CustomButton
                      buttonStyle="travel-request-proceed-btn mr-2"
                      onClick={() => {
                        logEvent(
                          ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                          requestData.request_id.toString(),
                          ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                          TRAVEL_REQUEST_ACTION_OPTIONS.CANCEL
                        );
                        if (requestData.cancellation_reason_required) {
                          setIsCancelActionDialogVisible(true);
                        } else {
                          setIsCancelDialogVisible(true);
                        }
                      }}>
                      Cancel
                    </CustomButton>
                  )}

                  {requestData.actions.submit && requestData.actions.submit === true && (
                    <CustomButton
                      buttonStyle="travel-request-proceed-btn mr-2"
                      onClick={() => {
                        logEvent(
                          ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                          requestData.request_id.toString(),
                          ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                          TRAVEL_REQUEST_ACTION_OPTIONS.SUBMIT
                        );
                        callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.SUBMIT);
                      }}>
                      Submit
                    </CustomButton>
                  )}
                  {requestData.actions.remind_manger &&
                    requestData.actions.remind_manger === true && (
                      <CustomButton
                        buttonStyle="travel-request-proceed-btn mr-2"
                        onClick={() => {
                          logEvent(
                            ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                            requestData.request_id.toString(),
                            ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                            TRAVEL_REQUEST_ACTION_OPTIONS.REMIND_MANAGER
                          );
                          callSubmitRequestApi(TRAVEL_REQUEST_ACTION_ROUTES.REMIND_MANAGER);
                        }}>
                        Remind Manager
                      </CustomButton>
                    )}
                  {requestData.actions.approve && requestData.actions.approve === true && (
                    <CustomButton
                      buttonStyle="travel-request-proceed-btn mr-2"
                      onClick={() => {
                        logEvent(
                          ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                          requestData.request_id.toString(),
                          ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                          TRAVEL_REQUEST_ACTION_OPTIONS.APPROVE
                        );
                        setIsApproveActionDialogVisible(true);
                      }}>
                      Approve
                    </CustomButton>
                  )}
                  {requestData.actions.modification &&
                    requestData.actions.modification === true && (
                      <CustomButton
                        buttonStyle="travel-request-proceed-btn mr-2"
                        onClick={() => {
                          logEvent(
                            ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                            requestData.request_id.toString(),
                            ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                            TRAVEL_REQUEST_ACTION_OPTIONS.MODIFICATION
                          );
                          setIsModificationActionDialogVisible(true);
                        }}>
                        Modification Required
                      </CustomButton>
                    )}
                  {requestData.actions.reject && requestData.actions.reject === true && (
                    <CustomButton
                      buttonStyle="travel-request-proceed-btn mr-2"
                      onClick={() => {
                        logEvent(
                          ANALYTICS_EVENT_TYPES.TR_REVIEW_TAKE_ACTION_CLICK,
                          requestData.request_id.toString(),
                          ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST,
                          TRAVEL_REQUEST_ACTION_OPTIONS.REJECT
                        );
                        setIsRejectActionDialogVisible(true);
                      }}>
                      Reject
                    </CustomButton>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelRequestReviewScreen;
