import React, { useEffect, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
  WISH_TYPES,
} from '../../../common/constants/AppConstants';
import { useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { useHistory } from 'react-router';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { formatDateDoMMM, getUrlWithSpecificRendition } from '../../../utils/common';

const WishesListItem = ({ item, showYear }) => {
  const history = useHistory();
  const employeeData = useSelector(selectEmployeeData);
  const [serviceYear, setServiceYear] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (item.data && item.data.anniversary) {
      if (employeeData && employeeData.date_of_joining) {
        const doj = new Date(employeeData.date_of_joining);
        let yearDiff = item.title - doj.getFullYear();
        if (yearDiff === 1) {
          setServiceYear(`${yearDiff} year`);
        } else if (yearDiff > 1) {
          setServiceYear(`${yearDiff} years`);
        }
      }
    }
  }, [employeeData, item.data, item.title]);

  const getSubText = (item) => {
    switch (item.type) {
      case WISH_TYPES.BIRTHDAY:
        return `You celebrated a birthday on ${formatDateDoMMM(item.created_on)}`;
      case WISH_TYPES.ADVANCEMENT:
        return `You celebrated a career advancement on ${formatDateDoMMM(item.created_on)}`;
      case WISH_TYPES.ANNIVERSARY:
        return `You celebrated a service anniversary on ${formatDateDoMMM(item.created_on)}`;
      case WISH_TYPES.NEW_JOINEE:
        return `You joined on ${formatDateDoMMM(item.created_on)}`;
      default:
        return `You celebrated a birthday on ${formatDateDoMMM(item.created_on)}`;
    }
  };

  const goToWishesScreen = () => {
    history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
      profileData: employeeData,
      celebrationDetails: item,
      year: item.year,
      fromRoute: NAVIGATION_ROUTES.MY_PROFILE_DETAIL,
    });
  };

  return (
    <div>
      {showYear && (
        <div className="year-all-wishes-container">
          <div className="year-all-wishes-devider" />
          <div className="year-all-wishes-item">{`${item.year}`}</div>
          <div className="year-all-wishes-devider" />
        </div>
      )}
      <div onClick={goToWishesScreen} className="common-cursor-pointer">
        <div>
          <img
            src={getUrlWithSpecificRendition(
              item.image.base_url + '/',
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item.image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="employee-details-container">
            <div className="wishes-employee-details">
              <img
                className={`d-block common-user-profile-photo-sm mr-3`}
                src={
                  employeeData.profile_picture.image_path
                    ? getUrlWithSpecificRendition(
                        employeeData.profile_picture.base_url,
                        SCREEN_DENSITY_QUALIFIERS.LDPI,
                        employeeData.profile_picture.image_path
                      )
                    : getFeatureComponentUrlLdpi(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                        ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                      )
                }
              />
              <div>
                <div className="communities-post-employee-name">{getSubText(item)}</div>
                <div className="wishes-sub-text-self">
                  <div className="see-wishes-sub-text">{`See Wishes  `}</div>
                  <div className="right-arrow-icon-wishes">{`\u203a`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishesListItem;
